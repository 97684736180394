import { createSlice } from "@reduxjs/toolkit";
const Result_Reducer = createSlice({
    name: "result",
    initialState: {
        loading: false,
        result: [],
        resultOptions: [],
        readQuestions: [],
        markforreviewData: [],
    },
    reducers: {
        pushResultAction: (state, action) => {
            return {
                ...state,
                result: action.payload
            }
        },
        updateResultAction: (state, action) => {
            const { trace, checked } = action.payload;
            state.result.fill(checked, trace, trace + 1);
        },
        FirstOptionUpdate: (state, action) => {
            state.result[0] = {
                id: "",
                name: "",
                answer: "",
                questionId: 1,
                savequestionId: "1e",
                subject: "",
                oriquestionId: action.payload,
                chapterName: "",
                your_responce: "",
                correct_responses: "",
                subTopic: "",
                score: "0",
                difficulty_level: "",
                markedforreviewId: "",
                answeredandmarkedforreviewId: "",
                visitedquestions: 0,
                spendtime: 0,
                correct_to_incorrect: "",
                incorrect_to_correct: "",
                incorrect_to_incorrect: "",
                correct_to_unanswered: "",
                incorrect_to_unanswered: "",
                correct_to_correct: ""
            }
        },
        updateResultActions: (state, action) => {
            let newItem = action.payload;
            const existItem = state.result.find((item) => item.questionId === action.payload.questionId);
            state.result = existItem ? state.result.map((item) => item.questionId === existItem.questionId ? newItem : item) : [...state.result, newItem]
        },
        updateResultOptions: (state, action) => {
            let newItem = action.payload;
            const existItem = state.resultOptions.find((item) => item.questionId === action.payload.questionId);
            state.resultOptions = existItem ? state.resultOptions.map((item) => item.questionId === existItem.questionId ? newItem : item) : [...state.resultOptions, newItem]
        },
        readQuestionOptions: (state, action) => {
            let newItem = action.payload;
            const existItem = state.readQuestions.find((item) => item.id === action.payload.id);
            state.readQuestions = existItem ? state.readQuestions.map((item) => item.id === existItem.id ? newItem : item) : [...state.readQuestions, newItem]
        },
        readQuestionforMarkforReviewOptions: (state, action) => {
            let newItem = action.payload;
            const existItem = state.markforreviewData.find((item) => item.id === action.payload.id);
            state.markforreviewData = existItem ? state.markforreviewData.map((item) => item.id === existItem.id ? newItem : item) : [...state.markforreviewData, newItem]
        },
        SaveandUpdateOptionData: (state, action) => {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level, visitedquestions,
                spendtime,
                correct_to_incorrect,
                incorrect_to_correct,
                incorrect_to_incorrect,
                correct_to_unanswered,
                incorrect_to_unanswered,
                correct_to_correct
            } = action.payload;

            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
                updateData.visitedquestions = visitedquestions;
                updateData.spendtime = spendtime;
                updateData.correct_to_incorrect = correct_to_incorrect;
                updateData.incorrect_to_correct = incorrect_to_correct;
                updateData.incorrect_to_incorrect = incorrect_to_incorrect;
                updateData.correct_to_unanswered = correct_to_unanswered;
                updateData.incorrect_to_unanswered = incorrect_to_unanswered;
                updateData.correct_to_correct = correct_to_correct;
            }
        },
        SaveandUpdateMarkReviewData: (state, action) => {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level, visitedquestions,
                spendtime, correct_to_incorrect,
                incorrect_to_correct,
                incorrect_to_incorrect,
                correct_to_unanswered,
                incorrect_to_unanswered,
                correct_to_correct } = action.payload;
            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
                updateData.visitedquestions = visitedquestions;
                updateData.spendtime = spendtime;
                updateData.correct_to_incorrect = correct_to_incorrect;
                updateData.incorrect_to_correct = incorrect_to_correct;
                updateData.incorrect_to_incorrect = incorrect_to_incorrect;
                updateData.correct_to_unanswered = correct_to_unanswered;
                updateData.incorrect_to_unanswered = incorrect_to_unanswered;
                updateData.correct_to_correct = correct_to_correct;
            }
        },
        SaveMarkOptionUpdate: (state, action) => {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level,
                visitedquestions,
                spendtime,
                correct_to_incorrect,
                incorrect_to_correct,
                incorrect_to_incorrect,
                correct_to_unanswered,
                incorrect_to_unanswered,
                correct_to_correct } = action.payload;
            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
                updateData.visitedquestions = visitedquestions;
                updateData.spendtime = spendtime;
                updateData.correct_to_incorrect = correct_to_incorrect;
                updateData.incorrect_to_correct = incorrect_to_correct;
                updateData.incorrect_to_incorrect = incorrect_to_incorrect;
                updateData.correct_to_unanswered = correct_to_unanswered;
                updateData.incorrect_to_unanswered = incorrect_to_unanswered;
                updateData.correct_to_correct = correct_to_correct;
            }
        },
        OptionResultUpdate: (state, action) => {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level,
                visitedquestions,
                spendtime,
                correct_to_incorrect,
                incorrect_to_correct,
                incorrect_to_incorrect,
                correct_to_unanswered,
                incorrect_to_unanswered,
                correct_to_correct
            } = action.payload;
            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
                updateData.visitedquestions = visitedquestions;
                updateData.spendtime = spendtime;
                updateData.correct_to_incorrect = correct_to_incorrect;
                updateData.incorrect_to_correct = incorrect_to_correct;
                updateData.incorrect_to_incorrect = incorrect_to_incorrect;
                updateData.correct_to_unanswered = correct_to_unanswered;
                updateData.incorrect_to_unanswered = incorrect_to_unanswered;
                updateData.correct_to_correct = correct_to_correct;
            }
        },
        VisitedQuestionCountAction(state, action) {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level,
                visitedquestions,
                spendtime
            } = action.payload;
            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
                updateData.visitedquestions = visitedquestions + 1;
                updateData.spendtime = spendtime
            }
        },

        ClearOptionData: (state, action) => {
            const { id,
                name,
                answer,
                questionId,
                savequestionId,
                markedforreviewId,
                answeredandmarkedforreviewId,
                subject,
                oriquestionId,
                chapterName,
                your_responce,
                correct_responses,
                subTopic,
                score,
                difficulty_level } = action.payload;
            const updateData = state.result.find((item) => item?.oriquestionId === oriquestionId)
            if (updateData) {
                updateData.id = id;
                updateData.name = name;
                updateData.answer = answer;
                updateData.questionId = questionId;
                updateData.savequestionId = savequestionId;
                updateData.markedforreviewId = markedforreviewId;
                updateData.answeredandmarkedforreviewId = answeredandmarkedforreviewId;
                updateData.subject = subject;
                updateData.chapterName = chapterName;
                updateData.your_responce = your_responce;
                updateData.correct_responses = correct_responses;
                updateData.subTopic = subTopic;
                updateData.score = score;
                updateData.difficulty_level = difficulty_level;
            }
        },
        AllclearQuestion: (state, action) => {
            return {
                ...state,
                result: [],
                resultOptions: [],
                readQuestions: []
            }
        }

    }
})

const { actions, reducer } = Result_Reducer;
export const { pushResultAction, updateResultAction, FirstOptionUpdate, updateResultOptions, readQuestionOptions, AllclearQuestion, SaveandUpdateOptionData, SaveandUpdateMarkReviewData, ClearOptionData, readQuestionforMarkforReviewOptions, OptionResultUpdate, SaveMarkOptionUpdate, VisitedQuestionCountAction } = actions;
export default reducer;