import React, { useState } from 'react'
import './styles/Sessionclear.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { UserLogout } from '../../services/user/userSevice';
function SessionClearcomponent() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [loadnig, setLoading] = useState(false);

    const navigate = useNavigate();

    const Login = async () => {
        setLoading(true);
        try {

            navigate("/")
            localStorage.clear();
            sessionStorage.clear(); 
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    }
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='button-text'>
                            Logout
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center medium-text'>
                        Login Pending, Device limit reached.
                    </div>
                </Modal.Body>
                <div className='button-section-box p-2 mb-3 mt-2'>
                    <Button className='logoutButton' onClick={Login}>
                        Logout from all Devices
                    </Button>
                </div>
            </Modal>

        </div>
    )
}

export default SessionClearcomponent