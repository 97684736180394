import { createSlice } from "@reduxjs/toolkit";
const Qarly_Reducer = createSlice({
    name: "Qarly",
    initialState: {
        loading: false,
        QarlyData: [],
    },
    reducers: {
        QarlyRequest(state, action) {
            return {
                loading: true
            }
        },
        QarlySuccess(state, action) {
            return {
                loading: false,
                QarlyData: action.payload
            }
        },
        QarlyFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Qarly_Reducer;
export const { QarlyRequest, QarlySuccess, QarlyFail } = actions;
export default reducer;