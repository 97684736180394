import { combineReducers } from "redux";
import CartReducer from './Cart_reducer'
import CartStoreReducer from './Cart_reducer_store'
import TokenReducer from './Token_reducer'
import CouserReducer from './Couser_reducer'
import ChapterReducer from './Chapter_reducer'
import SuggestedCourseReducer from './Suggested_redeucer'
import ExtraCourseReducer from './extraCourse_reducer'
import ProfileReducer from './Profile_reducer'
import BuycourseReducer from './Buynow_reducer'
import CouponReducer from './Coupon_reducer'
import ExamCourseReducer from './examCourse_reducer'
import ClassReducer from "./Class_reducer"
import TestspaceReducer from "./Testspace_reducer"
import PurchaseReducer from "./Purchase_reducer"
import PurchasedCoursereducer from './Purchased_Course_reducer'
import Syllabusreducer from "./Syllabus_reducer"
import QaReducer from "./Qa_reducer"
import QaGetReducer from "./Qaget_reducer"
import QarlyReducer from "./Qarly_reducer"
import QacloseReducer from './Qaclose_reducer'
import NewsReducer from './NewsArticles_reducer'
import ContentReducer from './Content_reducer'
import NeetExamsReducer from './Neet_reducer';
import Questionreducer from './Question_reducer';
import QuestionreducerOptions from './Questionoption_reducer';
import Buttonreducer from './Button_reducer'
import Resultreducer from './Result_reducer';
import QuestionCurrentOption from './QuestionCurrent_options_reducer';
import ReloadReducer from './Reload_reducer';
import ReportDatareducer from './Report_reducer';
import RankReducer from './Rank_reducer'
import RankLocationReducer from './RankLocation_reducer';
import RankLocationFilterReducer from './RankFilterLocations_reducer';
const RootReducer = combineReducers({
    login: "reducer",
    cart: CartReducer,
    token: TokenReducer,
    course: CouserReducer,
    chapter: ChapterReducer,
    suggested: SuggestedCourseReducer,
    extraCourse: ExtraCourseReducer,
    examCourse: ExamCourseReducer,
    cartstore: CartStoreReducer,
    profile: ProfileReducer,
    buycourse: BuycourseReducer,
    coupon: CouponReducer,
    class: ClassReducer,
    testspace: TestspaceReducer,
    purchase: PurchaseReducer,
    purchasedcourses: PurchasedCoursereducer,
    Syllabus: Syllabusreducer,
    Qa: QaReducer,
    QaGet: QaGetReducer,
    Qarly: QarlyReducer,
    Qaclose: QacloseReducer,
    NewsandArticles: NewsReducer,
    Content: ContentReducer,
    neetExams: NeetExamsReducer,
    questions: Questionreducer,
    questionsoption: QuestionreducerOptions,
    count: Buttonreducer,
    result: Resultreducer,
    optioncurrent: QuestionCurrentOption,
    loadprops: ReloadReducer,
    report: ReportDatareducer,
    rank: RankReducer,
    rankLocation: RankLocationReducer,
    rankLocationfilter: RankLocationFilterReducer

});


export default RootReducer;