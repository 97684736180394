import { createSlice } from "@reduxjs/toolkit";
const Qaclose_Reducer = createSlice({
    name: "Qaclose",
    initialState: {
        loading: false,
        QacloseData: [],
    },
    reducers: {
        QacloseRequest(state, action) {
            return {
                loading: true
            }
        },
        QacloseSuccess(state, action) {
            return {
                loading: false,
                QacloseData: action.payload
            }
        },
        QacloseFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Qaclose_Reducer;
export const { QacloseRequest, QacloseSuccess, QacloseFail } = actions;
export default reducer;