import { createSlice } from "@reduxjs/toolkit";
const Rank_Reducer = createSlice({
    name: "Rank",
    initialState: {
        loading: false,
        RankData: [],
    },
    reducers: {
        RankRequest(state, action) {
            return {
                loading: true
            }
        },
        RankSuccess(state, action) {
            return {
                loading: false,
                RankData: action.payload
            }
        },
        RankFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Rank_Reducer;
export const { RankRequest, RankSuccess, RankFail } = actions;
export default reducer;