import { getSingleQuestion } from "../../services/neetservices/question_service"
import { QuestionOptionCurrentFail, QuestionOptionCurrentRequest, QuestionOptionCurrentSuccess } from "../reducers/QuestionCurrent_options_reducer"
import { QuestionFail, QuestionRequest, QuestionSuccess } from "../reducers/Question_reducer"
import { QuestionOptionFail, QuestionOptionRequest, QuestionOptionSuccess } from "../reducers/Questionoption_reducer"


export const GetQuestionsSingle = (questionId) => async (dispatch) => {
    dispatch(QuestionRequest())
    const getTestName = sessionStorage.getItem("testname");
    try {
        const response = await getSingleQuestion(questionId, getTestName)
        if (response) {
            await dispatch(QuestionSuccess(response))
        }
    } catch (error) {
        dispatch(QuestionFail(error))
    }
}
export const QuestionOptionActions = (data) => async (dispatch) => {
    dispatch(QuestionOptionRequest())
    try {
        dispatch(QuestionOptionSuccess(data))
    } catch (error) {
        dispatch(QuestionOptionFail(error))
    }
}



export const CurrentOptionSelect = (dataid) => async (dispatch) => {
    dispatch(QuestionOptionCurrentRequest())
    try {
        dispatch(QuestionOptionCurrentSuccess(dataid))
    } catch (error) {
        dispatch(QuestionOptionCurrentFail(error))
    }
}