import axios from "axios";
import { UserSessionClear } from "../services/user/userSevice";
import { ToastError } from "../toastmessages/Toastmessages";
axios.interceptors.request.use(
  function (config) {
    const tokens = sessionStorage.getItem("access-user-token");

    config.url = process.env.REACT_APP_BASEURL + config.url;
    if (config.url === "/users/register") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    } else if (config.url === "/users/mobile/verify") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    } else if (config.url === "/users/email") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    } else if (config.url === "/users/email/verify") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    } else if (config.url === "/users/genarate/password") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    } else if (config.url === "/users/standard") {
      config.url = process.env.REACT_APP_APIURL + config.url;
    }
    config.withCredentials = false;
    if (tokens) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        "x-access-token": JSON.parse(tokens),
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error?.response?.data?.cdpcode === "CDPSE400" ||
      error?.response?.data?.cdpcode === "CDPMDL400"
    ) {
      const clearid = {
        _id: error?.response?.data?._id,
      };

      if (error?.response?.data?.message === "your session was expired") {
        window.location.assign("/logoutsession");
        sessionStorage.setItem("clear", true);
      } else {
        const response = await UserSessionClear(clearid);
        window.location.assign("/logoutuser");
      }
    } else {
      if (
        error?.response?.data?.message === "your session was expired" ||
        error?.response?.data?.message == "Access_Expired"
      ) {
        window.location.assign("/logoutsession");
      }
    }
    return Promise.reject(error);
  }
);

const instanceBaseurl = axios;

export default instanceBaseurl;
