import React, { useEffect, useState } from 'react'
import '../../components/sessionclearcomponent/styles/Sessionclear.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
function SessionLogout() {
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loadnig, setLoading] = useState(false);
    const navigate = useNavigate();
    const Login = async () => {
        setLoading(true);
        try {
            navigate("/")
            localStorage.clear();
            sessionStorage.clear();
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }


    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='button-text'>
                            Session Was Expired
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center medium-text'>

                        <div>
                            Your Session Was Expired
                        </div>


                    </div>
                </Modal.Body>
                <div className='button-section-box p-2 mb-3 mt-2'>
                    <Button className='logoutButton' onClick={Login}>
                        Logout
                    </Button>
                </div>
            </Modal>

        </div>
    )
}

export default SessionLogout