import React, { useState, useEffect } from 'react'
import './styles/Neetinstruction.scss';
import Header from '../../../../../src/middlewares/header/Header';
import { HomeContent } from '../../../../../src/middlewares/HomeContent';
import Quiz from '../quiz/Quiz';
import { useDispatch } from 'react-redux';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { CurrentOptionSelect, GetQuestionsSingle, QuestionOptionActions } from '../../../../redux/actions/Questions';
import { pushResultAction } from '../../../../redux/reducers/Result_reducer';
import Modal from 'react-bootstrap/Modal';
import warningimage from '../../../../assests/images/warning-image.png';
import { getOverallQuestion } from '../../../../services/neetservices/question_service';
import { ButtonUpdateTrace } from '../../../../redux/reducers/Button_reducer';
function NeetInstructionScreen() {
    const { state } = useLocation();
    const [questionsshow, setQuestionShow] = useState(false);
    const navigate = useNavigate();
    const [checkboxtrue, setCheckboxTrue] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questions1, setQuestions1] = useState([]);
    const dispatch = useDispatch();
    // check box warning modal popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [FirstQuestionId, setFirstQuestions] = useState("");
    useEffect(() => {
    }, [questionsshow, state, FirstQuestionId]);

    const GetQuestions = async () => {
        // Biology
        let datasBio11 = [];
        let datasBio12 = [];
        // Physics
        let datasPhy11 = [];
        let datasPhy12 = [];
        // Chemistory
        let datasChe11 = [];
        let datasChe12 = [];

        const datas = {
            masterId: state?.masterId,
            type: state?.type,
            id: state?.id
        }

        try {
            const response = await getOverallQuestion(datas);

            // biology
            response && response?.Biology?.map((item) => {
                item[11]?.questionId?.map((items) => {
                    let biologyids = {
                        Queid: items,
                        name: "Biology 11"
                    }

                    datasBio11.push(biologyids);

                })


                item[12]?.questionId?.map((items) => {

                    let biologyids = {
                        Queid: items,
                        name: "Biology 12"
                    }
                    datasBio12.push(biologyids);

                })

            })

            // physics

            response && response?.Physics?.map((item) => {
                item[11]?.questionId?.map((items) => {
                    let biologyids = {
                        Queid: items,
                        name: "Physics 11"
                    }
                    datasPhy11.push(biologyids);
                })


                item[12]?.questionId?.map((items) => {
                    let biologyids = {
                        Queid: items,
                        name: "Physics 12"
                    }
                    datasPhy12.push(biologyids);
                })

            })

            // chemistory
            response && response?.Chemistry?.map((item) => {
                item[11]?.questionId?.map((items) => {
                    let biologyids = {
                        Queid: items,
                        name: "Chemistry 11"
                    }
                    datasChe11.push(biologyids);
                })
                item[12]?.questionId?.map((items) => {
                    let biologyids = {
                        Queid: items,
                        name: "Chemistry 12"
                    }
                    datasChe12.push(biologyids);
                })

            })
        } catch (error) {
        }

        var overallquestions = datasBio11.concat(datasBio12).concat(datasPhy11).concat(datasPhy12).concat(datasChe11).concat(datasChe12);
        setQuestions(overallquestions);
    }

    useEffect(() => {
        GetQuestions();
    }, [state])


    useEffect(() => {
        dispatch(QuestionOptionActions(questions))
    }, [questions])

    useEffect(() => {
        dispatch(GetQuestionsSingle(questions[0]?.Queid))
    }, []);
    useEffect(() => {
        dispatch(CurrentOptionSelect(questions[0]?.Queid))
    }, [questions[0]?.Queid])


    useEffect(() => {
        if (questions) {
            let formatetData = [];
            questions.map((item) => {
                let formsdata = {
                    id: "",
                    name: "",
                    answer: "",
                    questionId: "",
                    savequestionId: "",
                    subject: item?.name,
                    oriquestionId: item?.Queid,
                    chapterName: "",
                    your_responce: "",
                    correct_responses: "",
                    subTopic: "",
                    score: "0",
                    difficulty_level: "",
                    markedforreviewId: "",
                    answeredandmarkedforreviewId: "",
                    visitedquestions: 0,
                    spendtime: 0,
                    correct_to_incorrect: "",
                    incorrect_to_correct: "",
                    incorrect_to_incorrect: "",
                    correct_to_unanswered: "",
                    incorrect_to_unanswered: "",
                    correct_to_correct: ""
                };
                formatetData.push(formsdata);
            });

            setQuestions1(formatetData);


        }


    }, [questions]);

    const MoveCodeResult = () => {
        const OptionData = questions1 && questions1.reduce(function (result, item, index) {
            var key1 = Object.keys(item)[0];
            var key2 = Object.keys(item)[1];
            var key3 = Object.keys(item)[2];
            var key4 = Object.keys(item)[3];
            var key5 = Object.keys(item)[4];
            var key6 = Object.keys(item)[5];
            var key7 = Object.keys(item)[6];
            var key8 = Object.keys(item)[7];
            var key9 = Object.keys(item)[8];
            var key10 = Object.keys(item)[9];
            var key11 = Object.keys(item)[10];
            var key12 = Object.keys(item)[11];
            var key13 = Object.keys(item)[12];
            var key14 = Object.keys(item)[13];
            var key15 = Object.keys(item)[14];
            var key16 = Object.keys(item)[15];
            var key17 = Object.keys(item)[16];
            var key18 = Object.keys(item)[17];
            var key19 = Object.keys(item)[18];
            var key20 = Object.keys(item)[19];
            var key21 = Object.keys(item)[20];
            var key22 = Object.keys(item)[21];
            var key23 = Object.keys(item)[22];

            var value1 = item[key1];
            var value2 = item[key2];
            var value3 = item[key3];
            var value4 = item[key4];
            var value5 = item[key5];
            var value6 = item[key6];
            var value7 = item[key7];
            var value8 = item[key8];
            var value9 = item[key9];
            var value10 = item[key10];
            var value11 = item[key11];
            var value12 = item[key12];
            var value13 = item[key13];
            var value14 = item[key14];
            var value15 = item[key15];
            var value16 = item[key16];
            var value17 = item[key17];
            var value18 = item[key18];
            var value19 = item[key19];
            var value20 = item[key20];
            var value21 = item[key21];
            var value22 = item[key22];
            var value23 = item[key23];


            var obj = {};
            obj[key1] = value1;
            obj[key2] = value2;
            obj[key3] = value3;
            obj[key4] = value4;
            obj[key5] = value5;
            obj[key6] = value6;
            obj[key7] = value7;
            obj[key8] = value8;
            obj[key9] = value9;
            obj[key10] = value10;
            obj[key11] = value11;
            obj[key12] = value12;
            obj[key13] = value13;
            obj[key14] = value14;
            obj[key15] = value15;
            obj[key16] = value16;
            obj[key17] = value17;
            obj[key18] = value18;
            obj[key19] = value19;
            obj[key20] = value20;
            obj[key21] = value21;
            obj[key22] = value22;
            obj[value23] = value23;
            result.push(obj);
            return result;
        }, []);
        dispatch(pushResultAction(OptionData))
        dispatch(ButtonUpdateTrace(0));
        setFirstQuestions(questions && questions[0]?.Queid)
    }

    const CheckBoxcheck = (id) => {
        if (checkboxtrue.includes(id)) {
            setCheckboxTrue(checkboxtrue?.filter((item) => item !== id));
            return;
        }
        setCheckboxTrue(prevState => [...prevState, id]);

    }

    const handleCheckboxChange = () => {
        if (checkboxtrue?.length === 0) {
            handleShow();
        }

    }

    useEffect(() => {
    }, [state, questionsshow]);


    useEffect(() => {

    }, [questions1])



    if (state?.id) {
        return (
            <div className='home-main-section'>
                <div className='home-inside-section'>
                    <div className='home-inside-body-width-control'>
                    </div>
                    <div className="home-body-section">
                        {questionsshow ? <div className='home-body-text-sectionss'>
                            <div>
                                <Quiz questionss={questions[0]?.Queid} MockquestionId={state?.id}
                                    type={state?.type}
                                    selectName={state?.selectName}
                                    FirstQuestionId={FirstQuestionId}
                                />
                            </div>
                        </div> : <>
                            <div className='home-body-text-sections'>
                                <div className='text-center mb-3 mt-5'>
                                    <h4>Please read the instructions carefully</h4>
                                </div>

                                <div className='mt-4'>
                                    <h2> {HomeContent?.GeneralInstructions}</h2>
                                </div>
                                <div className='mt-4'>
                                    <span className='fw-bold mb-2 mt-2 fs-5'>Note</span> <span className=''> ( Please Don't Refresh or Reload this page.)</span>
                                </div>

                                <div className='mt-4'>
                                    {HomeContent?.instructions?.map((item, index) => {
                                        return (
                                            <div className='home-main-instructions mt-2 mb-2' key={index}>
                                                <div className='main-home-texts'>
                                                    <div className='left-home-text'>
                                                        <div className='home-text-index'>
                                                            {index + 1} .
                                                        </div>
                                                    </div>
                                                    <div className='right-home-text'>
                                                        <div className='home-text-name'>
                                                            {item?.name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mb-2'>
                                                    {item?.instructionList?.map((items, index) => {
                                                        return (
                                                            <div className='d-flex gap-3 ms-3 mt-3 box-sections' style={{ cursor: "pointer", paddingLeft: "20px" }}>
                                                                <div>
                                                                    {index + 1} .
                                                                </div>
                                                                <div className={index === 0 ? "notvisitbox" : "" || index === 1 ? "notanswerbox" : "" || index === 2 ? "answerdbox" : "" || index === 3 ? "notanswered" : "" || index === 4 ? "answeredandmarked" : ""}>

                                                                </div>
                                                                <div>
                                                                    {items?.name}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='mt-3 mb-3'>
                                    <h2>{HomeContent?.NavigatingtoaQuestion}</h2>
                                </div>
                                <div>
                                    <div className='main-home-texts'>
                                        <div className='left-home-text'>
                                            <div className='home-text-index'>
                                                6.
                                            </div>
                                        </div>
                                        <div className='right-home-text'>
                                            <div className='home-text-name'>
                                                {HomeContent?.Toansweraquestiondothefollowing}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='left-move'>
                                        {HomeContent?.listanswers?.map((item, index) => {
                                            return (
                                                <div>
                                                    <div className='main-home-texts'>
                                                        <div className='left-home-text'>
                                                            <div className='home-text-index'>
                                                                {index === 0 ? "a . " : ""}
                                                                {index === 1 ? "b . " : ""}
                                                                {index === 2 ? "c . " : ""}
                                                            </div>
                                                        </div>
                                                        <div className='right-home-text mb-2'>
                                                            <div className='home-text-name mt-1'>
                                                                {item?.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='mt-3 mb-3'>
                                    <h2>{HomeContent?.AnsweringaQuestion}</h2>
                                </div>
                                <div>
                                    <div className='main-home-texts'>
                                        <div className='left-home-text'>
                                            <div className='home-text-index'>
                                                7 .
                                            </div>
                                        </div>
                                        <div className='right-home-text'>
                                            <div className='home-text-name'>
                                                {HomeContent?.Procedureforansweringamultiplechoicetypequestion}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='left-move'>
                                        {HomeContent?.answersquestionlists?.map((item, index) => {
                                            return (
                                                <div>
                                                    <div className='main-home-texts'>
                                                        <div className='left-home-text'>
                                                            <div className='home-text-index'>
                                                                {index === 0 ? "a . " : ""}
                                                                {index === 1 ? "b . " : ""}
                                                                {index === 2 ? "c . " : ""}
                                                                {index === 3 ? "d . " : ""}
                                                                {index === 4 ? "e . " : ""}
                                                            </div>
                                                        </div>
                                                        <div className='right-home-text mb-2'>
                                                            <div className='home-text-name mt-1'>
                                                                {item?.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='main-home-texts'>
                                    <div className='left-home-text'>
                                        <div className='home-text-index'>
                                            8 .
                                        </div>
                                    </div>
                                    <div className='right-home-text'>
                                        <div className='home-text-name'>
                                            {HomeContent?.toChange}
                                        </div>
                                    </div>
                                </div>

                                <div className='main-home-texts mt-2 mb-2'>
                                    <div className='left-home-text'>
                                        <div className='home-text-index'>
                                            9 .
                                        </div>
                                    </div>
                                    <div className='right-home-text'>
                                        <div className='home-text-name'>
                                            {HomeContent?.section11}
                                        </div>
                                    </div>
                                </div>

                                <div className='empty-border'>

                                </div>

                                <div className='text-danger'>
                                    {HomeContent?.pleasenoton}

                                </div>
                                <div className='empty-border'>

                                </div>



                                <div className='mt-3 d-flex gap-2' onClick={() => CheckBoxcheck(1)} style={{ cursor: "pointer" }}>
                                    <div>
                                        <div class="mb-3 form-check">
                                            {checkboxtrue?.includes(1) ? <>
                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" checked={true} name="checkboxtrue" onClick={() => CheckBoxcheck(1)} />
                                            </> : <>
                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" name="checkboxtrue" onClick={() => CheckBoxcheck(1)} />
                                            </>}
                                        </div>
                                    </div>
                                    <div className='home-checkbox-text'>
                                        <span style={{ color: "red", fontWeight: "500", fontSize: "1rem" }}>{HomeContent?.checkboxload}</span>{HomeContent?.checkboxtext}
                                    </div>
                                </div>

                                <div className='mt-4 mb-5 '>

                                    {checkboxtrue?.length > 0 ? <div className='d-flex align-items-center justify-content-center'>
                                        <button className='startbutton' onClick={() => {
                                            // setQuestionShow(!questionsshow)
                                            setQuestionShow(true)
                                            MoveCodeResult()
                                        }}>
                                            {HomeContent?.starttext}
                                        </button>
                                    </div> : <div className='d-flex align-items-center justify-content-center mb-5'>

                                        <button className='startbutton' onClick={handleCheckboxChange}>
                                            {HomeContent?.starttext}
                                        </button>
                                    </div>}

                                </div>
                            </div>
                        </>}

                    </div>
                </div>

                <Modal show={show} onHide={handleClose} centered>

                    <Modal.Body>
                        <div className='modal-conditions'>
                            <div>
                                <img src={warningimage} alt="no image" className='warning-image-box' />
                            </div>
                            <div className='warining-text'>
                                Warning!
                            </div>
                            <div className='mt-2 mb-3 please-text'>
                                Please accept terms and conditions before proceeding.
                            </div>
                            <div className='mb-4 mt-3'>
                                <button className='ok-btn-texts' onClick={handleClose}>
                                    Ok
                                </button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

            </div>
        )
    }
    else {
        return <Navigate to="/mocktest" />
    }


}

export default NeetInstructionScreen