import { createSlice } from "@reduxjs/toolkit";


const Cart_Reducer = createSlice({
    name: "Cart",
    initialState: {
        loading: false,
        CartData: [],
        dummydata: []
    },
    reducers: {
        CartRequest(state, action) {
            return {
                loading: true
            }
        },
        CartSuccess(state, action) {
            return {
                loading: false,
                CartData: action.payload,
            }
        },
        CartDelete(state, action) {
            return {
                ...state,
                CartData: state.CartData.filter((item, index) => item?._id !== action.payload)
            }
        },
        CartFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

    }
})

const { actions, reducer } = Cart_Reducer;
export const { CartRequest, CartSuccess, CartFail, CartDummy, CartDelete } = actions;
export default reducer;