import React from 'react'
import logo from '../../../assests/logo.svg';
import './Pdfstyle.scss'

import IncorrectResponse from './IncorrectResponse';
import Timemanage from './Timemanage';
import ResponseChangepattern from './ResponseChangepattern';
import SubjectWiseQuestions from './SubjectWiseQuestions';
import { useSelector } from 'react-redux';
import ConceptReport from './ConceptReport';
import Overallreport from './Overallreport';
import moment from 'moment';
function FinalReportDesign() {
    const state = useSelector((state) => state?.report);
    const { ReportData } = state;
    return (
        <div className='main-print-screen'>
            <div className='inside-print-screen'>
                <div className='cdp-logo-section'>
                    <img src={logo} alt="no image" />
                </div>
                <div className='mt-4 text-center mb-3'>
                    <div className='common-title-section fw-bold mt-3 mb-3'>
                        NEET Analys Mock Test Report
                    </div>
                </div>

                <div className='gap-section'>
                    <div className='mt-1'>
                        <div className='section-split-pdf'>
                            <div className='left-section-pdf'>
                                <div className='title-section'>
                                    Exam Name
                                </div>
                                <div className='title-section-name'>
                                    NEET 2024 Mock
                                </div>
                            </div>
                            <div className='right-section-pdf'>
                                <div className='title-section'>
                                    Name
                                </div>
                                <div className='title-section-name'>
                                    {ReportData?.overAllReport?.examDetails?.studentName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-1'>
                        <div className='section-split-pdf'>
                            <div className='left-section-pdf'>
                                <div className='title-section'>
                                    Marks
                                </div>
                                <div className='title-section-name'>
                                    {ReportData?.overAllReport?.examDetails?.totalMark > 10 ? <>{ReportData?.overAllReport?.examDetails?.totalMark}</> : <>{ReportData?.overAllReport?.examDetails?.totalMark}</>} / 720
                                </div>
                            </div>
                            <div className='right-section-pdf'>
                                <div className='title-section'>
                                    CDP Roll Number
                                </div>
                                <div className='title-section-name'>
                                    CDP00010<span className='text-cap'>{ReportData?.overAllReport?.examDetails?.studentName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-1'>
                        <div className='section-split-pdf'>
                            <div className='left-section-pdf'>
                                <div className='title-section'>
                                    Exam Date
                                </div>
                                <div className='title-section-name'>
                                    {moment(ReportData?.overAllReport?.examDetails?.examDate).format("MMM Do YY")}
                                </div>
                            </div>
                            <div className='right-section-pdf'>
                                <div className='title-section'>

                                </div>
                                <div className='title-section-name'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-5 mb-5 ms-4'>
                    <div className='title-section-name'>
                        Subject wise marks
                    </div>
                    <div className='mt-2 below-texts'>
                        Below graph gives the percentage of marks scored by you in each subject.
                    </div>
                </div>

                {/* <div className='mb-5 mt-2 ms-4'>
                    <Barchart datas={ReportData?.overAllReport?.examDetails} />
                </div> */}
                <div className='table-section mt-5 mb-5'>
                    <table class="table  table-striped table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Subjects</th>
                                <th>Biology</th>
                                <th>Physics</th>
                                <th>Chemistry</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>Marks</td>
                                <td>{ReportData?.overAllReport?.examDetails?.biologyMark > 10 ? <>{ReportData?.overAllReport?.examDetails?.biologyMark}</> : <>{ReportData?.overAllReport?.examDetails?.biologyMark}</>}</td>
                                <td>{ReportData?.overAllReport?.examDetails?.chemistryMark > 10 ? <>{ReportData?.overAllReport?.examDetails?.chemistryMark}</> : <>{ReportData?.overAllReport?.examDetails?.chemistryMark}</>}</td>
                                <td>{ReportData?.overAllReport?.examDetails?.physicsMark > 10 ? <>{ReportData?.overAllReport?.examDetails?.physicsMark}</> : <>{ReportData?.overAllReport?.examDetails?.physicsMark}</>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='mt-5' style={{ paddingTop: "4%" }}>
                    <IncorrectResponse data={ReportData?.overAllReport?.questionRes} />
                </div>
                <div className='mt-3' style={{ paddingTop: "2%", paddingBottom: "2%" }}>
                    <Timemanage data={ReportData?.overAllReport?.timeManagement} />
                </div>
                <div className='mt-5' style={{ paddingTop: "5%" }}>
                    <ResponseChangepattern data={ReportData?.overAllReport?.questionRes} />
                </div>
                <div className='mt-5' style={{ paddingTop: "2%" }}>
                    <SubjectWiseQuestions data={ReportData?.questionReport} />
                </div>
                <div className='mt-5' style={{ paddingTop: "2%" }}>
                    <ConceptReport data={ReportData?.chapterReport} />
                </div>
                <div className='mt-5' style={{ paddingTop: "2%" }}>
                    <Overallreport data={ReportData?.chapterReport} />
                </div>
            </div>

        </div>
    )
}

export default FinalReportDesign