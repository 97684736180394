import { rankFilterService, rankService } from "../../../services/rank/rank_services"
import { RankLocationFilterFail, RankLocationFilterRequest, RankLocationFilterSuccess } from "../../reducers/RankFilterLocations_reducer";
import { RankFail, RankRequest, RankSuccess } from "../../reducers/Rank_reducer"
export const RankReportActions = (data, current) => async (dispatch) => {
    dispatch(RankRequest())
    try {
        const response = await rankService(data, current);
        if (response) {
            dispatch(RankSuccess(response))
        }
    } catch (error) {
        dispatch(RankFail("Something Went Wrong"));
    }
}



export const RankReportFilterActions = (data) => async (dispatch) => {
    dispatch(RankLocationFilterRequest())
    try {
        const response = await rankFilterService(data);
        if (response) {
            dispatch(RankSuccess(response))
        }
    } catch (error) {
        dispatch(RankLocationFilterFail("Something Went Wrong"));
    }
}