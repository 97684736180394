import { getAllNeetExams } from "../../../services/neetservices/neet_services";
import { NeetExamFail, NeetExamRequest, NeetExamSuccess } from "../../reducers/Neet_reducer";


export const GetNeetAllExams = (data) => async (dispatch) => {
    dispatch(NeetExamRequest())
    try {
        const response = await getAllNeetExams(data);
        if (response) {
            dispatch(NeetExamSuccess(response?.result))
        }
    } catch (error) {
        dispatch(NeetExamFail(error))
    }
}