import { createSlice } from "@reduxjs/toolkit";


const Reload_Reducer = createSlice({
    name: "Reload",
    initialState: {
        loading: false,
        ReloadData: false,
    },
    reducers: {
        ReloadRequest(state, action) {
            return {
                loading: true
            }
        },
        ReloadSuccess(state, action) {
            return {
                loading: false,
                ReloadData: action.payload,
            }
        },
        ReloadFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

    }
})

const { actions, reducer } = Reload_Reducer;
export const { ReloadRequest, ReloadSuccess, ReloadFail } = actions;
export default reducer;