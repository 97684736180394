
import React, { useEffect, useState } from 'react'
import './Questions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { VisitedQuestionCountAction, updateResultAction } from '../../../../redux/reducers/Result_reducer';
import ReactHtmlParser from 'react-html-parser';
import { Triangle } from 'react-loader-spinner'
function Questions({ OnCheck, spendTimes }) {
    const [checked, setChecked] = useState(null);
    const dispatch = useDispatch();
    const states = useSelector((state) => state);
    const { trace } = states?.count;
    const { QuestionData } = states?.questions;
    const { result } = states?.result;
    const { QuestionOptiondata } = states?.questionsoption;
    useEffect(() => {
        dispatch(updateResultAction({ trace, checked }))
    }, [checked, QuestionOptiondata])

    const onSelect = async (i, current) => {
        const responseData = QuestionData?.data[0].answeroptions;
        const CorrectAnswer = responseData.findIndex(x => x.answer === true);
        const YourAnswer = current;
        const updateId = QuestionOptiondata[trace].Queid;
        const resultdata = result.find((item) => item?.oriquestionId === updateId);
        const newUpdate = {
            id: i?._id,
            name: i?.label,
            answer: i?.answer,
            questionId: trace + 1,
            savequestionId: resultdata?.savequestionId ? resultdata?.savequestionId : `${trace + 1}e`,
            subject: QuestionOptiondata[trace]?.name,
            oriquestionId: QuestionData?.data[0]?.assessmentQuestionId,
            chapterName: QuestionData?.data[0]?.chapters?.chapterName,
            your_responce: YourAnswer === 0 ? "A" : null || YourAnswer === 1 ? "B" : null || YourAnswer === 2 ? "C" : null || YourAnswer === 3 ? "D" : null,
            correct_responses: CorrectAnswer === 0 ? "A" : null || CorrectAnswer === 1 ? "B" : null || CorrectAnswer === 2 ? "C" : null || CorrectAnswer === 3 ? "D" : null,
            subTopic: QuestionData?.data[0]?.subTopic,
            score: i?.answer ? "4" : "0",
            difficulty_level: QuestionData?.data[0]?.assessmentLevel,
            markedforreviewId: "",
            answeredandmarkedforreviewId: "",
            visitedquestions: Number(resultdata?.visitedquestions) ? Number(resultdata?.visitedquestions) : 1,
            spendtime: resultdata?.spendtime ? resultdata?.spendtime : spendTimes,
            correct_to_incorrect: "",
            incorrect_to_correct: "",
            incorrect_to_incorrect: "",
            correct_to_unanswered: "",
            incorrect_to_unanswered: "",
            correct_to_correct: ""
        }
        setChecked(newUpdate);
        OnCheck(newUpdate);
        dispatch(updateResultAction({ trace, checked }))
        dispatch(VisitedQuestionCountAction(newUpdate));
    }
    useEffect(() => {
    }, [QuestionOptiondata, QuestionData])
    return (
        <>
            {QuestionData?.data[0].answeroptions ? <>
                <div className='question-section-main mb-5'>
                    <div className='qustion-title mb-4'>
                        Question {trace + 1}
                    </div>
                    <div className='mt-2 question-title-name'>
                        {ReactHtmlParser(QuestionData?.data[0]?.question)}
                    </div>
                    <div className='main-boxs mb-5'>
                        <ul key={trace} style={{ paddingBottom: "10%" }} className='mb-5'>
                            {
                                QuestionData?.data[0].answeroptions?.map((q, i) => {
                                    return (
                                        <li key={i} onClick={() => onSelect(q, i)} style={{ cursor: "pointer" }}>
                                            <input
                                                type="radio"
                                                value={false}
                                                checked={result[trace]?.id === q?._id}
                                                name="options"
                                                id={`q${i}-option`}
                                                onChange={() => onSelect(q, i)}
                                            />
                                            <label className='text-black' htmlFor={`q${i}-option`}>
                                                {ReactHtmlParser(q.label)}
                                            </label>

                                            <div className={`check ${result[trace]?.id === q?._id ? "checked" : ""}`}></div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>

                </div>
            </> : <div className='d-flex align-items-center justify-content-center question-section-main' style={{ width: "100%", height: "480px" }}>

                <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#e14401"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>}


        </>
    )
}

export default Questions