import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { GetSingleNewsandArticlesData } from '../../redux/actions/NewsandarticlesAction/NewsandarticleActions';
import './styles/Singlenews.scss';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

function SingleNewsandArticles() {

    const stateid = useLocation();


    const dispatch = useDispatch();

    const states = useSelector((state) => state?.NewsandArticles?.SinglenewsData);


    useEffect(() => {
        dispatch(GetSingleNewsandArticlesData(stateid?.state))
    }, [stateid?.state])
    return (
        <div className='main-single-card-section'>
            <div className='left-single-card'>
                <div>
                    {states?.PostData?.map((item, index) => {
                        return (
                            <div key={index}>
                                <img
                                    alt="No Article Image"
                                    src={`${process.env.REACT_APP_IMAGE_URL1}/${item?.imageName}`}
                                    className="new-image-card"
                                />

                                <div className='mt-4 mb-5'>

                                    {item?.postContent ? <>
                                        {ReactHtmlParser(item?.postContent)}

                                    </> : <>
                                        {ReactHtmlParser(
                                            states?.newsContent
                                        )}
                                    </>}


                                </div>
                            </div>
                        )
                    })}

                    <div>

                    </div>
                </div>

                <div className='mb-4'>
                    <div>
                        <h3>Offering Articles</h3>
                    </div>
                </div>
                <div className='mb-5 mt-3'>

                    {states?.Offerings?.map((item, index) => {
                        return (
                            <div key={index} className='mt-3 mb-2 display-cards'>
                                <div>
                                    <img
                                        alt="No Article Image"
                                        src={`${process.env.REACT_APP_IMAGE_URL1}/${item?.imgName}`}
                                        className="new-image-card-new"
                                    />
                                </div>
                                <div>
                                    <div>
                                        {item?.title}

                                    </div>
                                    <div>

                                        {moment(item?.createDate).format('LLL')}

                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
            <div className='right-single-card'>
                <div className='mb-4'>
                    <div>
                        <h3>Related Articles</h3>
                    </div>
                </div>
                <div className=''>

                    {states?.CourseData?.map((item, index) => {
                        return (
                            <div key={index} className='mt-3 mb-2 display-cards'>
                                <div>
                                    <img
                                        alt="No Article Image"
                                        src={`${process.env.REACT_APP_IMAGE_URL1}/${item?.imgName}`}
                                        className="new-image-card-new"
                                    />
                                </div>
                                <div>
                                    <div>
                                        {item?.title}

                                    </div>
                                    <div>

                                        {moment(item?.createDate).format('LLL')}

                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>



                <div className='mt-5 mb-4'>
                    <div>
                        <div>
                            <h3>Trending Articles</h3>
                        </div>
                    </div>
                    <div className=''>

                        {states?.TrendingItems?.map((item, index) => {
                            return (
                                <div key={index} className='mt-3 mb-2 display-cards'>
                                    <div>
                                        <img
                                            alt="No Article Image"
                                            src={`${process.env.REACT_APP_IMAGE_URL1}/${item?.imgName}`}
                                            className="new-image-card-new"
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            {item?.title}

                                        </div>
                                        <div>

                                            {moment(item?.createDate).format('LLL')}

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                </div>


            </div>
        </div>
    )
}

export default SingleNewsandArticles