import React from 'react'
import './Pdfstyle.scss';
function Timemanage({ data }) {
    return (
        <div>
            <div className='ms-3'>
                <div className='common-title-section mt-5 mb-2'>
                    Time Management

                </div>
                <div>
                    Time Management is very critical in any competitive exam. This table provides an overview of how
                    you managed the time against the optimum time recommended by experts.
                </div>
            </div>
            <div className='mt-4'>
                <div className='table-section'>
                    <table class="table table-striped table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th className='th-bg'>Subjects</th>
                                <th className='th-bg'>Total Time Taken(min) </th>
                                <th className='th-bg'>Optimum Time (min)</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item?.subject}</td>
                                        <td>{item?.timeTaken}</td>
                                        <td>{item?.optimumTime}</td>
                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Timemanage