import { createSlice } from "@reduxjs/toolkit";
const Purchase_Reducer = createSlice({
    name: "Purchase",
    initialState: {
        loading: false,
        PurchaseData: [],
    },
    reducers: {
        PurchaseRequest(state, action) {
            return {
                loading: true
            }
        },
        PurchaseSuccess(state, action) {
            return {
                loading: false,
                PurchaseData: action.payload
            }
        },
        PurchaseFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Purchase_Reducer;
export const { PurchaseRequest, PurchaseSuccess, PurchaseFail } = actions;
export default reducer;