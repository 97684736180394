import { createSlice } from "@reduxjs/toolkit";
const RankLocation_Reducer = createSlice({
    name: "RankLocation",
    initialState: {
        loading: false,
        RankLocationData: [],
    },
    reducers: {
        RankLocationRequest(state, action) {
            return {
                loading: true
            }
        },
        RankLocationSuccess(state, action) {
            return {
                loading: false,
                RankLocationData: action.payload
            }
        },
        RankLocationFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = RankLocation_Reducer;
export const { RankLocationRequest, RankLocationSuccess, RankLocationFail } = actions;
export default reducer;