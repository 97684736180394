import React, { Component } from 'react';
import FinalReportDesign from './FinalReportDesign';

export default class componentName extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { name, data, newref } = this.props;
        return (
            <div>
                <FinalReportDesign name={name} data={data} newref={newref} />
            </div>
        );
    }
}
