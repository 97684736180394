import { createSlice } from "@reduxjs/toolkit";


const Cartstore_Reducer = createSlice({
    name: "Cartstore",
    initialState: {
        loading: false,
        CartStoreData: [],
    },
    reducers: {
        CartstoreRequest(state, action) {
            return {
                loading: true
            }
        },
        CartstoreSuccess(state, action) {
            state.CartStoreData.push(action.payload)
        },
        CartStoreDelete(state, action) {
            return {
                ...state,
                CartStoreData: state.CartStoreData.filter((item, index) => item?._id !== action.payload)
            }
        },
        CartstoreFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }

    }
})

const { actions, reducer } = Cartstore_Reducer;
export const { CartstoreRequest, CartstoreSuccess, CartstoreFail, CartStoreDelete } = actions;
export default reducer;