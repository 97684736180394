import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ChangeForgetPassword from "../components/auth/components/forgetpassword/ChangeForgetPassword";
import SessionClearcomponent from "../components/sessionclearcomponent/SessionClearcomponent";
import { useDispatch, useSelector } from "react-redux";
import { GetProfileAction } from "../redux/actions/UserprofileAction/UserprofileAction";
import Loader from "../Loader/Loader";
import SessionLogout from "../components/sessionexpiredcomponent/SessionLogout";
import SingleNewsandArticles from "../components/newsandarticles/SingleNewsandArticles";
import Mocktest from "../components/mocktest/Mocktest";
import ListMockTest from "../components/mocktest/ListMockTest";
import Offline from "../components/Offline/Offline";
import NeetInstructionScreen from "../components/mocktest/components/neetInstructionScreen/NeetInstructionScreen";
import MockPayment from "../components/mocktest/mockPayment/MockPayment";
import Rankresult from "../components/mocktest/components/rank/Rankresult";

const Signin = lazy(() => import("../components/auth/signin/Signin"));
const Signup = lazy(() => import("../components/auth/signup/Signup"));
const ProtectedRouter = lazy(() => import("./ProtectedRouter"));
const PageNotFound = lazy(() =>
  import("../components/pagenotfound/PageNotFound")
);
const Home = lazy(() => import("./../components/home/Home"));
const Dashboard = lazy(() => import("../dashborad/Dashboard"));
const Courses = lazy(() => import("../components/courses/Courses"));
const ChatGpt = lazy(() => import("../components/chatgpt/ChatGpt"));
const Buycourses = lazy(() =>
  import("../components/courses/component/Buycourses/Buycourses")
);
const Examcourses = lazy(() =>
  import("../components/courses/component/Examcourses/Examcourses")
);
const Bundleclass = lazy(() =>
  import("../components/courses/component/BundleClass/Bundleclass")
);
const Purchases = lazy(() => import("../components/purchase/Purchase"));
const Subscribe = lazy(() =>
  import("../components/courses/component/Subscribe/Subscribe")
);
const Coursecontent = lazy(() =>
  import("../components/courses/component/Coursecontent/Coursecontent")
);
const Helpandsupports = lazy(() =>
  import("../components/helpandsupports/Helpandsupports")
);
const Changepassword = lazy(() =>
  import("../components/changepassword/Changepassword")
);
const ContentPlayPage = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ContentPlayPage/ContentPlayPage"
  )
);
const PratiseIns = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ContentPlayPage/PratiseIns"
  )
);
const AssessIns = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ContentPlayPage/AssessIns"
  )
);
const PractiseExam = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ExamPage/PractiseExam"
  )
);
const AssessmentExam = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ExamPage/AssessmentExam"
  )
);
const PractiseResultPage = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ResultPage/PractiseResultPage"
  )
);
const AssessmentResultPage = lazy(() =>
  import(
    "../components/courses/component/Buycourses/component/ResultPage/AssessmentResultPage"
  )
);
const Forgetpassword = lazy(() =>
  import("../components/auth/components/forgetpassword/Forgetpassword")
);
const MyCart = lazy(() => import("../components/cart/MyCart"));
const Userprofile = lazy(() => import("../components/userprofile/Userprofile"));
const TestSpace = lazy(() => import("../components/testspace/TestSpace"));
const NotificationScreen = lazy(() =>
  import("../components/notification/NotificationScreen")
);
const QuestionPortalScreen = lazy(() =>
  import("../components/questionportalscreen/QuestionPortalScreen")
);
const MyCourses = lazy(() => import("../components/MyCourses/MyCourses"));
const Teamsandconditions = lazy(() =>
  import("../components/teamsandconditions/Teamsandconditions")
);

const NewsandArticles = lazy(() =>
  import("../components/newsandarticles/NewsandArticles")
);

const Result = lazy(() =>
  import("./../components/mocktest/components/result/Result")
);

function RouterIndex() {
  return (
    <section>
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Signin />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/verify-token" element={<ChangeForgetPassword />} />
          <Route path="/logoutuser" element={<SessionClearcomponent />} />
          <Route path="/logoutsession" element={<SessionLogout />} />
          <Route path="/teamsandconditions" element={<Teamsandconditions />} />
          <Route path="/teamsandconditions" element={<Teamsandconditions />} />
          <Route element={<ProtectedRouter></ProtectedRouter>}>
            <Route path="/home" element={<Home />}></Route>

            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/course" element={<Courses />}></Route>
            <Route path="/buycourse/:id" element={<Buycourses />} />
            <Route path="/examcourse/:id" element={<Examcourses />} />
            <Route path="/bundleclass" element={<Bundleclass />} />
            <Route path="/mypurchase" element={<Purchases />}>
              {" "}
            </Route>
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/coursecontent" element={<Coursecontent />} />
            <Route path="/helpandsupports" element={<Helpandsupports />} />
            <Route path="/chatgpt" element={<ChatGpt />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/coursecontentplay" element={<ContentPlayPage />} />
            <Route path="/practiceIns" element={<PratiseIns />} />
            <Route path="/assessmentIns" element={<AssessIns />} />
            <Route path="/practiceexam" element={<PractiseExam />} />
            <Route path="/assessmentexam" element={<AssessmentExam />} />
            <Route path="/practiceresult" element={<PractiseResultPage />} />
            <Route
              path="/assessmentresult"
              element={<AssessmentResultPage />}
            />
            <Route path="/cart" element={<MyCart />}></Route>
            <Route path="/profile" element={<Userprofile />}></Route>
            <Route path="/testspace" element={<TestSpace />}></Route>
            <Route path="/qa" element={<QuestionPortalScreen />}></Route>
            <Route
              path="/newsandarticles"
              element={<NewsandArticles />}
            ></Route>

            <Route
              path="/newsandarticlessingle"
              element={<SingleNewsandArticles />}
            ></Route>

            <Route
              path="/notification"
              element={<NotificationScreen />}
            ></Route>
            <Route path="/mycourses/:id" element={<MyCourses />}></Route>
            {/* mock test routing paths */}
            <Route path="/mocktest" element={<Mocktest />}></Route>
            <Route path="/mocktest/list" element={<ListMockTest />}></Route>
            <Route path="/mocktest/rank" element={<Rankresult />}></Route>

            {/* neet exam */}
            <Route path="/mockexam" element={<NeetInstructionScreen />} />
            <Route path="/payment" element={<MockPayment />} />
            <Route path="/result" element={<Result />} />
          </Route>
          <Route path="/offline" element={<Offline />}></Route>

          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </Suspense>
    </section>
  );
}

export default RouterIndex;
