import { createSlice } from "@reduxjs/toolkit";
const RankLocationFilter_Reducer = createSlice({
    name: " RankLocationFilter",
    initialState: {
        loading: false,
        RankLocationFilterData: [],
    },
    reducers: {
        RankLocationFilterRequest(state, action) {
            return {
                loading: true
            }
        },
        RankLocationFilterSuccess(state, action) {
            return {
                loading: false,
                RankLocationFilterData: action.payload
            }
        },
        RankLocationFilterFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = RankLocationFilter_Reducer;
export const { RankLocationFilterRequest, RankLocationFilterSuccess, RankLocationFilterFail } = actions;
export default reducer;