import { createSlice } from "@reduxjs/toolkit";


const Profile_Reducer = createSlice({
    name: "Profile",
    initialState: {
        loading: false,
        ProfileData: [],

    },
    reducers: {
        ProfileRequest(state, action) {
            return {
                loading: true
            }
        },
        ProfileSuccess(state, action) {
            return {
                loading: false,
                ProfileData: action.payload,

            }
        },
        ProfileFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

    }
})

const { actions, reducer } = Profile_Reducer;
export const { ProfileRequest, ProfileSuccess, ProfileFail } = actions;
export default reducer;