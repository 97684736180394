import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import './styles/Listmocktest.scss';
import { useNavigate } from 'react-router-dom';
import ConvertPdf from './PdfReportData/ConvertPdf';
import { useDispatch } from 'react-redux';
import { GetReportActionUser } from '../../redux/actions/reportDownloadactions/ReportdownloadActions';
import { useSelector } from 'react-redux';
function ListMockTest() {
    const { state } = useLocation();
    const stateReport = useSelector((state) => state?.report);
    const { ReportData } = stateReport;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const FreemockTestNeet = (masterId, id) => {
        navigate("/mockexam", {
            state: {
                masterId: masterId,
                type: "bundle",
                id: id,
                selectName: state?.selectName,
            },
        });
    };
    const ReportDownload = async (masterId, id) => {
        try {
            const data = {
                mockQuestionId: id,
                type: "bundle",
                questionId: masterId,
            }
            await dispatch(GetReportActionUser(data));
        } catch (error) {
        }
    }

    useEffect(() => {

    }, [ReportData])

    const NavigateRank = () => {
        navigate(`/mocktest/rank`, { state: { totalMark: "" } })
    }
    return (
        <div className='main-list-mock-test'>
            <div className="inside-mock-testlist">
                <div className='ms-5 mb-2'>
                    <h3>Mock Exam</h3>
                </div>
                {state?.data?.questionDocs?.map((item, index) => {
                    return (
                        <div className='mock-cards' key={index}>
                            <div className='first-name' key={index}>
                                <div>
                                    {state?.data?.name}
                                </div>
                            </div>
                            <div className='buttons-list'>
                                <div>
                                    <button className='start-text-btn'
                                        onClick={() =>
                                            FreemockTestNeet(state?.data?._id, item?.mockQuestionId)
                                        }
                                    >Start Test</button>
                                </div>
                                <div onClick={() => ReportDownload(state?.data?._id, item?.mockQuestionId)}>
                                    <ConvertPdf />
                                </div>
                                <div>
                                    <button className='start-text-btn' onClick={NavigateRank}>Rank</button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ListMockTest