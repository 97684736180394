import { createSlice } from "@reduxjs/toolkit";
const Qa_Reducer = createSlice({
    name: "Qa",
    initialState: {
        loading: false,
        QaData: [],
    },
    reducers: {
        QaRequest(state, action) {
            return {
                loading: true
            }
        },
        QaSuccess(state, action) {
            return {
                loading: false,
                QaData: action.payload
            }
        },
        QaFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Qa_Reducer;
export const { QaRequest, QaSuccess, QaFail } = actions;
export default reducer;