import { createSlice } from "@reduxjs/toolkit";
const Report_Reducer = createSlice({
    name: "Report",
    initialState: {
        loading: false,
        ReportData: [],
    },
    reducers: {
        ReportRequest(state, action) {
            return {
                loading: true
            }
        },
        ReportSuccess(state, action) {
            return {
                loading: false,
                ReportData: action.payload
            }
        },
        ReportFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Report_Reducer;
export const { ReportRequest, ReportSuccess, ReportFail } = actions;
export default reducer;