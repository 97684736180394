import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './AnsweredModal.scss';
function AnswerModal({ showAnsweredmodal, hanldeClose, title, answeredcount, answeredcountresult,
    notansweredcountresult, notansweredcount, notvisitedcount,
    markedforreviewcount, markedforreviewresult,
    savemarkedforreviewcount, savemarkedforreviewresult


}) {


    return (
        <div className='Modals'>
            <Modal show={showAnsweredmodal} onHide={hanldeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>

                        <span className='fw-bold cursor'>{title}</span>
                        {title === "Answered" ? <>
                            <span className='save-text ms-2'>({answeredcount?.length})</span>
                        </> : null}
                        {title === "Not Answered" ? <>
                            <span className='empty-text ms-2'>({notansweredcountresult?.length})</span>
                        </> : null}
                        {title === "Not Visited" ? <>
                            <span className='not-visited-text ms-2'>({notvisitedcount?.length})</span>
                        </> : null}
                        {title === "Marked for Review" ? <>
                            <span className='box-mark-text-color ms-2'>({markedforreviewcount?.length})</span>
                        </> : null}
                        {title === "Save & Marked for Review" ? <>
                            <span className='save-box-mark-text-color ms-2'>({savemarkedforreviewcount?.length})</span>
                        </> : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='option-borders'>
                        <div>
                            {title === "Answered" ? <div className='row gap-2 round-box-list mt-2 mb-2'>
                                {
                                    answeredcount?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className="round-box mb-2"
                                            >
                                                <div
                                                    className={`${answeredcountresult?.includes(`${item}s`) ? "save-box" : ""}`}
                                                >
                                                    {item > 9 ? <>{item}</> : <>0{item}</>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null}
                        </div>
                        <div>
                            {title === "Not Answered" ? <div className='row gap-2 round-box-list mt-2 mb-2'>
                                {
                                    notansweredcountresult?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className="round-box mb-2"
                                            >
                                                <div
                                                    className={`${notansweredcount?.includes(`${item}e`) ? "box-empty" : ""}`}
                                                >
                                                    {item > 9 ? <>{item}</> : <>0{item}</>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null}
                        </div>
                        <div>
                            {title === "Not Visited" ? <div className='row gap-2 round-box-list mt-2 mb-2'>
                                {
                                    notvisitedcount?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className="round-box mb-2"
                                            >
                                                <div
                                                    className={"box-color-round"}
                                                >
                                                    {item > 9 ? <>{item}</> : <>0{item}</>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null}
                        </div>

                        <div>
                            {title === "Marked for Review" ? <div className='row gap-2 round-box-list mt-2 mb-2'>
                                {
                                    markedforreviewcount?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className="round-box mb-2"
                                            >
                                                <div
                                                    className={`${markedforreviewresult?.includes(`${item}em`) ? "box-mark-color" : ""}`}

                                                >
                                                    {item > 9 ? <>{item}</> : <>0{item}</>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null}
                        </div>

                        <div>
                            {title === "Save & Marked for Review" ? <div className='row gap-2 round-box-list mt-2 mb-2'>
                                {
                                    savemarkedforreviewcount?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className="round-box mb-2"
                                            >
                                                <div
                                                    className={`${savemarkedforreviewresult?.includes(`${item}am`) ? "saveandmark-btn" : ""}`}

                                                >
                                                    {item > 9 ? <>{item}</> : <>0{item}</>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null}
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </div >
    )
}

export default AnswerModal
