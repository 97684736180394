import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { SpinersLoad } from '../../spinners/Spinners';
import './MockPayment.scss';
import { useLocation } from 'react-router-dom';
import goldimg from "../../../assests/images/gold-img.png";
import silverimg from "../../../assests/images/silver-img.png";
import bronzeimg from "../../../assests/images/bronze-img.png";
import { useNavigate } from 'react-router-dom';
import { ToastSuccess } from '../../../toastmessages/Toastmessages';
import logo from "../../../assests/images/logo.svg";
import { PaymentMockTests } from '../../../services/neetservices/PaymentMock';
import { useSelector } from 'react-redux';

function MockPayment() {

    const navigate = useNavigate();

    const { state } = useLocation();
    const [loading, setLoading] = useState(false);

    const states = useSelector((state) => state?.neetExams);
    const { NeetExamData } = states;
    console.log(NeetExamData, 'state')


    const filterDataPayment = NeetExamData && NeetExamData?.mockBundle?.filter((item) => item?._id === state?.data?._id);
    console.log(filterDataPayment, 'NeetExamData')
    useEffect(() => {

    }, [state]);

    const GstValue = Math.max(
        0,
        // Math.round(ToatlCaluculateValue - (ToatlCaluculateValue * 18) / 100)
        ((state?.data?.price * 18) / 100)

    );
    const TotalPrice = Number(state?.data?.price) + Number(GstValue);

    const typeIndividual = Number(99) + Number(17.82);
    const FinalPriceValue = state?.type == "individual" ? typeIndividual : TotalPrice.toFixed(2);
    // payment method
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const paymentClick = async () => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        setLoading(true);

        const payment_values = {}

        if (state?.type == "individual") {
            const payment_values = {
                questionId: state?.individualQuestionId,
                type: state?.type,
                examType: state?.selectName
            };
            PaymentMockTests(payment_values)
                .then((res) => {
                    const data = res?.order;
                    const options = {
                        key: process.env.REACT_APP_PAYMENT_KEY,
                        currency: data.currency,
                        amount: data?.amount,
                        order_id: data.id,
                        name: process.env.REACT_APP_CDPNAME,
                        description: "Paying For Course",
                        image: logo,
                        handler: async function (data) {
                            ToastSuccess("Payment Paid Successfully");
                            setTimeout(() => {
                                navigate("/mocktest")
                            }, 1000);
                            setLoading(false);
                        },
                        modal: {
                            ondismiss: async function () {
                                setLoading(false);
                            },
                        },
                    };
                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
        else {
            const payment_values = {
                questionId: state?.data?._id,
                type: state?.type,
                examType: state?.selectName
            };
            PaymentMockTests(payment_values)
                .then((res) => {
                    const data = res?.order;
                    const options = {
                        key: process.env.REACT_APP_PAYMENT_KEY,
                        currency: data.currency,
                        amount: data?.amount,
                        order_id: data.id,
                        name: process.env.REACT_APP_CDPNAME,
                        description: "Paying For Course",
                        image: logo,
                        handler: async function (data) {
                            ToastSuccess("Payment Paid Successfully");
                            setTimeout(() => {
                                navigate("/mocktest")
                            }, 1000);
                            setLoading(false);
                        },
                        modal: {
                            ondismiss: async function () {
                                setLoading(false);
                            },
                        },
                    };
                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
                })
                .catch((err) => {
                    setLoading(false);
                });
        }


        PaymentMockTests(payment_values)
            .then((res) => {
                const data = res?.order;
                const options = {
                    key: process.env.REACT_APP_PAYMENT_KEY,
                    currency: data.currency,
                    amount: data?.amount,
                    order_id: data.id,
                    name: process.env.REACT_APP_CDPNAME,
                    description: "Paying For Course",
                    image: logo,
                    handler: async function (data) {
                        ToastSuccess("Payment Paid Successfully");
                        setTimeout(() => {
                            navigate("/mocktest")
                        }, 1000);
                        setLoading(false);
                    },
                    modal: {
                        ondismiss: async function () {
                            setLoading(false);
                        },
                    },
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    console.log(state, 'state')

    return (
        <div>
            <div className="subscribe-main-container">
                <div className='mb-5'>
                    <h3 className="mt-3">Payment</h3>
                </div>
                <>
                    <div className="subscribe-submain-container">
                        <div className="subscribe-left-container">
                            <div className="amount-container mt-4">
                                <div className="leftside-amount">
                                    <p>Price</p>
                                    <div className="d-flex gap-2">
                                        <p>GST at 18%</p>
                                    </div>
                                    <p>Total </p>
                                </div>
                                <div className="rightside-amount">
                                    <p className="rupee">
                                        ₹ <span>{state?.type == "individual" ? "99" : state?.data?.price}</span>
                                    </p>
                                    <p>
                                        <span className="rupee"> ₹ {state?.type == "individual" ? "17.82" : GstValue}</span>
                                    </p>
                                    <p>
                                        <span className="rupee"> ₹ {FinalPriceValue}</span>
                                    </p>
                                </div>
                            </div>
                            <div></div>
                            <div className="mt-4 d-flex align-align-items-center" onClick={paymentClick}>
                                <Button className="loginButtonbuynow">
                                    {loading ? (
                                        <>
                                            <SpinersLoad />
                                        </>
                                    ) : (
                                        <>Buy Now</>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <div className="rightside-full">
                            <div className="image-card-box">
                                {filterDataPayment?.map((item, index) => {
                                    return (
                                        <div key={index}>

                                            {item?.questionDocs?.map((items, index) => {
                                                return (
                                                    <div key={index} className="main-card-section-subscribe align-items-center d-flex gap-4 justify-content-between mb-3">
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            {item?.category === "BRONZE" ? <>
                                                                <div>
                                                                    <img src={bronzeimg} alt="no image" className='imge-borders' />
                                                                </div>
                                                            </> : <></>}
                                                            {item?.category === "GOLD" ? <>
                                                                <div>
                                                                    <img src={goldimg} alt="no image" className='imge-borders' />
                                                                </div>
                                                            </> : <></>}
                                                            {item?.category === "SILVER" ? <>
                                                                <div>
                                                                    <img src={silverimg} alt="no image" className='imge-borders' />
                                                                </div>
                                                            </> : <></>}


                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            {items?.name}
                                                        </div>
                                                        <div className="right-text-price-subscribe-box">
                                                            <div className="rupee d-flex gap-1">
                                                                {/* ₹ <span className="amount-text">{state?.data?.price}</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>

            </div>
        </div>
    )
}

export default MockPayment