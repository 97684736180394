import { createSlice } from "@reduxjs/toolkit";
const Course_Reducer = createSlice({
    name: "Course",
    initialState: {
        loading: false,
        CourseData: [],
        SuggestedCourses:[]
    },
    reducers: {
        CourseRequest(state, action) {
            return {
                loading: true
            }
        },
        CourseSuccess(state, action) {
            return {
                loading: false,
                CourseData: action.payload
            }
        },
        CourseFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Course_Reducer;
export const { CourseRequest, CourseSuccess, CourseFail } = actions;
export default reducer;