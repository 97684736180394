import { createSlice } from "@reduxjs/toolkit";
const Button_Reducer = createSlice({
    name: "button",
    initialState: {
        loading: false,
        trace: 0,
    },
    reducers: {
        ButtonIncrement(state, action) {
            return {
                ...state,
                trace: state.trace + 1
            }
        },
        ButtonDecrement(state, action) {
            return {
                ...state,
                trace: state.trace - 1
            }
        },
        ButtonUpdateTrace(state, action) {
            state.trace = action.payload

        },
        ClearButtonData: (state, action) => {
            return {
                ...state,
                trace: 0
            }
        }
    }
})

const { actions, reducer } = Button_Reducer;
export const { ButtonIncrement, ButtonDecrement, ButtonUpdateTrace, ClearButtonData } = actions;
export default reducer;