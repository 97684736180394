import instanceBaseurl1 from "../../config/BaseUrl1";

export async function NewsandArticlesServices() {
    try {
        const response = await instanceBaseurl1.get(`/lms/list`);
        return response?.data;
    } catch (err) {
        throw err;
    }
}

export async function SingleGetNewsandArticlesServices(id) {
    try {
        const response = await instanceBaseurl1.get(`/lms/${id}`);
        return response?.data;
    } catch (err) {
        throw err;
    }
}