import { createSlice } from "@reduxjs/toolkit";
const NeetExam_Reducer = createSlice({
    name: "NeetExams",
    initialState: {
        loading: false,
        NeetExamData: [],
        error: ""
    },
    reducers: {
        NeetExamRequest(state, action) {
            return {
                loading: true
            }
        },
        NeetExamSuccess(state, action) {
            return {
                loading: false,
                NeetExamData: action.payload,
            }
        },
        NeetExamFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },
        NeetExamClear(state, action) {
            return {
                NeetExamData: []
            }
        }
    }
})

const { actions, reducer } = NeetExam_Reducer;
export const { NeetExamRequest, NeetExamSuccess, NeetExamFail, NeetExamClear } = actions;
export default reducer;