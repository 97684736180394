import { createSlice } from "@reduxjs/toolkit";


const ByNow_Reducer = createSlice({
    name: "BuyNow",
    initialState: {
        loading: false,
        ByNowData: [],
    },
    reducers: {
        BYnowRequest(state, action) {
            return {
                loading: true
            }
        },
        BYnowSuccess(state, action) {
            return {
                loading: false,
                ByNowData: action.payload,

            }
        },
        BYnowFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = ByNow_Reducer;
export const { BYnowRequest, BYnowSuccess, BYnowFail } = actions;
export default reducer;