import instanceBaseurl from "../../config/BaseUrl";

export async function getAllNeetExams(data) {
    try {
        const response = await instanceBaseurl.get(`/${data}/ques`);
        return response?.data;
    } catch (err) {
        throw err;
    }
}


