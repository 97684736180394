import React, { useEffect, useState } from "react";
import './App.scss';
import RouterIndex from './router/RouterIndex';
import Loader from "./Loader/Loader";
import Modal from 'react-bootstrap/Modal';
import waringimg from './assests/images/warning-image.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadActions } from './redux/actions/loadActions/LoadActions';
function App() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state?.loadprops?.ReloadData);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1200);
  }, [])

  const token = sessionStorage.getItem("access-user-token");

  useEffect(() => {
    if (token) {
      if (window.location.pathname == "/") {
        window.location.assign("/home")
      }
    }
    else {
    }
  }, [])
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // useEffect(() => {
  //   const Alert = (e) => {
  //     e.preventDefault();
  //     return handleShow();
  //   };
  //   const disabledKeys = ["c", "C", "x", "J", "u", "I", "w", "P", "X"];
  //   document.addEventListener("contextmenu", Alert);
  //   document.addEventListener("keydown", function (e) {
  //     if (
  //       e.key === "Meta" ||
  //       e.key === "Windows" ||
  //       e.keyCode === 91 ||
  //       e.keyCode === 92
  //     ) {
  //       Alert(e);
  //     }
  //     if ((e.ctrlKey && disabledKeys.includes(e.key)) || e.key === "F12" || e.key === "F5") {
  //       Alert(e);
  //     }
  //     if (e.keyIdentifier === "PrtScr") {
  //       Alert(e);

  //     }
  //   });
  //   document.addEventListener("cut", (e) => {
  //     Alert(e);
  //   });
  //   document.addEventListener("copy", (e) => {
  //     Alert(e);
  //   });

  //   window.addEventListener("online", function () {
  //     if (token) {
  //       window.location.assign("/home")
  //     }
  //   })

  //   window.addEventListener("offline", function () {
  //     window.location.assign("/offline")
  //   })
  // }, [])



  // useEffect(() => {

  //   const Alert = (e) => {
  //     e.preventDefault();
  //     return handleShow();
  //   };
  //   const disabledKeys = ["c", "C", "x", "J", "u", "I", "w", "P", "X"];
  //   document.addEventListener("contextmenu", Alert);
  //   document.addEventListener("keydown", function (e) {
  //     if (
  //       e.key === "Meta" ||
  //       e.key === "Windows" ||
  //       e.keyCode === 91 ||
  //       e.keyCode === 92
  //     ) {
  //       Alert(e);
  //     }
  //     if ((e.ctrlKey && disabledKeys.includes(e.key)) || e.key === "F12" || e.key === "F5") {
  //       Alert(e);
  //     }
  //     if (e.keyIdentifier === "PrtScr") {
  //       Alert(e);

  //     }
  //   });
  //   document.addEventListener("cut", (e) => {
  //     Alert(e);
  //   });
  //   document.addEventListener("copy", (e) => {
  //     Alert(e);
  //   });

  //   window.addEventListener("online", function () {
  //     if (token) {
  //       window.location.assign("/home")
  //     }
  //   })

  //   window.addEventListener("offline", function () {
  //     window.location.assign("/offline")
  //   })
  // }, [])



  const responseData = () => {
    dispatch(ReloadActions(true))

  }

  useEffect(() => {

    window.addEventListener("online", function () {
      navigate("/home")
    })
    window.addEventListener("offline", function () {
      navigate("/offline")
    })

  }, [])



  return loading ? <>
    <div className="loader-sections">
      <Loader />
    </div>
  </> : <>
    <div className="App">
      <RouterIndex />
    </div>
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <div className='d-flex align-items-center justify-content-center cursor'>
          <img src={waringimg} alt="no image" className='warning-image-box' />
        </div>
        <div className='text-center mt-3 mb-4 fw-bold'>
          Warning
        </div>
        <div className='fw-bold fs-4 text-center mt-3 mb-4'>
          This feature is Restricted!
        </div>
        <div className='mt-5 mb-4 d-flex align-items-center justify-content-center'>
          <button className='ok-btn' onClick={handleClose}>
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}



export default App;
