import React from 'react'
import smartlogo from '../assests/images/short_log-cdp.png'
import './styles/Loader.scss';
import { useState, CSSProperties } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";

function Loader() {


    // const override: CSSProperties = {
    //     display: "block",
    //     margin: "0 auto",
    //     borderColor: "red",
    // };

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#E81402");
    return (
        <div className={"mainloader"}>
            {/* <div className={"insideloader"}>    
                <div className={"loadersection"}>
                    <img src={smartlogo} alt="no image" className={"womeynlogo"} />
                </div>
            </div> */}
            <PacmanLoader
                color={color}
                loading={loading}
                // cssOverride={override}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>

    )
}

export default Loader