import React, { useEffect, useState } from 'react'
import './Ranklist.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GetRankLocationList } from '../../../../redux/actions/RankLocationActions/RankLocation';
import Table from 'react-bootstrap/Table';
import { RankReportFilterActions } from '../../../../redux/actions/rankActions/RankActions';
import norecord from '../../../../assests/images/empty-rank-img.png';
import arrowright from "../../../../assests/images/left.png";
import arrowleft from "../../../../assests/images/right.png";
import ReactPaginate from 'react-paginate';
import { Puff } from 'react-loader-spinner'


function Ranklistcard({ data, category, dataCategorys, ClickClear, result, handlePageClick, current, loadingloader }) {
    const dispatch = useDispatch();
    const states = useSelector((state) => state?.rankLocation)
    const statesfilterlocation = useSelector((state) => state?.rankLocationfilter?.RankLocationFilterData)
    const filterLocationDataAll = statesfilterlocation && statesfilterlocation?.result?.data;
    const totalPageCount = result?.result;
    const { RankLocationData } = states;
    const [arrow, setArrow] = useState(false);
    const [checkboxlist, setCheckBoxList] = useState([]);
    const [searchtext, setSearchText] = useState("");
    const filters = searchtext;

    useEffect(() => {
        dispatch(GetRankLocationList());
    }, [data])

    const dataList = ["Bangalore", "Chennai", "Delhi/NCR", "Hyderabad", "Kolkata", "Mumbai", "Pune", "Agartala", "Agra", "Ahmedabad", "Ahmednaga", "Aizawl", "Akola", "Aligarh", "Allahabad", "Alwar", "Ambala", "Ambikapur", "Amravati ", "Amreli ", "Amritsar ", "Anand ", "Anantapur", "Anantnag ", "Andhra Pradesh - Other", "Arunachal Pradesh - Other", "Asansol", "Assam - Other", "Aurangabad",
        , "Azamgarh",
        "Bagalkot",
        "Bahadurgarh",
        "Baharampur",
        "Bahraich",
        "Balasore",
        "Ballari",
        "Banda",
        "Bankura",
        "Baramati",
        "Baramulla",
        "Bardhaman",
        "Bareilly",
        "Baripada",
        "Barmer",
        "Bathinda",
        "Belgaum",
        "Berhampur",
        "Bettiah",
        "Bhagalpur",
        "Bharatpur",
        "Bhavnagar",
        "Bhilwara",
        "Bhopal",
        "Bhubaneswar",
        "Bidar",
        "Bijapur",
        "Bikaner",
        "BilasPur",
        "Bundi",
        "Chamarajanagar",
        "Chamba (1)",
        "Chandigarh Tricity",
        "Chandrapur (1)",
        "Chhattisgarh - Other",
        "Chhindwara",
        "Chikballpura",
        "Chitradurga",
        "Chittoor",
        "Chittorgarh",
        "Churu",
        "Coimbatore",
        "Coochbehar",
        "Coorg",
        "Cuttack",
        "Darbhanga",
        "Darjeeling",
        "Dausa",
        "Davangere",
        "Dehradun",
        "Delhi - Other",
        "Dhanbad",
        "Dharmapuri",
        "Dharwad",
        "  Dholpur",
        "Dhule",
        "Dibrugarh",
        "Dindigul",
        "District 24 Parganas",
        "Doda"];

    const handleChangeCheckbox = (name) => {
        if (checkboxlist?.includes(name)) {
            setCheckBoxList(checkboxlist?.filter((item) => item !== name));
        }
        else {
            setCheckBoxList([...checkboxlist, name]);
        }
    }
    const ClearCheckBoxes = () => {
        setCheckBoxList([]);
        ClickClear();
    }

    useEffect(() => {
        if (checkboxlist && checkboxlist?.length > 0) {
            const filterData = {
                mark: dataCategorys?.search,
                category: dataCategorys?.category ? dataCategorys?.category : "GEN",
                state: dataCategorys?.states ? dataCategorys?.states : "",
                location: checkboxlist
            }
            dispatch(RankReportFilterActions(filterData))
        }
    }, [checkboxlist, filterLocationDataAll]);








    return (

        <div className='main-list-cards-rank'>

            <div className='left-card-list-rank'>
                <div className='filter-box'>
                    Filters
                </div>
                <div>
                    <div className='location-dropdown mb-4'>
                        <div>
                            Location
                        </div>
                        <div className='down-arrow-icon' onClick={() => setArrow(!arrow)}>
                            {arrow ? <>
                                <ion-icon name="chevron-up-outline"></ion-icon>
                            </> : <>
                                <ion-icon name="chevron-down-outline"></ion-icon>
                            </>}
                        </div>

                    </div>

                    {arrow ? <>
                        <div className='mt-3 mb-3'>
                            <input type="text" placeholder='Search location...' className='serach-location-input'
                                onChange={(e) => setSearchText(e?.target?.value)}
                                value={searchtext}
                            />
                        </div>
                    </> : null}
                    {arrow ? <>
                        <div className='d-flex justify-content-between align-content-center mb-4 mt-4'>
                            <div>
                                Selected
                            </div>
                            <div className='text-danger cursor' onClick={ClearCheckBoxes}>
                                Clear (<span>{checkboxlist?.length}</span>)
                            </div>
                        </div>
                    </> : <></>}

                    {checkboxlist?.length > 0 ? <>
                        <div className='box-selected-list mb-3 mt-2 row'>
                            {checkboxlist?.map((item, index) => {
                                return (
                                    <div key={index} className='box-list-location' onClick={() => handleChangeCheckbox(item)}>
                                        <div>
                                            {item}
                                        </div>
                                        <div className='close-location-box'>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className='icon-close'
                                                viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </> : null}

                    {arrow ? <div className='location-list-box-height'>

                        {RankLocationData?.filter((item) => {
                            if (filters === "") {
                                return item;
                            }
                            else {
                                return item?.toLowerCase()?.includes(filters.toLowerCase());
                            }
                        }).map((item, index) => {
                            return (
                                <div className='d-flex gap-3 mb-2 mt-2' onClick={() => handleChangeCheckbox(item)} key={index}>
                                    <div>
                                        {checkboxlist?.includes(item) ? <>
                                            <input type="checkbox" value={item} checked={true} />
                                        </> : <>
                                            <input type="checkbox" value={item} checked={checkboxlist?.includes(item)} />
                                        </>}
                                    </div>
                                    <div>
                                        <label className='cursor'>{item}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null}

                </div>
            </div>
            <div className='right-card-list-rank'>
                {loadingloader ? <div className="loader-pagination-rank">
                    {/* <Puff
                        visible={true}
                        height="80"
                        width="80"
                        color="#e14401"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /> */}
                    kalai
                </div> : null}
                {data && data?.map((item, index) => {
                    const rankName = item?.categories?.find((item => item?.category === category))
                    return (
                        <div key={index} className='card-rank-colleges mt-3 mb-3'>
                            <div className='neet-type-box'>
                                {item && item?.courses[0]}

                            </div>
                            <div>
                                <div>
                                    <div className="clg-text">
                                        {item?.collegeName}
                                    </div>
                                    <div className='d-flex align-items-center  mt-2'>
                                        <span className='small-text '><ion-icon name="location-outline" size="small"></ion-icon></span> <span className='small-text'>{item?.location}</span> | <span className='small-text'>Placement</span> | <span className='small-text'>CutOff</span>
                                    </div>

                                </div>
                                <div className='college-tab'>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                    </div>

                                </div>
                            </div>
                            <div className='mt-3'>
                                <Table bordered hover>
                                    <thead  >
                                        <tr >
                                            <th style={{ background: "#eff5ff" }}>Round</th>
                                            <th style={{ background: "#eff5ff" }}>Start Rank</th>
                                            <th style={{ background: "#eff5ff" }}>End Rank</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        <tr className='cursor'>
                                            <td>1</td>
                                            <td>{rankName?.startRank}</td>
                                            <td>{rankName?.endRank}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )
                })}
                {/* </>} */}

                <div className="mt-0" style={{ marginRight: "35px" }}>

                    {data?.length > 0 ? <>
                        <div className="mt-3">
                            <hr />
                        </div>
                        <div>
                            {/* Page {totalPageCount} */}
                        </div>
                        <div className="mt-3">

                            <ReactPaginate
                                activeClassName={'actives-label'}
                                breakClassName={'break-me '}
                                breakLabel={'...'}
                                containerClassName={'pagination'}
                                disabledClassName={'disabled-page'}
                                // marginPagesDisplayed={2}
                                nextClassName={"next"}
                                nextLabel={Math.ceil(totalPageCount?.totalItems / 10) === current ? null : "Next"}
                                onPageChange={handlePageClick}
                                pageCount={totalPageCount?.totalItems / 10}
                                pageClassName={'pagination-page '}
                                // pageRangeDisplayed={2}
                                previousClassName={"previous"}
                                previousLabel={current > 1 ? "Previous" : null}
                            />
                        </div>
                    </> : null}


                </div>

                {data?.length === 0 ? <div className="no-result-image-rank">
                    <img src={norecord} alt="no image"
                        className='norecard-image'
                    />
                </div> : <></>}

            </div>


        </div>



    )
}

export default Ranklistcard
