import { NewsandArticlesServices, SingleGetNewsandArticlesServices } from "../../../services/newsandarticles/Newsandarticles"
import { NewsFail, NewsRequest, NewsSuccess, NewsSingleDataSuccess } from "../../reducers/NewsArticles_reducer";


export const GetNewsandArticlesData = () => async (dispatch) => {
    dispatch(NewsRequest())
    try {
        const response = await NewsandArticlesServices();
        if (response) {
            dispatch(NewsSuccess(response?.postsList))
        }
    } catch (error) {
        dispatch(NewsFail(error))
    }
}


export const GetSingleNewsandArticlesData = (dataid) => async (dispatch) => {
    dispatch(NewsRequest())

    try {
        const response = await SingleGetNewsandArticlesServices(dataid);
        if (response) {
            dispatch(NewsSingleDataSuccess(response))
        }
    } catch (error) {
        dispatch(NewsFail(error))
    }
}