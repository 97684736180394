import instanceBaseurl from "../../config/BaseUrl";

export async function getReportData(data) {
    const name = sessionStorage.getItem("testname");
    try {
        const response = await instanceBaseurl.post(`/neet/user/report`, data);
        return response.data;
    } catch (err) {
        throw err;
    }
}