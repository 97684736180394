
const getYear = new Date();
let CurrentYear = getYear.getFullYear();

export const HomeContent = {
    GeneralInstructions: "General Instructions",
    instructions: [
        {
            id: 1,
            name: `Total duration of NEET - NEET 2024 is 180 min`,
            instructionList: [],

        }
        ,
        {
            id: 2,
            name: ' The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will  be allowed to submit your examination if you complete it before time.',
            instructionList: [],
        },
        {
            id: 3,
            name: '  The Questions Palette displayed on the right side of screen will show the status of each question using one of the following colors',
            instructionList: [
                {
                    id: 1,
                    name: "You have not visited the question yet."
                },
                {
                    id: 2,
                    name: "You have not answered the question.",

                },
                {
                    id: 3,
                    name: "You have answered the question."
                },
                {
                    id: 4,
                    name: "You have NOT answered the question, but have marked the question for review."
                },
                {
                    id: 5,
                    name: `The question(s) "Answered and Marked for Review" will be considered for evaluation.`
                }
            ]
        },
        {
            id: 4,
            name: ' You can click on the next button to go to the next question.',
            instructionList: [],

        },
        {
            id: 5,
            name: "You can click on back button to go back to the previous question.",
            instructionList: [],

        }],
    checkboxload: "I agree to the terms that opening another browser during the test will close all the windows and the test cannot be continued further. ",
    checkboxtext: "I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations.",
    starttext: 'Start Test',
    back: "Back",
    next: "Skip",
    submit: "Submit",
    saveandnext: 'Save & Next',
    saveandmarkforreview: 'Save & Mark for Review',
    markforreviewnext: 'Mark for Review & Next',
    clear: "Clear",
    NavigatingtoaQuestion: "Navigating to a Question:",
    Toansweraquestiondothefollowing: "To answer a question, do the following: ",
    listanswers: [
        {
            id: 1,
            name: "Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question."
        },
        {
            id: 2,
            name: "Click on Save & Next to save your answer for the current question and then go to the next question."
        },
        {
            id: 3,
            name: "Click on Mark for Review & Next to save your answer for the current question, mark it for review, and then go to the next question."
        }
    ],
    AnsweringaQuestion: "Answering a Question:",
    Procedureforansweringamultiplechoicetypequestion: "Procedure for answering a multiple choice type question: ",
    answersquestionlists: [
        {
            id: 1,
            name: "To select you answer, click on the button of one of the options."
        },
        {
            id: 2,
            name: "To deselect your chosen answer, click on the button of the chosen option again or click on the Clear Response button"
        },
        {
            id: 3,
            name: "To change your chosen answer, click on the button of another option"
        },
        {
            id: 4,
            name: "To save your answer, you must click on the Save & Next button."
        },
        {
            id: 5,
            name: "To mark the question for review, click on the Mark for Review & Next button."
        }
    ],
    toChange: "To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.",
    Navigatingthroughsections: 'Navigating through sections:',
    section10: "Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by click on the section name. The section you are currently viewing is highlighted.",
    section11: " On clicking Save & Next button on the question, you will automatically be taken to the next question.",
    section12: "You can shuffle between sections and questions anything during the examination as per your convenience only during the time stipulated.",
    section13: "Candidate can view the corresponding section summery as part of the legend that appears in every section above the question palette.",
    // pleasenoton: 'Please note all questions will appear in your default language.'
    pleasenoton: 'Please opt for a tablet or laptop for a improved user experience. If you are using a mobile device, make sure to use it in landscape mode.'

}
