import { createSlice } from "@reduxjs/toolkit";
const ExtraCourse_Reducer = createSlice({
    name: "Extra Course Suggestion",
    initialState: {
        loading: false,
        ExtraCourses: []
    },
    reducers: {
        ExtraCoursesRequest(state, action) {
            return {
                loading: true
            }
        },
        ExtraCoursesSuccess(state, action) {
            return {
                loading: false,
                ExtraCoursesCourses: action.payload
            }
        },
        ExtraCoursesFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = ExtraCourse_Reducer;
export const { ExtraCoursesRequest, ExtraCoursesSuccess, ExtraCoursesFail } = actions;
export default reducer;