import React from 'react'
import './Pdfstyle.scss';
function Overallreport({ data }) {
    const datas = [
        {
            id: 1,
            name: "Biology",
            arraydata: data?.Biology
        },
        {
            id: 2,
            name: "Physics",
            arraydata: data?.Physics
        },
        {
            id: 3,
            name: "Chemistry",
            arraydata: data?.Chemistry
        }
    ]

    console.log(datas, 'datas')
    return (
        <div>
            {datas?.map((item, index) => {
                return (
                    <div key={index}>
                        <div className='ms-3'>
                            <div className='common-title-section mt-5 mb-2'>
                                Overall Topic : <span className='fw-bold mb-3 mt-2'>{item?.name}</span>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className='table-section'>
                                <table class="table table-striped table-bordered">
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th className='th-bg'>Topic</th>
                                            <th className='th-bg'>No.of.
                                                Questions</th>
                                            <th className='th-bg'> No.of.Questions
                                                Attempted
                                            </th>
                                            <th className='th-bg'>No.of.Correct
                                                Responses</th>
                                            <th className='th-bg'>No.of.Incorrect
                                                Responses </th>
                                            <th className='th-bg'>
                                                Marks
                                                Scored
                                            </th>
                                            <th className='th-bg'>
                                                Strenght %
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item?.arraydata?.map((items) => {
                                            return (
                                                <>
                                                    {items?.subTopics?.map((itemss, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{itemss?.name}</td>
                                                                <td>{itemss?.no_of_ques}</td>
                                                                <td>{itemss?.attemptQues}</td>
                                                                <td>{itemss?.crtQues}</td>
                                                                <td>{itemss?.inCrtQues}</td>
                                                                <td>{itemss?.score}</td>
                                                                <td>{itemss?.strength}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>)
                                        })}

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Overallreport