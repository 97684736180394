import { createSlice } from "@reduxjs/toolkit";
const Question_Reducer = createSlice({
    name: "questions",
    initialState: {
        loading: false,
        QuestionData: [],
    },
    reducers: {
        QuestionRequest(state, action) {
            return {
                loading: true
            }
        },
        QuestionSuccess(state, action) {
            return {
                loading: false,
                QuestionData: action.payload
            }
        },
        QuestionFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },
        ClearAllstates(state, action) {
            return {
                ...state,
                QuestionData: []
            }
        }


    }
})

const { actions, reducer } = Question_Reducer;
export const { QuestionRequest, QuestionSuccess, QuestionFail, ClearAllstates } = actions;
export default reducer;