
import { GetUserProfile } from "../../../services/user/userSevice";
import { ProfileFail, ProfileRequest, ProfileSuccess } from "../../reducers/Profile_reducer";
export const GetProfileAction = () => async (dispatch) => {
    dispatch(ProfileRequest());
    try {

        const response = await GetUserProfile();
        if(response)
        {
            dispatch(ProfileSuccess(response?.data));

        }
    }
    catch (error) {

        dispatch(ProfileFail(error))
    }
}


