import axios from "axios";
// axios.interceptors.request.use(
//     function (config) {
//         config.url = process.env.REACT_APP_BASEURL_CDP_NEW + config.url;
//         config.withCredentials = false;
//         if (true) {
//             config.headers = {
//                 ...config.headers,
//                 "Content-Type": "application/json",
//             };
//         }
//         return config;
//     },
//     function (error) {
//         return Promise.reject(error);
//     }
// );

// axios.interceptors.response.use(
//     function (response) {
//         return response;
//     },
//     async function (error) {
//         return Promise.reject(error);
//     }
// );

// const instanceBaseurl1 = axios;





const instanceBaseurl1 = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_CDP_NEW,
    // mode: 'no-cors',
    // headers: {
    //     'Content-Type': 'application/json',
    // }
});


export default instanceBaseurl1;
