import { createSlice } from "@reduxjs/toolkit";
const Chapter_Reducer = createSlice({
    name: "Chapter",
    initialState: {
        loading: false,
        ChapterData: [],
    },
    reducers: {
        ChapterRequest(state, action) {
            return {
                loading: true
            }
        },
        ChapterSuccess(state, action) {
            return {
                loading: false,
                ChapterData: action.payload
            }
        },
        ChapterFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Chapter_Reducer;
export const { ChapterRequest, ChapterSuccess, ChapterFail } = actions;
export default reducer;