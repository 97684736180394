import React, { useState, useEffect, memo } from 'react'
import './styles/Quiz.scss';
import Options from '../options/Options';
import Questions from '../questions/Questions';
import { HomeContent } from '../../../../middlewares/HomeContent';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonDecrement, ButtonIncrement } from '../../../../redux/reducers/Button_reducer';
import {
    ClearOptionData, FirstOptionUpdate, OptionResultUpdate, SaveandUpdateMarkReviewData, SaveandUpdateOptionData, VisitedQuestionCountAction, pushResultAction, readQuestionOptions, readQuestionforMarkforReviewOptions,
    updateResultAction
} from '../../../../redux/reducers/Result_reducer';
import { CurrentOptionSelect, GetQuestionsSingle } from '../../../../redux/actions/Questions';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { SubmitAnswerAction } from '../../../../redux/actions/SubmitAnswerAction';
import Spinner from 'react-bootstrap/Spinner';
import Timer from '../timer/Timer';
import warningimage from '../../../../assests/images/warning-image.png';
import wrongimage from '../../../../assests/images/wrong-image.png';
import { ToastError, ToastSuccess } from '../../../../toastmessages/Toastmessages';
import AnswerModal from '../Answermodels/AnswerModal';
const Quiz = memo(({ questionss, MockquestionId, type, selectName, FirstQuestionId }) => {
    const states = useSelector((state) => state);
    const { trace } = states?.count;
    const { QuestionData } = states?.questions;
    const { result, resultOptions } = states?.result;

    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(10);
    const [timerCountalert, setTimeCountAlert] = useState(0);
    const [finaltimecheck, setFinalCheckTime] = useState(0);
    // spend time

    // final time calculate spend time

    const [spendTimeFinalCal, setSpendTimeFinalCal] = useState(0);
    const [spendTimeFinalCal1, setSpendTimeFinalCal1] = useState(0);


    useEffect(() => {
        setSpendTimeFinalCal1(3 * 60 * 60 * 1000);
    }, [])



    // submit button true false check
    const [submittrue, setSubmittrue] = useState(0);
    const [spendTime, setSpendTime] = useState(0);
    const [timercount, setTimerCount] = useState(0);

    // loading Actions
    const [loading, setLoading] = useState(false);
    // submit Datas
    const [biologydata, setBiologyData] = useState([])
    const [biologydataspendtime, setBiologyDataSpendtime] = useState([])
    const [physicsdata, setPhysicsData] = useState([])
    const [physicsdataspendtime, setPhysicsDataSpendtime] = useState([])
    const [chemistrydata, setChemistryData] = useState([])
    const [chemistrydataspendtime, setChemistryDataSpendtime] = useState([])
    // answerd questions
    const [answeredquestions, setAnswerdQuestions] = useState([]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const [visitedCountBrowser, setVisitedBrowswerCount] = useState(0);

    const reduceVistedBrowswerCount = 2;

    const [markforreviewData, setMarkForReviewData] = useState([])
    const [readQuestions, setReadQuestions] = useState([]);
    const [readQuestionsmerage, setReadQuestionsMerage] = useState(["1e"]);

    // const FinalReadQuestionTotal = readQuestions.concat(readQuestionsmerage);

    const FinalReadQuestionTotal = readQuestions;


    // answered Data in Modals overall


    const [answeredcount, setAnsweredCount] = useState([]);
    const [answeredcountresult, setAnsweredCountresult] = useState([]);


    const [notansweredcount, setNotAnsweredCount] = useState([]);
    const [notansweredcountresult, setNotAnsweredCountresult] = useState([]);


    const [notvisitedcount, setNotVisitedCount] = useState([]);


    const [markedforreviewcount, setMatkedForReviewCount] = useState([]);
    const [markedforreviewresult, setMarkedForReviewresult] = useState([]);

    const [savemarkedforreviewcount, setSaveMatkedForReviewCount] = useState([]);
    const [savemarkedforreviewresult, setSaveMarkedForReviewresult] = useState([]);


    // save and Next Modal Popup
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
        setShow1(true)
    };

    // Answered Modal popup
    const handleClose1 = () => {
        setShow2(false)
    };
    const handleShow2 = () => {
        setShow2(true);
        const SingleQuestionId = QuestionOptiondata[trace]?.Queid;
        dispatch(GetQuestionsSingle(SingleQuestionId));
    }

    // Save Mark for Review Modal Popup

    const handleClose3 = () => {
        setShow3(false)
    };
    const handleShow3 = () => {
        setShow3(true);
    }

    const handleClose4 = () => {
        // if (visitedCountBrowser === 2) {
        //     handleShow2();
        // }
        setShow4(false)
    };
    const handleShow4 = () => {
        setShow4(true);
    }
    const [checkdata, setCheckData] = useState("");
    const [checkdatasave, setCheckDataSave] = useState("");
    const [marksavedata, setMarkSaveData] = useState([]);
    const [notanswerquestions, setNotAnswerQuestions] = useState([]);


    const { QuestionOptiondata } = states?.questionsoption;
    const { CurrentOptionvalue } = states?.optioncurrent;
    const dispatch = useDispatch();
    const [resuleDatasave, setResaltDatasave] = useState([]);

    // Option Select in Question Action
    const OnCheck = async (checkid) => {
        setCheckData(checkid);
    }

    // Current Question Action

    const callBackQuestion = async () => {
        const SingleQuestionId = QuestionOptiondata[trace + 1]?.Queid;
        dispatch(CurrentOptionSelect(SingleQuestionId));
    }




    // increment Action

    const CallBackFunctionUpdateOptions = async () => {
        if (result && result[0]?.savequestionId) {
        }
        else {
            if (resuleDatasave?.includes("1e")) {
            }
            else {
                await dispatch(FirstOptionUpdate(FirstQuestionId));
            }
        }


    }
    useEffect(() => {
    }, [trace])
    const inCreament = async () => {
        const responseData = QuestionData?.data[0].answeroptions;
        const CorrectAnswer = responseData?.findIndex(x => x.answer === true);
        callBackQuestion();
        setSpendTime(0);
        if (checkdata?.name) {
            if (trace < QuestionOptiondata?.length) {
                dispatch(ButtonIncrement())
                setCheckData("")
                callBackQuestion();
                const reads = {
                    id: trace + 1,
                }
                dispatch(readQuestionOptions(reads))
                const originalId = QuestionOptiondata[trace + 1]?.Queid;
                dispatch(GetQuestionsSingle(originalId));
                if (result?.length <= trace) {
                    dispatch(pushResultAction(checkdata));
                    setCheckData("");
                    setCheckDataSave("");

                }
            }
        }
        else {
            dispatch(ButtonIncrement())
            const originalId = QuestionOptiondata[trace + 1]?.Queid;
            dispatch(GetQuestionsSingle(originalId));
            const updateId = QuestionOptiondata[trace].Queid;
            const resultdata = result.find((item) => item?.oriquestionId === updateId);
            const datas = {
                id: resultdata?.id,
                name: resultdata?.name,
                answer: resultdata?.answer,
                questionId: trace + 1,
                savequestionId: resultdata?.savequestionId ? resultdata?.savequestionId : `${trace + 1}e`,
                oriquestionId: QuestionData?.data[0]?.assessmentQuestionId,
                chapterName: QuestionData?.data[0]?.chapters?.chapterName,
                subject: QuestionOptiondata[trace]?.name,
                your_responce: resultdata?.your_responce,
                correct_responses: resultdata?.correct_responses ? resultdata?.correct_responses : CorrectAnswer === 0 ? "A" : null || CorrectAnswer === 1 ? "B" : null || CorrectAnswer === 2 ? "C" : null || CorrectAnswer === 3 ? "D" : null,
                score: resultdata?.score,
                markedforreviewId: "",
                answeredandmarkedforreviewId: "",
                subTopic: resultdata?.subTopic,
                difficulty_level: resultdata?.difficulty_level,
                visitedquestions: resultdata?.visitedquestions ? resultdata?.visitedquestions + 1 : 1,
                spendtime: checkdata?.spendtime ? Number(checkdata?.spendtime + spendTime) : spendTime,
                correct_to_incorrect: checkdata?.correct_to_incorrect ? checkdata?.correct_to_incorrect : 0,
                incorrect_to_correct: checkdata?.incorrect_to_correct ? checkdata?.incorrect_to_correct : 0,
                incorrect_to_incorrect: checkdata?.incorrect_to_incorrect ? checkdata?.incorrect_to_incorrect : 0,
                correct_to_unanswered: checkdata?.correct_to_unanswered ? checkdata?.correct_to_unanswered : 0,
                incorrect_to_unanswered: checkdata?.incorrect_to_unanswered ? checkdata?.incorrect_to_unanswered : 0,
                correct_to_correct: checkdata?.correct_to_correct ? checkdata?.correct_to_correct : 0

            }
            dispatch(OptionResultUpdate(datas))
            dispatch(VisitedQuestionCountAction(datas));
            CallBackFunctionUpdateOptions(trace);
            dispatch(OptionResultUpdate(datas));


        }
    }

    // decrement Action
    const deCreament = () => {
        dispatch(ButtonDecrement())
        const SingleQuestionId = QuestionOptiondata[trace - 1]?.Queid;
        dispatch(GetQuestionsSingle(SingleQuestionId));
        setCheckDataSave("");
        setCheckData("");
        setCheckData("");
        setShow1(false);
    }

    // submit modal confirmation
    const SubmitAnswers = async () => {
        handleShow2();
    }

    // final Answer Submited

    const AnswersSubmited = () => {
        handleClose1();
        SubmitAllDatas();
        navigate("/result", {
            state: { time: timercount, id: MockquestionId, selectName: selectName, finaltimecheck: finaltimecheck, spendTimeFinalCal: spendTimeFinalCal, spendTimeFinalCal1: spendTimeFinalCal1 }
        });
    }

    useEffect(() => {
        let checkList = [];
        let checkList1 = [];
        let checkList2 = [];

        result.map((item, index) => {
            if (item?.savequestionId === `${index + 1}s`) {
                checkList.push(item?.savequestionId)
            }
        })
        result?.map((item) => {
            if (item?.savequestionId === `${item?.questionId}e`) {
                checkList1.push(item?.savequestionId)
            }
        })

        result?.map((item) => {
            if (item?.savequestionId === `${item?.questionId}am`) {
                checkList2.push(item?.savequestionId)

            }
        })

        // setSavemarkAnswered
        setMarkSaveData(checkList2);
        setAnswerdQuestions(checkList)
    }, [result, resuleDatasave]);


    const ListOptionAnswers = [
        {
            id: 1,
            name: 'No of Questions',
            result: ""
        },
        { id: 2, name: 'Answered', result: "" },
        { id: 3, name: 'Not Answered', result: "" },
        { id: 4, name: 'Marked for Review', result: "" },
        { id: 5, name: 'Save & Marked for Review', result: "" }
    ]

    // save and  Next Action in skip;

    const [oldData, setOldDatas] = useState(null);
    useEffect(() => {
        const updateId = QuestionOptiondata[trace].Queid;
        if (updateId) {
            const resultdata = result.find((item) => item?.oriquestionId === updateId);
            setOldDatas(resultdata)
        }
    }, [trace])

    const saveandNextButton = async () => {
        if (checkdata?.length === 0) {
            handleShow();
        }
        else {
            if (checkdata?.name) {
                const reads = {
                    id: trace + 1
                }
                dispatch(readQuestionOptions(reads))
                const originalId = QuestionOptiondata[trace + 1]?.Queid;
                dispatch(GetQuestionsSingle(originalId));
                const updateId = QuestionOptiondata[trace].Queid;
                const resultdata = result.find((item) => item?.oriquestionId === updateId);
                const correctresponse = (resultdata?.your_responce) === (resultdata?.correct_responses);
                const overs = {
                    id: checkdata?.id,
                    name: checkdata?.name,
                    answer: checkdata?.answer,
                    questionId: trace + 1,
                    savequestionId: `${trace + 1}s`,
                    oriquestionId: QuestionData?.data[0]?.assessmentQuestionId,
                    chapterName: QuestionData?.data[0]?.chapters?.chapterName,
                    subject: QuestionOptiondata[trace]?.name,
                    your_responce: checkdata?.your_responce,
                    correct_responses: checkdata?.correct_responses,
                    score: checkdata?.score,
                    markedforreviewId: "",
                    answeredandmarkedforreviewId: "",
                    subTopic: checkdata?.subTopic,
                    difficulty_level: checkdata?.difficulty_level,
                    visitedquestions: checkdata?.visitedquestions ? checkdata?.visitedquestions + 1 : 1,
                    spendtime: checkdata?.spendtime ? Number(checkdata?.spendtime + spendTime) : spendTime,
                    correct_to_incorrect: resultdata?.correct_to_incorrect ? resultdata?.correct_to_incorrect : 0,
                    incorrect_to_correct: resultdata?.incorrect_to_correct ? resultdata?.incorrect_to_correct : 0,
                    incorrect_to_incorrect: resultdata?.incorrect_to_incorrect ? resultdata?.incorrect_to_incorrect : 0,
                    correct_to_unanswered: resultdata?.correct_to_unanswered ? resultdata?.correct_to_unanswered : 0,
                    incorrect_to_unanswered: resultdata?.incorrect_to_unanswered ? resultdata?.incorrect_to_unanswered : 0,
                    correct_to_correct: resultdata?.correct_to_correct ? resultdata?.correct_to_correct : 0
                }

                if (overs?.visitedquestions > 1) {
                    if (overs?.correct_to_correct === 1) {
                        if (checkdata?.correct_responses === checkdata?.your_responce) {
                            overs.correct_to_correct = 1;
                            overs.incorrect_to_correct = 0;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                            overs.correct_to_incorrect = 0;
                        }
                    }
                    else {
                        if (checkdata?.correct_responses === checkdata?.your_responce) {
                            overs.correct_to_correct = 0;
                            overs.incorrect_to_correct = 1;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                            overs.correct_to_incorrect = 0;

                        }
                        else {
                            overs.correct_to_incorrect = 1;
                            overs.correct_to_correct = 0;
                            overs.incorrect_to_correct = 0;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                        }
                    }
                }
                else {
                    if (checkdata?.correct_responses == checkdata?.your_responce) {
                        overs.correct_to_correct = 1;
                        overs.incorrect_to_correct = 0;
                        overs.incorrect_to_incorrect = 0;
                        overs.correct_to_unanswered = 0;
                        overs.incorrect_to_unanswered = 0;
                        overs.correct_to_incorrect = 0;
                    }
                    else {
                        overs.incorrect_to_incorrect = 1;
                        overs.correct_to_incorrect = 1;
                        overs.correct_to_correct = 0;
                        overs.incorrect_to_correct = 0;
                        overs.correct_to_unanswered = 0;
                        overs.incorrect_to_unanswered = 0;
                    }
                }



                dispatch(SaveandUpdateOptionData(overs));
                {
                    trace + 1 === QuestionOptiondata?.length ? handleShow2() :
                        dispatch(ButtonIncrement())
                }
                setShow1(false);
                setCheckData("");
                setSpendTime(0);
            }
        }


    }




    // saveandmark forreview Action


    const saveandandMarkReviewButton = async () => {

        const updateId = QuestionOptiondata[trace].Queid;
        const resultdata = result.find((item) => item?.oriquestionId === updateId);
        const correctresponse = (resultdata?.your_responce) === (resultdata?.correct_responses);

        if (checkdata?.length === 0) {
            handleShow3();

        }
        else {
            if (checkdata?.name) {
                callBackQuestion()
                const reads = {
                    id: trace + 1
                }
                dispatch(readQuestionOptions(reads))
                const originalId = QuestionOptiondata[trace + 1]?.Queid;
                dispatch(GetQuestionsSingle(originalId));
                const overs = {
                    id: checkdata?.id,
                    name: checkdata?.name,
                    answer: checkdata?.answer,
                    questionId: trace + 1,
                    savequestionId: `${trace + 1}am`,
                    oriquestionId: QuestionData?.data[0]?.assessmentQuestionId,
                    chapterName: QuestionData?.data[0]?.chapters?.chapterName,
                    subject: QuestionOptiondata[trace]?.name,
                    your_responce: checkdata?.your_responce,
                    correct_responses: checkdata?.correct_responses,
                    score: checkdata?.score,
                    markedforreviewId: "",
                    answeredandmarkedforreviewId: "",
                    subTopic: checkdata?.subTopic,
                    difficulty_level: checkdata?.difficulty_level,
                    visitedquestions: checkdata?.visitedquestions ? checkdata?.visitedquestions + 1 : 1,
                    spendtime: checkdata?.spendtime ? Number(checkdata?.spendtime + spendTime) : spendTime,
                    correct_to_incorrect: checkdata?.correct_to_incorrect ? checkdata?.correct_to_incorrect : 0,
                    incorrect_to_correct: checkdata?.incorrect_to_correct ? checkdata?.incorrect_to_correct : 0,
                    incorrect_to_incorrect: checkdata?.incorrect_to_incorrect ? checkdata?.incorrect_to_incorrect : 0,
                    correct_to_unanswered: checkdata?.correct_to_unanswered ? checkdata?.correct_to_unanswered : 0,
                    incorrect_to_unanswered: checkdata?.incorrect_to_unanswered ? checkdata?.incorrect_to_unanswered : 0,
                    correct_to_correct: checkdata?.correct_to_correct ? checkdata?.correct_to_correct : 0

                }


                if (overs?.visitedquestions > 1) {
                    if (overs?.correct_to_correct === 1) {
                        if (checkdata?.correct_responses === checkdata?.your_responce) {
                            overs.correct_to_correct = 1;
                            overs.incorrect_to_correct = 0;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                            overs.correct_to_incorrect = 0;
                        }
                    }
                    else {
                        if (checkdata?.correct_responses === checkdata?.your_responce) {
                            overs.correct_to_correct = 0;
                            overs.incorrect_to_correct = 1;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                            overs.correct_to_incorrect = 0;

                        }
                        else {
                            overs.correct_to_incorrect = 1;
                            overs.correct_to_correct = 0;
                            overs.incorrect_to_correct = 0;
                            overs.incorrect_to_incorrect = 0;
                            overs.correct_to_unanswered = 0;
                            overs.incorrect_to_unanswered = 0;
                        }
                    }
                }
                else {
                    if (checkdata?.correct_responses == checkdata?.your_responce) {
                        overs.correct_to_correct = 1;
                        overs.incorrect_to_correct = 0;
                        overs.incorrect_to_incorrect = 0;
                        overs.correct_to_unanswered = 0;
                        overs.incorrect_to_unanswered = 0;
                        overs.correct_to_incorrect = 0;
                    }
                    else {
                        overs.incorrect_to_incorrect = 1;
                        overs.correct_to_incorrect = 1;
                        overs.correct_to_correct = 0;
                        overs.incorrect_to_correct = 0;
                        overs.correct_to_unanswered = 0;
                        overs.incorrect_to_unanswered = 0;
                    }
                }
                dispatch(SaveandUpdateMarkReviewData(overs));
                {
                    trace + 1 === QuestionOptiondata?.length ? handleShow2() :
                        dispatch(ButtonIncrement())
                }
                setShow1(false);
                setCheckData("");
                setSpendTime(0);
            }
        }


    }




    const MarkedforReviewButton = async () => {
        const responseData = QuestionData?.data[0].answeroptions;
        const CorrectAnswer = responseData.findIndex(x => x.answer === true);
        const originalId = QuestionOptiondata[trace + 1]?.Queid;
        dispatch(GetQuestionsSingle(originalId));
        const updateId = QuestionOptiondata[trace + 1 ? trace : trace - 1]?.Queid;
        const resultdata = result.find((item) => item?.oriquestionId === updateId);
        const correctresponse = (resultdata?.your_responce) === (resultdata?.correct_responses);
        const datas = {
            id: resultdata?.id,
            name: resultdata?.name,
            answer: resultdata?.answer,
            questionId: trace + 1,
            savequestionId: `${trace + 1}em`,
            oriquestionId: updateId,
            chapterName: QuestionData?.data[0]?.chapters?.chapterName,
            subject: QuestionOptiondata[trace]?.name,
            your_responce: resultdata?.your_responce,
            correct_responses: resultdata?.correct_responses ? resultdata?.correct_responses : CorrectAnswer === 0 ? "A" : null || CorrectAnswer === 1 ? "B" : null || CorrectAnswer === 2 ? "C" : null || CorrectAnswer === 3 ? "D" : null,
            score: resultdata?.score,
            markedforreviewId: "",
            answeredandmarkedforreviewId: "",
            subTopic: resultdata?.subTopic,
            difficulty_level: resultdata?.difficulty_level,
            visitedquestions: resultdata?.visitedquestions ? resultdata?.visitedquestions + 1 : 0,
            spendtime: checkdata?.spendtime ? Number(checkdata?.spendtime + spendTime) : spendTime,
            correct_to_incorrect: resultdata?.correct_to_incorrect ? resultdata?.correct_to_incorrect : 0,
            incorrect_to_correct: resultdata?.incorrect_to_correct ? resultdata?.incorrect_to_correct : 0,
            incorrect_to_incorrect: resultdata?.incorrect_to_incorrect ? resultdata?.incorrect_to_incorrect : 0,
            correct_to_unanswered: resultdata?.correct_to_unanswered ? resultdata?.correct_to_unanswered : 0,
            incorrect_to_unanswered: resultdata?.incorrect_to_unanswered ? resultdata?.incorrect_to_unanswered : 0,
            correct_to_correct: resultdata?.correct_to_correct ? resultdata?.correct_to_correct : 0

        }

        if (datas?.visitedquestions > 1) {
        }
        else {
            if (checkdata?.correct_responses == checkdata?.your_responce) {
                datas.correct_to_unanswered = 1
            }
            else {
                datas.incorrect_to_unanswered = 1
            }
        }



        dispatch(OptionResultUpdate(datas))
        {
            trace + 1 === QuestionOptiondata?.length ? handleShow2() :
                dispatch(ButtonIncrement())
        }

        setSpendTime(0);



    }


    const ClearOptions = async () => {
        if (checkdata) {
            const overs = {
                id: trace + 1,
                name: "",
                answer: "",
                questionId: "",
                savequestionId: "",
                oriquestionId: QuestionData?.data[0]?.assessmentQuestionId,
                chapterName: "",
                subject: "",
                your_responce: "",
                correct_responses: "",
                score: "0",
                markedforreviewId: "",
                answeredandmarkedforreviewId: "",
                subTopic: "",
                difficulty_level: "",
                correct_to_incorrect: "",
                incorrect_to_correct: "",
                incorrect_to_incorrect: "",
                correct_to_unanswered: "",
                incorrect_to_unanswered: ""
            }
            dispatch(ClearOptionData(overs))
            setCheckData("");
        }
    }

    // modal popup show

    const ShowHandleSubmitPopup = () => {
        if (trace + 1 === QuestionOptiondata?.length + 1) {
            handleShow2()
        }
    }

    useEffect(() => {
        if (trace + 1 === QuestionOptiondata?.length + 1) {
            handleShow2()
        }
    }, [trace])


    useEffect(() => {
        const biodata = [];
        const phydata = [];
        const chedata = [];

        // spen time
        const biodatatime = [];
        const phydatatime = [];
        const chedatatime = [];

        const resultDatasave = [];
        const resultDatasaveandmark = [];
        result.map((item) => {
            if (item?.savequestionId == `${item?.questionId}s`) {
                resultDatasave.push(item);
            }
        })
        result.map((item) => {
            if (item?.savequestionId == `${item?.questionId}am`) {
                resultDatasaveandmark.push(item);
            }
        })


        // final result data

        const AnswerdResultData = resultDatasave.concat(resultDatasaveandmark);

        AnswerdResultData.map((item) => {
            if (item?.subject == "Biology 11" || item?.subject == "Biology 12") {
                let data = {
                    questionId: item?.oriquestionId,
                    difficulty_level: item?.difficulty_level,
                    answer: item?.answer,
                    chapterName: item?.chapterName,
                    subTopic: item?.subTopic,
                    your_responce: item?.your_responce,
                    correct_responses: item?.correct_responses,
                    score: item?.score,
                    correct_to_incorrect: item?.correct_to_incorrect,
                    incorrect_to_correct: item?.incorrect_to_correct,
                    incorrect_to_incorrect: item?.incorrect_to_incorrect,
                    correct_to_unanswered: item?.correct_to_unanswered,
                    incorrect_to_unanswered: item?.incorrect_to_unanswered,

                }
                let timedata = {
                    spendtime: item?.spendtime
                }
                biodatatime.push(timedata);
                biodata.push(data);
            }
            else if (item?.subject == "Physics 11" || item?.subject == "Physics 12") {
                let data = {
                    questionId: item?.oriquestionId,
                    difficulty_level: item?.difficulty_level,
                    answer: item?.answer,
                    chapterName: item?.chapterName,
                    subTopic: item?.subTopic,
                    your_responce: item?.your_responce,
                    correct_responses: item?.correct_responses,
                    score: item?.score,
                    correct_to_incorrect: item?.correct_to_incorrect,
                    incorrect_to_correct: item?.incorrect_to_correct,
                    incorrect_to_incorrect: item?.incorrect_to_incorrect,
                    correct_to_unanswered: item?.correct_to_unanswered,
                    incorrect_to_unanswered: item?.incorrect_to_unanswered,
                }
                let timedata = {
                    spendtime: item?.spendtime
                }
                phydatatime.push(timedata);
                phydata.push(data);
            }

            else if (item?.subject == "Chemistry 11" || item?.subject == "Chemistry 12") {
                let data = {
                    questionId: item?.oriquestionId,
                    difficulty_level: item?.difficulty_level,
                    answer: item?.answer,
                    chapterName: item?.chapterName,
                    subTopic: item?.subTopic,
                    your_responce: item?.your_responce,
                    correct_responses: item?.correct_responses,
                    score: item?.score,
                    correct_to_incorrect: item?.correct_to_incorrect,
                    incorrect_to_correct: item?.incorrect_to_correct,
                    incorrect_to_incorrect: item?.incorrect_to_incorrect,
                    correct_to_unanswered: item?.correct_to_unanswered,
                    incorrect_to_unanswered: item?.incorrect_to_unanswered,
                }
                let timedata = {
                    spendtime: item?.spendtime
                }
                chedatatime.push(timedata);
                chedata.push(data);
            }
        })
        setBiologyData(biodata)
        setPhysicsData(phydata)
        setChemistryData(chedata)

        // time datas

        setBiologyDataSpendtime(biodatatime)
        setPhysicsDataSpendtime(phydatatime)
        setChemistryDataSpendtime(chedatatime)




    }, [result])



    const minutestotal = `${Math.floor(timercount / 60)}`.padStart(2, "0");
    const secondstotal = `${timercount - minutestotal * 60}`.padStart(2, "0");
    const finaltimes = `${minutestotal}:${secondstotal}`;

    const SubmitAllDatas = async () => {

        // biology
        const questionAttemtedbio = biologydata?.length;
        const correctanswerbio = biologydata?.filter((item) => item?.answer === true);
        const incorrectanswerbio = biologydata?.filter((item) => item?.answer === false);
        const biotimespend = biologydataspendtime?.reduce((acc, current) => {
            return acc + current?.spendtime
        }, 0);

        const minutesbio = `${Math.floor(biotimespend / 60)}`.padStart(2, "0");
        const secondsbio = `${biotimespend - minutesbio * 60}`.padStart(2, "0");
        const finaltimesbio = `${minutesbio}:${secondsbio}`;


        const correctcountbio = correctanswerbio?.length * 4;
        const Incorrectcountbio = incorrectanswerbio?.length;
        const finalScorebio = correctcountbio - Incorrectcountbio;

        const correcttoincorrectbio = biologydata?.reduce((acc, current) => {
            return acc + current?.correct_to_incorrect
        }, 0);

        const incorrecttocorrectbio = biologydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_correct
        }, 0);

        const incorrecttoincorrectbio = biologydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_incorrect
        }, 0);

        const correcttounansweredbio = biologydata?.reduce((acc, current) => {
            return acc + current?.correct_to_unanswered
        }, 0);

        const incorrecttounansweredbio = biologydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_unanswered
        }, 0);





        // physics

        const questionAttemtedphy = physicsdata?.length;
        const phycorrectanswer = physicsdata?.filter((item) => item?.answer === true);
        const phyincorrectanswer = physicsdata?.filter((item) => item?.answer === false);
        const correctcountphy = phycorrectanswer?.length * 4;
        const Incorrectcountphy = phyincorrectanswer?.length;
        const finalScorephy = correctcountphy - Incorrectcountphy;
        const phytimespend = physicsdataspendtime?.reduce((acc, current) => {
            return acc + current?.spendtime
        }, 0);



        const correcttoincorrectphy = physicsdata?.reduce((acc, current) => {
            return acc + current?.correct_to_incorrect
        }, 0);

        const incorrecttocorrectphy = physicsdata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_correct
        }, 0);

        const incorrecttoincorrectphy = physicsdata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_incorrect
        }, 0);

        const correcttounansweredphy = physicsdata?.reduce((acc, current) => {
            return acc + current?.correct_to_unanswered
        }, 0);

        const incorrecttounansweredphy = physicsdata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_unanswered
        }, 0);

        const minutesphy = `${Math.floor(phytimespend / 60)}`.padStart(2, "0");
        const secondsphy = `${phytimespend - minutesphy * 60}`.padStart(2, "0");
        const finaltimesphy = `${minutesphy}:${secondsphy}`;

        // chemistry
        const questionAttemtedche = chemistrydata?.length;
        const checorrectanswer = chemistrydata?.filter((item) => item?.answer === true);
        const cheincorrectanswer = chemistrydata?.filter((item) => item?.answer === false);
        const correctcountche = checorrectanswer?.length * 4;
        const Incorrectcountche = cheincorrectanswer?.length;
        const finalScoreche = correctcountche - Incorrectcountche;
        const chetimespend = chemistrydataspendtime?.reduce((acc, current) => {
            return acc + current?.spendtime
        }, 0);

        const correcttoincorrectche = chemistrydata?.reduce((acc, current) => {
            return acc + current?.correct_to_incorrect
        }, 0);

        const incorrecttocorrectche = chemistrydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_correct
        }, 0);

        const incorrecttoincorrectche = chemistrydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_incorrect
        }, 0);

        const correcttounansweredche = chemistrydata?.reduce((acc, current) => {
            return acc + current?.correct_to_unanswered
        }, 0);

        const incorrecttounansweredche = chemistrydata?.reduce((acc, current) => {
            return acc + current?.incorrect_to_unanswered
        }, 0);


        const minutesche = `${Math.floor(chetimespend / 60)}`.padStart(2, "0");
        const secondsche = `${chetimespend - minutesche * 60}`.padStart(2, "0");
        const finaltimesche = `${minutesche}:${secondsche}`;
        // Total time spend user overall
        const totalTimeSpend = Number(biotimespend) + Number(phytimespend) + Number(chetimespend);
        const minutestotaltimespend = `${Math.floor(totalTimeSpend / 60)}`.padStart(2, "0");
        const secondstotaltimespend = `${totalTimeSpend - minutestotaltimespend * 60}`.padStart(2, "0");
        const finaltimetotaltimespend = `${minutestotaltimespend}:${secondstotaltimespend}`;
        const totalMarkCountplus = Number(correctcountbio) + Number(correctcountphy) + Number(correctcountche);
        const totalMarkCountnegative = Number(incorrectanswerbio?.length) + Number(phyincorrectanswer?.length) + Number(cheincorrectanswer?.length);
        const totalMarkCount = Number(totalMarkCountplus) - Number(totalMarkCountnegative);
        const ListTimeCount = Number(finaltimesbio) + Number(finaltimesphy) + Number(finaltimesche);
        function timeStringToMinutes(timeString) {
            const [hours, minutes] = timeString.split(":").map(Number);
            return hours * 60 + minutes;
        }

        const vars1 = timeStringToMinutes(finaltimesbio) + timeStringToMinutes(finaltimesphy) + timeStringToMinutes(finaltimesche);
        const timer1 = `${Math.floor(vars1 / 60)}`.padStart(2, "0");
        const timer2 = `${vars1 - timer1 * 60}`.padStart(2, "0");
        const timer3 = `${timer1}:${timer2}`;
        setFinalCheckTime(ListTimeCount);
        const responsedata = {
            // _id: loginuser?.user_details?._id,
            mockQuestionId: MockquestionId,
            totalTime: `${timer3}`,
            totalMarks: `${totalMarkCount}`,
            Biology: {
                questions_attemped: `${questionAttemtedbio}`,
                correct_responses: `${correctanswerbio?.length}`,
                incorrect_responses: `${incorrectanswerbio?.length}`,
                marks: `${correctcountbio}`,
                negative_marks: `${incorrectanswerbio?.length}`,
                actual_scored: `${finalScorebio}`,
                totaltime_taken: `${finaltimesbio}`,
                correct_to_incorrect: `${correcttoincorrectbio}`,
                incorrect_to_correct: `${incorrecttocorrectbio}`,
                incorrect_to_incorrect: `${incorrecttoincorrectbio}`,
                correct_to_unanswered: `${correcttounansweredbio}`,
                incorrect_to_unanswered: `${incorrecttounansweredbio}`,
                questions: biologydata,
            },
            Physics: {
                questions_attemped: `${questionAttemtedphy}`,
                correct_responses: `${phycorrectanswer?.length}`,
                incorrect_responses: `${phyincorrectanswer?.length}`,
                marks: `${correctcountphy}`,
                negative_marks: `${phyincorrectanswer?.length}`,
                actual_scored: `${finalScorephy}`,
                totaltime_taken: `${finaltimesphy}`,
                correct_to_incorrect: `${correcttoincorrectphy}`,
                incorrect_to_correct: `${incorrecttocorrectphy}`,
                incorrect_to_incorrect: `${incorrecttoincorrectphy}`,
                correct_to_unanswered: `${correcttounansweredphy}`,
                incorrect_to_unanswered: `${incorrecttounansweredphy}`,
                questions: physicsdata
            },
            Chemistry: {
                questions_attemped: `${questionAttemtedche}`,
                correct_responses: `${checorrectanswer?.length}`,
                incorrect_responses: `${cheincorrectanswer?.length}`,
                marks: `${correctcountche}`,
                negative_marks: `${cheincorrectanswer?.length}`,
                actual_scored: `${finalScoreche}`,
                totaltime_taken: `${finaltimesche}`,
                correct_to_incorrect: `${correcttoincorrectche}`,
                incorrect_to_correct: `${incorrecttocorrectche}`,
                incorrect_to_incorrect: `${incorrecttoincorrectche}`,
                correct_to_unanswered: `${correcttounansweredche}`,
                incorrect_to_unanswered: `${incorrecttounansweredche}`,
                questions: chemistrydata,
            },
        }
        dispatch(SubmitAnswerAction(responsedata, setLoading));
    }
    const optionListCount = Number(answeredquestions?.length) + Number(marksavedata?.length) + Number(FinalReadQuestionTotal?.length) + Number(markforreviewData?.length);
    useEffect(() => {
        let checkList = [];
        result?.map((item, index) => {
            if (item?.savequestionId === `${index + 1}s`) {
                checkList.push(item?.savequestionId)
            }
        })
        setResaltDatasave(checkList);
    }, [result, questionss, CurrentOptionvalue])

    useEffect(() => {
        let values = [];
        let markData = [];
        let optionData = [];
        result?.map((item) => {
            values.push(item?.questionId)
        })
        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}em`) {
                markData.push(item?.savequestionId);
            }
        })
        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}e`) {
                optionData.push(item?.savequestionId);
            }
        })
        setReadQuestions(optionData)
        setMarkForReviewData(markData);
    }, [QuestionData])

    useEffect(() => {
        const updateId = QuestionOptiondata[trace].Queid;
        const resultdata = result.find((item) => item?.oriquestionId === updateId);
        if (resultdata?.name) {
            setCheckData(resultdata);
        }
        else {
            setCheckData("");
        }
    }, [notanswerquestions, spendTime]);

    useEffect(() => {
        setInterval(() => {
            setSpendTime((pre) => pre + 1);
            setTimerCount((pre) => pre + 1)
        }, 1000);

        return () => clearInterval();
    }, [])


    useEffect(() => {
        let checkList = [];
        resultOptions?.map((item) => {
            if (item?.savequestionId) {
                checkList.push(item?.savequestionId)
            }
        })
        setNotAnswerQuestions(checkList);
        dispatch(GetQuestionsSingle(CurrentOptionvalue))

    }, [resultOptions]);



    useEffect(() => {
        if (submittrue === 1) {
            setShow2(true);
        }
    }, [submittrue, QuestionData])

    const refreshQuestion = () => {
        const originalId = QuestionOptiondata[trace]?.Queid;
        dispatch(GetQuestionsSingle(originalId));
    }

    // visited count check user
    useEffect(() => {
        document.addEventListener("visibilitychange", () => {
            // document.title = document.visibilityState;
            // console.log(document.hidden);
            if (document.hidden) {
            }
            else {
                if (visitedCountBrowser === 2) {
                    // setVisitedBrowswerCount(2);
                }
                else {
                    // handleShow4();
                    setVisitedBrowswerCount(visitedCountBrowser + 1);

                }
            }
            // if (visitedCountBrowser === 2) {
            //     handleShow2();
            // }
        })
    }, [visitedCountBrowser]);

    useEffect(() => {
        if (visitedCountBrowser === 2) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(10);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };

        }

    }, [seconds, visitedCountBrowser, minutes]);

    useEffect(() => {
        if (seconds === 0 && minutes === 0) {
            // AnswersSubmited()
        }
        if (submittrue === 1) {
            AnswersSubmited()
        }
    }, [seconds, minutes, submittrue]);

    const notvisitedAnswerd = resuleDatasave?.length === 0 && markforreviewData?.length === 0 && marksavedata?.length === 0;

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
    }, [MockquestionId, type]);

    const [showAnsweredmodal, setAnsweredModal] = useState(false);



    const handleShowAnswerdModalTrue = (e) => {
        setAnsweredModal(true)
        CurrentModalType(e)
    }

    const handleShowAnswerdModalFlase = () => {
        setAnsweredModal(false)
    }

    const [checkCurrentModaltype, setCheckCurrentModalType] = useState("");
    const CurrentModalType = (e) => {
        setCheckCurrentModalType(e)
    }

    useEffect(() => {
    }, [checkCurrentModaltype]);

    useEffect(() => {
        const answeredResult = [];
        const answeredResultumbers = [];
        const notansweredResult = [];
        const notansweredResultumbers = [];
        const notvisitedResult = [];
        const markedforreviewResult = [];
        const markedforreviewResultnumbers = [];
        const savemarkedforreviewResult = [];
        const savemarkedforreviewResultnumbers = [];

        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}s`) {
                answeredResult.push(item?.savequestionId);
                answeredResultumbers.push(item?.questionId);
            }
        })

        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}e`) {
                notansweredResult?.push(item?.savequestionId);
                notansweredResultumbers?.push(item?.questionId);
            }
        })
        result?.map((item, index) => {
            if (item?.savequestionId == "") {
                notvisitedResult?.push(index + 1);
            }
        })

        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}em`) {
                markedforreviewResult?.push(item?.savequestionId);
                markedforreviewResultnumbers?.push(item?.questionId);
            }
        })

        result?.map((item) => {
            if (item?.savequestionId == `${item?.questionId}am`) {
                savemarkedforreviewResult?.push(item?.savequestionId);
                savemarkedforreviewResultnumbers?.push(item?.questionId);
            }
        })
        setAnsweredCount(answeredResultumbers)
        setAnsweredCountresult(answeredResult)
        // not answered
        setNotAnsweredCount(notansweredResult)
        setNotAnsweredCountresult(notansweredResultumbers)

        // not visited
        setNotVisitedCount(notvisitedResult)

        //  marked for review

        setMarkedForReviewresult(markedforreviewResult);
        setMatkedForReviewCount(markedforreviewResultnumbers);

        // save and marked for review

        setSaveMarkedForReviewresult(savemarkedforreviewResult);
        setSaveMatkedForReviewCount(savemarkedforreviewResultnumbers);

    }, [result, FirstQuestionId]);






    return (
        <div>
            <div className='quiz-main-section'>
                <div className='inside-quiz-section'>
                    <div className='inside-body-section-quiz'>
                        <div className='quiz-left-section'>
                            <div className='quiz-options-box'>
                                <Questions OnCheck={OnCheck} spendTimes={spendTime} />
                                <div className='question-refresh-box' onClick={refreshQuestion}>
                                    <ion-icon name="reload-outline"></ion-icon>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='mt-2 button-list-overall'>
                                    {/* saveandNextButton */}
                                    <div>
                                        <button className='saveandnextbtn' onClick={
                                            trace + 1 === QuestionOptiondata?.length + 1 ? ShowHandleSubmitPopup :
                                                saveandNextButton
                                        }>{HomeContent?.saveandnext}</button>
                                    </div>
                                    {/* savemarkbtn */}
                                    <div>
                                        <button className='savemarkbtn' onClick={trace + 1 === QuestionOptiondata?.length + 1 ? ShowHandleSubmitPopup : saveandandMarkReviewButton}>{HomeContent?.saveandmarkforreview}</button>
                                    </div>
                                    {/* markforreviewbtn */}
                                    <div>
                                        <button className='markforreviewbtn' onClick={trace + 1 === QuestionOptiondata?.length + 1 ? ShowHandleSubmitPopup : MarkedforReviewButton}>{HomeContent?.markforreviewnext}</button>
                                    </div>
                                    {/* clearbtn */}
                                    <div>
                                        <button className='clearbtn' onClick={ClearOptions}>{HomeContent?.clear}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='quiz-right-section'>
                            <div className='remaining-time'>
                                <div className={timerCountalert ? "timer-alert-box" : 'time-box'} >
                                    <ion-icon name="alarm-outline" ></ion-icon>
                                </div>
                                <Timer
                                    duration={3 * 60 * 60 * 1000}
                                    setSpendTimeFinalCal={setSpendTimeFinalCal}
                                    setSubmitresulttime={setSubmittrue} setTimeCountAlerts={setTimeCountAlert}
                                    setSpendTimeFinalCal1={setSpendTimeFinalCal1}
                                />
                            </div>
                            <div className='option-box-lists'>
                                <div className='option-box-flex mb-3 mt-3'>
                                    <div className='left-option-box' >
                                        <div className='result-box-options cursor' onClick={() => handleShowAnswerdModalTrue("Answered")}>
                                            <div className='answered-box'>
                                                {resuleDatasave?.length <= 9 ? <>0{resuleDatasave?.length}</> : <>{resuleDatasave?.length}</>}
                                            </div>
                                            <div className='text-gap'>
                                                Answered
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right-option-box'>
                                        <div className='result-box-options cursor' onClick={() => handleShowAnswerdModalTrue("Not Answered")}>
                                            <div className='not-answered-box'>
                                                {notvisitedAnswerd ? <>
                                                    {Number(FinalReadQuestionTotal?.length) + 1 <= 9 ? <>0{Number(FinalReadQuestionTotal?.length)}</> : <>{Number(FinalReadQuestionTotal?.length)}</>}
                                                </> : <>
                                                    {Number(FinalReadQuestionTotal?.length) <= 9 ? <>0{Number(FinalReadQuestionTotal?.length)}</> : <>{Number(FinalReadQuestionTotal?.length)}</>}

                                                </>}
                                                {/* {Number(FinalReadQuestionTotal?.length) <= 9 ? <>0{Number(FinalReadQuestionTotal?.length)}</> : <>{Number(FinalReadQuestionTotal?.length)}</>} */}
                                            </div>
                                            <div className='text-gap'>
                                                Not Answered
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='option-box-flex mb-3 mt-3'>
                                    <div className='left-option-box'>
                                        <div className='result-box-options cursor' onClick={() => handleShowAnswerdModalTrue("Not Visited")}>
                                            <div className='not-visit-answered-box'>
                                                {notvisitedAnswerd ? <>
                                                    {Number(result?.length - 1) - Number(optionListCount) <= 9 ? <>{Number(result?.length) - Number(optionListCount)}</> : <>{Number(result?.length) - Number(optionListCount)}</>}
                                                </> : <>
                                                    {Number(result?.length) - Number(optionListCount) <= 9 ? <>{Number(result?.length) - Number(optionListCount)}</> : <>{Number(result?.length) - Number(optionListCount)}</>}
                                                </>}

                                                {/* {Number(result?.length) - Number(optionListCount) <= 9 ? <>{Number(result?.length) - Number(optionListCount)}</> : <>{Number(result?.length) - Number(optionListCount)}</>} */}
                                            </div>
                                            <div className='text-gap'>
                                                Not Visited
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right-option-box'>
                                        <div className='result-box-options cursor' onClick={() => handleShowAnswerdModalTrue("Marked for Review")}>
                                            <div className='mark-answered-box'>
                                                {markforreviewData?.length <= 9 ? <>0{markforreviewData?.length}</> : <>{markforreviewData?.length}</>}
                                            </div>
                                            <div className='text-gap'>
                                                Marked for Review
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='option-box-flex mb-3 mt-3'>
                                    <div className='full-option-box cursor' onClick={() => handleShowAnswerdModalTrue("Save & Marked for Review")}>
                                        <div className='left-box-answer-full cursor'  >
                                            <div className='not-mark-answered-box'>
                                                {marksavedata?.length <= 9 ? <>0{marksavedata?.length}</> : <>{marksavedata?.length}</>}
                                            </div>
                                        </div>
                                        <div className='result-box-options1'>
                                            <div >
                                                {/* Answered & Marked for Review
                                        will be considered for evaluation */}
                                                Save & Marked <br />for Review
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='mt-3'>
                                <Options setSpendTim={setSpendTime}
                                    FirstQuestionId={FirstQuestionId}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='inside-body-section-footer-quiz'>
                        <div className='footer-section-quiz'>
                            <div className='main-footer'>
                                <div className='footer-inside'>
                                    <div className='inside-inside-footer-body'>
                                        <div className='left-footer'>
                                            <div className='left-inside-buttons'>
                                                {trace > 0 ?
                                                    <div>
                                                        <button className='back-btn' onClick={
                                                            deCreament
                                                        }>{HomeContent?.back}</button>


                                                    </div> : <div>
                                                        <button className='back-btn-disabled'>{HomeContent?.back}</button>
                                                    </div>}
                                                <div>
                                                    {trace + 1 === QuestionOptiondata?.length ? <>

                                                        <button className='back-btn-disabled'>{HomeContent?.next}</button>
                                                    </> : <>

                                                        <button className='next-btn'
                                                            onClick={
                                                                inCreament
                                                            }
                                                        >{HomeContent?.next}</button>
                                                    </>}

                                                </div>
                                            </div>
                                            <div className='right-inside-buttons'>
                                                <div>
                                                    <button className='submit-btn' onClick={SubmitAnswers}>{HomeContent?.submit}</button>
                                                </div>
                                            </div>


                                        </div>
                                        <div className='right-footer'>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div >


            {/* Save and Next Modal Popup */}
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>  Question {trace + 1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please choose an option
                </Modal.Body>
                <Modal.Footer>

                    <div className='main-footer-section'>
                        <button className="ok-btn" onClick={handleClose}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Answered Modal Popup */}
            <Modal show={show2}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Body>
                    <div className='text-center'>
                        <div className='mb-4 mt-2 confirm-texts'>
                            Confirm your submission
                        </div>
                        <div className='confrim-list-options'>
                            {ListOptionAnswers?.map((item, index) => {
                                return (
                                    <div className='item-list-right' key={index}>
                                        <div className='first-box-one'>
                                            <div className='mt-2 p-2'>
                                                {item?.name}
                                                <div className='mt-1'>
                                                    {index === 0 &&
                                                        <div className='not mb-3'>

                                                        </div>}

                                                    {index === 1 && <div className='answerd-box mb-3'>

                                                    </div>}

                                                    {index === 2 && <div className='unanswerd-box mb-3'>

                                                    </div>}
                                                    {index === 3 && <div className='markedforreview-box mb-3'>

                                                    </div>}
                                                    {index === 4 && <div className='markedforreview-box-success mb-3'>
                                                    </div>}
                                                </div>
                                            </div>

                                        </div>
                                        <div className='second-box-second'>
                                            {index === 0 && <div>
                                                <div className='font-sizes-texts'>
                                                    {QuestionOptiondata?.length <= 9 ? <>0{QuestionOptiondata?.length}</> : <>{QuestionOptiondata?.length}</>}
                                                </div>
                                            </div>}
                                            {index === 1 && <div>
                                                <div className='font-sizes-texts'>
                                                    {answeredquestions?.length <= 9 ? <>0{answeredquestions?.length}</> : <>{answeredquestions?.length}</>}

                                                </div>
                                            </div>}
                                            {index === 2 && <div>
                                                <div className='font-sizes-texts'>
                                                    {Number(FinalReadQuestionTotal?.length) <= 9 ? <>{Number(FinalReadQuestionTotal?.length) +
                                                        Number(result?.length) - Number(optionListCount)
                                                    }</> : <>{Number(FinalReadQuestionTotal?.length) + Number(result?.length) - Number(optionListCount)}</>}
                                                </div>

                                            </div>}
                                            {index === 3 && <div>


                                                <div className='font-sizes-texts'>
                                                    {markforreviewData?.length <= 9 ? <>0{markforreviewData?.length}</> : <>{markforreviewData?.length}</>}

                                                </div>
                                            </div>}
                                            {index === 4 && <div>

                                                <div className='font-sizes-texts'>
                                                    {marksavedata?.length <= 9 ? <>0{marksavedata?.length}</> : <>{marksavedata?.length}</>}

                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='mt-3 fw-bold'>
                            Are you sure you want to submit for final marking?
                        </div>
                        <div className='fw-bold'>
                            No changes will be allowed after submission.
                        </div>
                        <div className='main-footer-section mb-4 mt-4'>
                            <button className="ok-btn" onClick={AnswersSubmited}>
                                {loading ? <>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </> : <>
                                    Submit
                                </>}

                            </button>

                            {submittrue === 1 || visitedCountBrowser === 2 ? <>
                                <button className="cancel-btn">
                                    Cancel
                                </button></> : <>
                                <button className="cancel-btn" onClick={handleClose1}>
                                    Cancel
                                </button>
                            </>}

                        </div>
                    </div>
                </Modal.Body>

            </Modal>


            {/* Save Mark for Review Modal Popup */}

            <Modal show={show3}
                onHide={handleClose3}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> Question {trace + 1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please choose an option
                </Modal.Body>
                <Modal.Footer>
                    <div className='main-footer-section'>
                        <button className="ok-btn" onClick={handleClose3}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>


            </Modal>


            {/* visible browser check */}


            <Modal show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
                centered
            >

                <Modal.Body>



                    <div className='modal-conditions'>
                        <div>

                            {visitedCountBrowser === 2 ? <>
                                <img src={wrongimage} alt="no image" className='warning-image-box' />

                            </> : <>
                                <img src={warningimage} alt="no image" className='warning-image-box' />
                            </>}
                        </div>

                        {visitedCountBrowser === 2 ? <>
                            <div className='mt-2 mb-3 please-texts'>
                                Sorry, since you have opened another browser, your test is being closed.
                            </div>
                            <div className='fw-bold fs-4' style={{ color: "red" }}>
                                {seconds > 0 || minutes > 0 ? (
                                    <span className="minutes-section">
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                ) : null}
                            </div>
                        </> : <>

                            <div className='warining-text'>
                                Warning!
                            </div>
                            <div className='mt-2 mb-3 please-texts'>
                                It is not permitted to open another browser during the test.
                            </div>
                            <div className='next-text'>
                                The test browser will close automatically if you open another browser again.
                            </div>
                        </>}

                        <div className='mb-4 mt-4'>
                            {visitedCountBrowser === 2 ? <>

                            </> : <>
                                <button className='ok-btn-texts' onClick={handleClose4}>
                                    Ok
                                </button>
                            </>}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                <AnswerModal
                    showAnsweredmodal={showAnsweredmodal}
                    hanldeClose={handleShowAnswerdModalFlase}
                    title={checkCurrentModaltype}

                    // answered

                    answeredcount={answeredcount}
                    answeredcountresult={answeredcountresult}
                    // not answered

                    notansweredcount={notansweredcount}
                    notansweredcountresult={notansweredcountresult}

                    // not visited

                    notvisitedcount={notvisitedcount}

                    // marked for review
                    markedforreviewcount={markedforreviewcount}
                    markedforreviewresult={markedforreviewresult}

                    //save and marked for review
                    savemarkedforreviewcount={savemarkedforreviewcount}
                    savemarkedforreviewresult={savemarkedforreviewresult}


                />
            </div>
        </div>
    )
})

export default Quiz