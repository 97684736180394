import instanceBaseurl from "../../config/BaseUrl";

export async function getOverallQuestion(data) {

    try {
        const response = await instanceBaseurl.post(`/neet/ques/`, data);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function getSingleQuestion(questionids) {

    try {
        const response = await instanceBaseurl.get(`/neet/user/${questionids}`);
        return response.data;
    } catch (err) {
        throw err;
    }
}



export async function getOverallQuestionJee(data) {

    try {
        const response = await instanceBaseurl.post(`/jee/ques/`, data);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function getSingleQuestionJee(questionids) {

    try {
        const response = await instanceBaseurl.get(`/jee/user/${questionids}`);
        return response.data;
    } catch (err) {
        throw err;
    }
}