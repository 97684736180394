import { createSlice } from "@reduxjs/toolkit";
const SuggestedCourse_Reducer = createSlice({
    name: "Suggested",
    initialState: {
        loading: false,
        SuggestedCourses: []
    },
    reducers: {
        SuggestedRequest(state, action) {
            return {
                loading: true
            }
        },
        SuggestedSuccess(state, action) {
            return {
                loading: false,
                SuggestedCourses: action.payload
            }
        },
        SuggestedFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = SuggestedCourse_Reducer;
export const { SuggestedRequest, SuggestedSuccess, SuggestedeFail } = actions;
export default reducer;