import instanceBaseurl from "../../config/BaseUrl";
export async function rankService(data, current) {
    try {
        const response = await instanceBaseurl.post(`/ranksim/filter-caste-base?page=${current}&limit=10`, data);
        return response?.data;
    } catch (err) {
        throw err;
    }
}

export async function rankLocationService() {
    try {
        const response = await instanceBaseurl.get("/ranksim/get-all-locations");
        return response?.data;
    } catch (err) {
        throw err;
    }
}

export async function rankFilterService(data) {
    try {
        const response = await instanceBaseurl.post("/ranksim/filter-caste-base", data);
        return response?.data;
    } catch (err) {
        throw err;
    }
}