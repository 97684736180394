import { createSlice } from "@reduxjs/toolkit";
const QaGet_Reducer = createSlice({
    name: "QaGet",
    initialState: {
        loading: false,
        QaGetData: [],
       
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        prevPage: false,
        nextPage: false,
        status: 'idle',
        error: null,
    },
    reducers: {
        QaGetRequest(state, action) {
            return {
                loading: true
            }
        },
        QaGetSuccess(state, action) {
            return {
                loading: false,
                QaGetData: action.payload
            }
        },
        QaGetFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = QaGet_Reducer;
export const { QaGetRequest, QaGetSuccess, QaGetFail } = actions;
export default reducer;