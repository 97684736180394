import React, { useEffect, useState } from 'react'
import './Rank.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RankReportActions } from './../../../../redux/actions/rankActions/RankActions';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Ranklistcard from './Ranklistcard';
import norecord from '../../../../assests/images/empty-rank-img.png';
import { rankService } from '../../../../services/rank/rank_services';
import { useLocation } from 'react-router-dom';
function Rankresult() {

    const { state } = useLocation();



    const dispatch = useDispatch();
    const stateData = useSelector((state) => state?.rank?.RankData);
    const stateReport = useSelector((state) => state?.report);
    const { ReportData } = stateReport
    const [current, setCurrent] = useState(1);
    const [limit, setLimit] = useState(current * 10);
    const [pagecount, setPagecount] = useState("");
    const [pagecountnumbers, setPagecountNumbers] = useState(1);
    const [resultdata, setResultData] = useState([])
    const [search, setSearch] = useState(state?.totalMark ? state?.totalMark : "");
    const [category, setCategory] = useState("GEN");
    const [states, setStates] = useState("");
    const [percent, setPercent] = useState(0);
    const [error, setError] = useState(false);
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [loading, setLoadnig] = useState(false);
    const SearchButton = async () => {
        if (!search || !category) {
            setError(true);
        }
        if (category && search) {
            setError(false);
            const data = {
                mark: `${search}`,
                category: `${category}`,
                state: `${states}`
            }
            await dispatch(RankReportActions(data, current));
        }
    }

    useEffect(() => {

        if (search && category) {
            setError(false);
        }

        if (stateData?.result?.data) {
            setResultData(stateData?.result?.data);
        }

    }, [error, category, search, states, stateData, state]);

    useEffect(() => {

        var percentage = 0;
        if (search) {
            percentage += 33;
        }
        if (category) {
            percentage += 33;
        }
        if (states) {
            percentage += 34;
        }
        setPercent(percentage);
    }, [search, states, category, percent]);

    const getYear = new Date();

    const Year = getYear.getFullYear();

    const filterObjects = { states, search, category };
    useEffect(() => {
    }, [stateData, current])

    const fetchComments = async (current) => {
        const data = {
            mark: `${search}`,
            category: `${category}`,
            state: `${states}`
        }
        setLoadnig(true)
        try {
            const response = await rankService(data, current);
            if (response) {
                setResultData(response?.result?.data);
                setLoadnig(false)
            }

        } catch (error) {
            setLoadnig(false)
        }
    }


    const handlePageClick = async (data) => {
        let current = data?.selected + 1;
        setCurrent(current);
        goToTop();
        await fetchComments(current);
    }

    useEffect(() => {
    }, [loading])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 200) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);



    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className='main-rank'>
            <div className='inside-rank'>
                <div className='mt-5 text-center fw-bold fs-3'>
                    NEET College Predictor {Year} {current}
                </div>
                <div className='mt-4 mb-4 w-75 mx-auto progressbar-box'>

                    {/* <ol class="steps">
                        <li class={search?.length > 0 ? "step is-complete" : "step is-active"} data-step="1">
                            NEET Mark

                        </li>
                        <li class={category?.length > 0 ? "step is-complete" : "step is-active"} data-step="2">
                            Category

                        </li>
                        <li class={states?.length > 0 ? "step is-complete" : "step is-active"} data-step="3">
                            Domicile State
                        </li>

                    </ol> */}

                    <ol class="steps">
                        <li class={"step is-complete"} data-step="1">
                            NEET Mark

                        </li>
                        <li class={"step is-complete"} data-step="2">
                            Category

                        </li>
                        <li class={"step is-complete"} data-step="3">
                            Domicile State
                        </li>

                    </ol>

                    {/* <ProgressBar
                        percent={percent}
                        className="progressbar-width"
                        height={"1px"}
                        filledBackground="linear-gradient(98deg, #9c1303 0%, #e81402 100%)"
                    >
                        <Step transition="scale">
                            {({ accomplished }) => (

                                <div className='ms-3'>
                                    {search ? <div className='success-icon'>
                                        <i class="fa-solid fa-circle-check success-icon "></i>
                                    </div> : <>
                                        <i class="fa-regular fa-circle-check unsuccess-icon"></i>

                                    </>}
                                    <div className='mt-2'>
                                        NEET
                                    </div>
                                </div>
                            )}
                        </Step>
                        <Step transition="scale">
                            {({ accomplished }) => (

                                <div>

                                    {category ? <div className='success-icon'>
                                        <i class="fa-regular fa-circle-check success-icon"></i>
                                    </div> : <>
                                        <i class="fa-regular fa-circle-check unsuccess-icon"></i>
                                    </>}


                                    <div className='mt-2'>
                                        Category
                                    </div>


                                </div>
                            )}
                        </Step>
                        <Step transition="scale">
                            {({ accomplished }) => (

                                <div>
                                    {states ? <div className='success-icon'>
                                        <i class="fa-regular fa-circle-check success-icon"></i>
                                    </div> : <div className='unsuccess-icon'>
                                        <i class="fa-regular fa-circle-check unsuccess-icon "></i>

                                    </div>}
                                    <div className='mt-2'>
                                        State
                                    </div>
                                </div>
                            )}
                        </Step>
                    </ProgressBar> */}
                </div>
                <div className='rank-header mt-5 p-4'>
                    <div>
                        <div className='mb-2 fw-bold'>
                            NEET Mark
                        </div>
                        <div className='rank-input-box'>
                            <input type="text" placeholder="Enter Search Mark..."
                                value={search}
                                onChange={(e) => setSearch(e?.target?.value)}
                                className='form-box'
                            />
                        </div>
                    </div>

                    <div>
                        <div className='mb-2 fw-bold'>Category</div>
                        <div className='rank-input-box'>

                            <>
                                <select className='form-boxs'
                                    onChange={(e) => setCategory(e?.target?.value)
                                    }
                                    value={category}
                                >
                                    <option className='option-disabled-text'>--Please Select Category--</option>
                                    <option value="GEN">General</option>
                                    <option value="EWS">EWS</option>
                                    <option value="OBC">OBC</option>
                                    <option value="SC">SC</option>
                                    <option value="ST">ST</option>
                                </select>
                            </>
                        </div>
                    </div>

                    <div>
                        <div className='mb-2 fw-bold'>
                            Domicile State
                        </div>
                        <div>
                            <div className='rank-input-box'>
                                <><select className='form-boxs'
                                    onChange={(e) => setStates(e?.target?.value)
                                    }
                                    value={states}
                                >
                                    <option value="" disabled selected>--Choose Domicile State--</option>
                                    <option value="Andaman Nicobar Islands">Andaman Nicobar Islands</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chandigarh">Chandigarh</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Dadra And Nagar Haveli">Dadra And Nagar Haveli</option>
                                    <option value="Daman">Daman &amp; Diu</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Lakshadweep">Lakshadweep</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Orissa">Orissa</option>
                                    <option value="Pondicherry">Pondicherry</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                </select><span class="cp-err-msg"></span></>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='mt-4'>
                            <div className='serach-icon-box' onClick={SearchButton}>
                                <span> Predict Results </span>  <ion-icon name="search-outline"></ion-icon>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    {error ? <div className='text-danger mt-2 mb-3 text-center'>
                        Enter Rank / Score  required!
                    </div> : <></>}
                </div>
                <div className='mb-5'>
                    {stateData?.result?.length === 0 || stateData?.result === undefined ? <div className='fw-bold fs-2 text-center mt-5'>
                        <div>
                            <img src={norecord} alt="no image"
                                className='rank-empty-image'
                            />
                        </div>
                    </div> : <>
                        <div className='box-list-rank-cards'>
                            <Ranklistcard data={resultdata && resultdata}
                                category={category}
                                dataCategorys={filterObjects}
                                ClickClear={SearchButton}
                                result={stateData && stateData}
                                handlePageClick={handlePageClick}
                                current={current}
                                loadingloader={loading}
                            />
                        </div></>}
                </div>
            </div>

        </div >
    )
}

export default Rankresult
