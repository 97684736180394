import instanceBaseurl from "../../config/BaseUrl";


export async function submitAnswer(data) {

    try {
        const response = await instanceBaseurl.put(`/neet/user`, data);
        return response.data;
    } catch (err) {
        throw err;
    }
}