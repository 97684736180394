import React, { useEffect } from 'react'
import './Pdfstyle.scss';
function ConceptReport({ data }) {

    const datas = [
        {
            id: 1,
            name: "Biology",
            arraydata: data?.Biology
        },
        {
            id: 2,
            name: "Physics",
            arraydata: data?.Physics
        },
        {
            id: 3,
            name: "Chemistry",
            arraydata: data?.Chemistry
        }
    ]


    useEffect(() => {
    }, [data])
    return (
        <div>
            {datas?.map((item, index) => {
                return (
                    <div key={index}>
                        <div className='ms-3'>
                            <div className='common-title-section mt-5 mb-2'>
                                Concept wise Strengths and Areas of Improvement : <span className='fw-bold mb-3 mt-2'>{item?.name}</span>
                            </div>
                            <div>
                                This table will help you to identify your strong topics as well as sub topics that would require improvement             </div>
                        </div>
                        <div className='mt-4'>
                            <div className='table-section'>
                                <table class="table table-striped table-bordered">
                                    <thead className='thead-dark'>
                                        <tr>


                                            <th className='th-bg'> Topic</th>
                                            <th className='th-bg'>Sub Topic</th>
                                            <th className='th-bg'>No.of.

                                                Questions</th>

                                            <th className='th-bg'> No.of.Questions

                                                Attempted
                                            </th>
                                            <th className='th-bg'>No.of.Correct
                                                Responses</th>
                                            <th className='th-bg'>No.of.Incorrect
                                                Responses </th>
                                            <th className='th-bg'>
                                                Marks
                                                Scored
                                            </th>
                                            <th className='th-bg'>
                                                Strenght %
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {item?.arraydata?.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{items?.chapterName}</td>
                                                    <td>{items?.chapterName}</td>
                                                    <td>{items?.overAll?.no_of_ques}</td>
                                                    <td>{items?.overAll?.attemptQues}</td>
                                                    <td>{items?.overAll?.crtQues}</td>
                                                    <td>{items?.overAll?.inCrtQues}</td>
                                                    <td>{items?.overAll?.score}</td>
                                                    <td>{items?.overAll?.strength}</td>


                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default ConceptReport;