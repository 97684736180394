import React, { useEffect, useState } from "react";
import "./styles/Mocktest.scss";
import imagemock from "../../assests/images/mock1.png";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { GetNeetAllExams } from "../../redux/actions/NeetAction/NeetActions";
import Loader from "../../Loader/Loader";
import { getOverallQuestion } from "../../services/neetservices/question_service";
import { ToastError, ToastSuccess } from "../../toastmessages/Toastmessages";
import CardTest from "./CardTest";
import BuynowCards from "./BuynowCards";
import ConvertPdf from './PdfReportData/ConvertPdf';
import { GetReportActionUser } from "../../redux/actions/reportDownloadactions/ReportdownloadActions";
import nodataimage from '../../assests/images/no-data.png';

function Mocktest() {
  const dispatch = useDispatch();
  const stateReport = useSelector((state) => state?.report);
  const { ReportData } = stateReport;
  const [selectName, setSelectName] = useState("neet");
  const states = useSelector((state) => state?.neetExams);
  const { loading, NeetExamData } = states;
  const navigate = useNavigate();


  console.log(NeetExamData, 'NeetExamData')
  const ShowTests = async (masterId, id, type, index) => {

    const filterDataResponse =
      NeetExamData?.mockBundle && NeetExamData?.mockBundle[index];
    try {
      const datas = {
        masterId: masterId,
        id: id,
        type: type,
      };

      const response = await getOverallQuestion(datas);
      if (response) {
        navigate("/mocktest/list", {
          state: { data: filterDataResponse, selectName: selectName },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        handleNavigatePayment(index, type, masterId)
      }
    }
  };
  const FreemockTestNeet = (masterId, id) => {
    navigate("/mockexam", {
      state: {
        masterId: masterId,
        type: "individual",
        id: id,
        selectName: selectName,
      },
    });
  };
  useEffect(() => {
    if (selectName) {
      dispatch(GetNeetAllExams(selectName));
    }
  }, [selectName]);

  const handleNavigatePayment = async (index, type, id) => {
    if (type == "individual") {
      const filterDataResponse =
        NeetExamData?.mockPaidIndividual && NeetExamData?.mockPaidIndividual[index];
      const filterDataResponsePayment = NeetExamData?.mockBundle && NeetExamData?.mockBundle?.filter((item) => item?._id === id);
      navigate("/payment", { state: { data: filterDataResponsePayment[0], type: type, selectName: selectName, individualQuestionId: filterDataResponse?.mockQuestionId } });
    }
    else {
      const filterDataResponse =
        NeetExamData?.mockBundle && NeetExamData?.mockBundle[index];
      const filterDataResponsePayment = NeetExamData?.mockBundle && NeetExamData?.mockBundle?.filter((item) => item?._id === id);
      navigate("/payment", { state: { data: filterDataResponsePayment[0], type: type, selectName: selectName, name: filterDataResponse?.name, individualQuestionId: filterDataResponse?.questionDocs[0]?.mockQuestionId } });
    }
  };


  const individualTestPaymentCheck = async (masterId, id) => {
    const datas = {
      masterId: masterId,
      id: id,
      type: "individual",
    };
    try {
      const response = await getOverallQuestion(datas);
      if (response) {
      }
    } catch (error) {
      if (error?.response?.data?.message === "Please buy the question") {
        ToastError(error?.response?.data?.message);
      }
    }
  };

  const [CheckBuyNowflow, setCheckFlow] = useState([]);


  const responseNeetData = async () => {
    try {
      await NeetExamData && NeetExamData?.mockBundle?.map((item) => {
        if (NeetExamData?.mockBuyBundle?.includes(item?._id)) {
          setCheckFlow([true]);
        }
      });
    } catch (error) {

    }
  }
  useEffect(() => {
    responseNeetData();

  }, [CheckBuyNowflow])

  const handleChangeTests = (e) => {
    sessionStorage.setItem("testname", e?.target?.value);
    setSelectName(e?.target?.value);
  }

  const ReportDownload = async (masterId, id) => {
    try {
      const data = {
        mockQuestionId: id,
        type: "individual",
        questionId: masterId,
      }
      await dispatch(GetReportActionUser(data));
    } catch (error) {
    }
  }

  const NavigateRank = async (masterId, id) => {
    try {
      const data = {
        mockQuestionId: id,
        type: "individual",
        questionId: masterId,
      }
      await dispatch(GetReportActionUser(data));
      navigate(`/mocktest/rank`, { state: { totalMark: ReportData?.overAllReport?.examDetails?.totalMark } });
    } catch (error) {
    }
  }


  const [orderData, setOrderData] = useState([]);

  useEffect(() => {

    const DataSubscribe = [];
    const response = NeetExamData && NeetExamData?.mockBundle?.map((item) => {
      const count = item?.questionDocs?.length;
      const forms = {
        id: item?._id,
        name: item?.category,
        price: item?.price,
        questionDocCount: count ? count : 0,
        questionDocs: item?.questionDocs
      }
      DataSubscribe.push(forms);
    })
    const filterOrder = DataSubscribe?.sort((a, b) => {
      return a?.price - b?.price;
    })
    setOrderData(filterOrder);
  }, [NeetExamData?.mockBundle, CheckBuyNowflow])


  useEffect(() => {
  }, [orderData, NeetExamData])



  return (
    <div className="main-mocktest-section">
      <div className="inside-main-mocktest">
        <div className="empty-box3"></div>
        <div className="mocktest-instruction-section">
          <div className="left-mock-section">
            <div className="text-mock-orange">
              <span className="text-i">I</span>mportance Of
            </div>
            <div className="text-mock mt-1">Online Mock Test</div>
            {/* <div className="mt-3">
              <span className="cdp-text">CDP360 </span> stands for{" "}
              <span style={{ color: "orange" }}>Education Revolution</span>
            </div> */}
            <div className="mt-4">
              {NeetExamData?.mockFreeIndividual?.map((item, index) => {
                return (
                  <div
                    key={index}
                  >
                    <div className="buttons-list-free">
                      <div className="first-btn">
                        <button className="start-test-black" onClick={() =>
                          FreemockTestNeet(item?._id, item?.mockQuestionId)
                        }>
                          Start Test for Free
                        </button>
                      </div>
                      <div className="second-btn">
                        {NeetExamData?.isFreeExamComplete ? <>
                          <div onClick={() => ReportDownload(item?._id, item?.mockQuestionId)}>
                            <ConvertPdf />
                          </div>
                        </> : null}

                      </div>
                      <div className="third-btn">
                        {NeetExamData?.isFreeExamComplete ? <>
                          <button className="start-test-black" onClick={() => NavigateRank(item?._id, item?.mockQuestionId)}>Rank</button>
                        </> : null}

                      </div>
                    </div>

                  </div>
                );
              })}
            </div>
          </div>
          <div className="right-mock-section">
            <img
              src={imagemock}
              alt="no image mocktest"
              className="mock-image"
            />
          </div>
          <div className="empty-box"></div>
          <div className="empty-box1"></div>
          <div className="empty-box2"></div>
        </div>
        <div className="mt-5 mb-5">
          <div className="mb-5 d-flex justify-content-between dropdown-list-exams">
            <div>
              <h4>Exams</h4>
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={selectName}
                onChange={handleChangeTests}
              >
                <option value="" disabled selected>
                  ---Please Select Mock Test Exams---
                </option>
                <option value="neet">NEET</option>
                {/* <option value="jee">JEE</option> */}
              </Form.Select>
            </div>
          </div>

          <div className="row gap-5 mb-5 mt-5 d-flex align-items-center justify-content-center">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {NeetExamData?.mockPaidIndividual?.length > 0 && NeetExamData?.mockBuyBundle?.length > 0 ? <>
                  <div className="list-cards-row mt-5">
                    {orderData?.map((item, index) => {
                      if (NeetExamData?.mockBuyBundle?.includes(item?.id)) {
                        return <BuynowCards {...item} key={index} type="purchased" ClickAction={ShowTests} index={index} />
                      } else {
                        return <BuynowCards {...item} key={index} type="not purchased" ClickAction={ShowTests} index={index} />
                      }
                    })}
                    <div className="animation-box1">

                    </div>
                    <div className="animation-box2">

                    </div>
                  </div>
                </> : <>
                  <div className="list-cards-row mt-5">
                    {orderData?.map((item, index) => {
                      return <CardTest {...item} key={index} ClickAction={ShowTests} index={index} />
                    })}
                    <div className="animation-box1">

                    </div>
                    <div className="animation-box2">

                    </div>
                  </div>
                </>}



              </>
            )}

            <div className="text-center">
              {NeetExamData?.mockBundle?.length === 0 && (
                <div className="fw-bold fs-3">
                  <div>
                    <img src={nodataimage} alt="no image"
                      className="no-image-box"
                    />
                  </div>
                  No Data Found!</div>
              )}
            </div>
            <div>

              {NeetExamData?.mockPaidIndividual?.length > 0 && NeetExamData?.mockBuyBundle?.length > 0 ? <>
                <div>
                  <h4>
                    Mock Individual
                  </h4>
                </div>
                <div className="mt-4">
                  {NeetExamData?.mockPaidIndividual?.map((item, index) => {
                    return (
                      <>
                        <div className='mock-cards' key={index}>
                          <div>
                            <div>
                              {item?.name}
                            </div>

                          </div>
                          <div>
                            <button className='start-text-btn'

                              onClick={() =>
                                ShowTests(
                                  item?._id,
                                  item?.mockQuestionId,
                                  "individual",
                                  index
                                )
                              }
                            >Start Test</button>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </> : <></>}


            </div>

          </div>
        </div>
      </div>
    </div >
  );
}

export default Mocktest;
