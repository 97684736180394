import { createSlice } from "@reduxjs/toolkit";


const News_Reducer = createSlice({
    name: "newsandarticles",
    initialState: {
        loading: false,
        NewsData: [],
        SinglenewsData: {}
    },
    reducers: {
        NewsRequest(state, action) {
            return {
                loading: true
            }
        },
        NewsSuccess(state, action) {
            return {
                loading: false,
                NewsData: action.payload,

            }
        },
        NewsFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },
        NewsSingleDataSuccess(state, action) {
            return {
                loading: false,
                SinglenewsData: action.payload
            }
        }

    }
})

const { actions, reducer } = News_Reducer;
export const { NewsRequest, NewsSuccess, NewsFail, NewsSingleDataSuccess } = actions;
export default reducer;