import React, { useEffect } from 'react'
import './Pdfstyle.scss';
function ResponseChangepattern({ data }) {

    useEffect(() => {

    }, [data])
    return (
        <div>
            <div className='ms-3'>
                <div className='common-title-section mt-5 mb-2'>
                    Response Change Pattern
                </div>
                <div>
                    The following table shows how many times you have changed your options during the practice test
                </div>
            </div>
            <div className='mt-4'>
                <div className='table-section'>
                    <table class="table table-striped table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th className='th-bg'>Subjects</th>
                                <th className='th-bg'>Correct
                                    to
                                    Incorrect </th>
                                <th className='th-bg'>Incorrect
                                    to
                                    Correct</th>
                                <th className='th-bg'>Incorrect
                                    to
                                    Incorrect</th>
                                <th className='th-bg'>Correct
                                    to
                                    unanswered</th>
                                <th className='th-bg'>
                                    Incorrect
                                    to
                                    unanswered
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item?.labelName}</td>
                                        <td>{item?.correct_to_incorrect}</td>
                                        <td>{item?.incorrect_to_correct}</td>
                                        <td>{item?.incorrect_to_incorrect}</td>
                                        <td>{item?.correct_to_unanswered}</td>
                                        <td>{item?.incorrect_to_unanswered}</td>
                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default ResponseChangepattern