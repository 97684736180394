import React, { useEffect, useState } from 'react'
import './Options.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CurrentOptionSelect, GetQuestionsSingle } from '../../../../redux/actions/Questions';
import { ButtonUpdateTrace } from '../../../../redux/reducers/Button_reducer';
import { FirstOptionUpdate, OptionResultUpdate, VisitedQuestionCountAction, readQuestionOptions, updateResultAction } from '../../../../redux/reducers/Result_reducer';
import { Toast } from 'react-bootstrap';
import { ToastError, ToastSuccess } from '../../../../toastmessages/Toastmessages';

function Options({ setSpendTim, FirstQuestionId }) {
  const [vistcount, setVistCount] = useState(1);
  const [resuleData, setResultData] = useState([]);
  const [resuleData1, setResultData1] = useState(["1e"]);
  // const finalresultSaveDataFinal = resuleData?.concat(resuleData1);
  const finalresultSaveDataFinal = resuleData;

  const [resuleDatasave, setResultDatasave] = useState([]);
  const [markreview, setMarkReview] = useState([]);
  const [opationempty, setOptionEmpty] = useState([]);


  const dispatch = useDispatch();
  const states = useSelector((state) => state);
  const { QuestionData } = states?.questions;
  const { QuestionOptiondata } = states?.questionsoption;
  const { CurrentOptionvalue } = states?.optioncurrent;
  const { trace } = states?.count;
  const { result, resultOptions, readQuestions, markforreviewData } = states?.result;

  useEffect(() => {
  }, [resuleData, QuestionOptiondata, trace, vistcount])

  useEffect(() => {
    let checkList = [];
    let checkList1 = [];
    let checkList2 = [];
    let checkListmark3 = [];
    let optionEmpty = [];


    // readQuestions?.map((item) => {
    //   checkList.push(item?.id)
    // })

    result?.map((item) => {
      if (item?.savequestionId == `${item?.questionId}e`) {
        checkList.push(item?.savequestionId);
      }
    })
    result?.map((item) => {
      checkList1.push(item?.savequestionId)
      checkList2.push(`${item?.questionId}`);
    })

    result?.map((item) => {
      if (item?.savequestionId === `${item?.questionId}em`) {
        optionEmpty.push(item?.savequestionId);
      }
    })

    markforreviewData?.map((item) => {
      if (item?.markid == `${item?.id}m`) {
        checkListmark3.push(item?.markid);
      }
    });
    setOptionEmpty(optionEmpty);
    setMarkReview(checkListmark3);
    setResultData(checkList);
    setResultDatasave(checkList1)
  }, [result])


  useEffect(() => {
  }, [result, resultOptions, readQuestions, markforreviewData, QuestionData, trace])


  const UpdateTrcaeCount = async (dataid, count) => {
    const reads = {
      id: count + 1,
    }
    dispatch(readQuestionOptions(reads))
    dispatch(ButtonUpdateTrace(count));
    dispatch(GetQuestionsSingle(dataid));
    dispatch(CurrentOptionSelect(dataid));
  }

  const CallBackFunctionUpdateOptions = async () => {
    if (result && result[0]?.savequestionId) {
    }
    else {
      if (resuleDatasave?.includes("1e")) {
      }
      else {
        await dispatch(FirstOptionUpdate(FirstQuestionId));
      }
    }

  }




  const UpdateOptionData = async (dataid, index) => {
    dispatch(ButtonUpdateTrace(index));
    const updateId = QuestionOptiondata[index].Queid;
    const resultdata = result.find((item) => item?.oriquestionId === updateId);
    const datas = {
      id: resultdata?.id,
      name: resultdata?.name,
      answer: resultdata?.answer,
      questionId: index + 1,
      savequestionId: resultdata?.savequestionId ? resultdata?.savequestionId : `${index + 1}e`,
      oriquestionId: updateId,
      chapterName: QuestionData?.data[0]?.chapters?.chapterName,
      subject: QuestionOptiondata[trace]?.name,
      your_responce: resultdata?.your_responce,
      correct_responses: resultdata?.correct_responses,
      score: resultdata?.score,
      markedforreviewId: "",
      answeredandmarkedforreviewId: "",
      subTopic: resultdata?.subTopic,
      difficulty_level: resultdata?.difficulty_level,
      visitedquestions: resultdata?.visitedquestions ? resultdata?.visitedquestions : 1,
      spendtime: 0,
      correct_to_incorrect: "",
      incorrect_to_correct: "",
      incorrect_to_incorrect: "",
      correct_to_unanswered: "",
      incorrect_to_unanswered: "",
    }
    dispatch(OptionResultUpdate(datas));
    dispatch(VisitedQuestionCountAction(datas));
    setSpendTim(0);


    CallBackFunctionUpdateOptions(index);
    dispatch(OptionResultUpdate(datas));





  }
  const updateId = QuestionOptiondata[trace].Queid;

  useEffect(() => {

  }, [FirstQuestionId])

  return (
    <div className='option-border'>


      <div className='row gap-2 round-box-list mt-2 mb-2'>
        {QuestionOptiondata?.map((item, index) => {
          return (
            <div className='round-box mb-2' key={index}>
              <div onClick={() => {
                UpdateTrcaeCount(item?.Queid, index)
                UpdateOptionData(item?.Queid, index);
              }}
                className={` "box-color-round" ${resuleDatasave?.includes(`${index + 1}s`) ? "save-box" : `${(resuleDatasave?.includes(`${index + 1}am`)) ? "saveandmark-btn" : finalresultSaveDataFinal.includes(`${index + 1}e`) ? "box-empty" : opationempty?.includes(`${index + 1}em`) ? "box-mark-color" : "box-color-round"}`}`}
              >
                {index + 1 <= 9 ? <>0{index + 1}</> : <>{index + 1}</>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Options