import React, { useEffect } from 'react'
import './styles/Cardtest.scss';
import tickmark from '../../assests/images/tickmarks.png';
import gold from '../../assests/images/gold_images.png';
import silver from '../../assests/images/silver_images.png';
import bronze from '../../assests/images/bronze_images.png';
function CardTest({ category, price, name, questionDocs, ClickAction, id, index }) {

    useEffect(() => {

    }, [price, category])
    return (
        <div
            className='common_bg'
            onClick={
                () => ClickAction(
                    id,
                    questionDocs[0] &&
                    questionDocs[0]?.mockQuestionId,
                    "bundle",
                    index)
            }
            style={{
                backgroundColor: name === "GOLD"
                    ? "#00bf9e"
                    : name === "SILVER"
                        ? "#009bdd"
                        : "#0b475f"
            }}
        >


            <div className='box-round-list'>
                {name === "GOLD"
                    ? <>
                        <img src={gold} alt="no images" className='stars-img' />

                    </>
                    : name === "SILVER"
                        ? <>
                            <img src={silver} alt="no images" className='stars-img' />

                        </>
                        : <>

                            <img src={bronze} alt="no images" className='stars-img' />

                        </>}
            </div>
            <div className='card-designs'>
                <div className='mt-4 mb-2'>
                    ₹ {price}
                </div>
                {price == 599 ? <>
                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Study Material Access
                        </div>
                    </div>



                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            1 Free Mock test +3 Paid

                        </div>
                    </div>

                </> : null}

                {price == 799 ? <>
                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Study Material Access
                        </div>
                    </div>



                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Doubt clearing sessions
                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            1 Free Mock test +3 Paid

                        </div>
                    </div>
                </> : null}



                {price == 999 ? <>
                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Study Material Access
                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Live Classes
                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Doubt clearing sessions
                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            1 Free Mock test +3 Paid

                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            NEET Analysis
                        </div>
                    </div>

                    <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                        <div>
                            <img src={tickmark} alt="no images" className='tick_img' />
                        </div>
                        <div>
                            Rank Simulator
                        </div>
                    </div>
                </> : null}



                {/* <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                    <div>
                        <img src={tickmark} alt="no images" className='tick_img' />
                    </div>
                    <div>


                      


                    </div>
                </div> */}
                {/* <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                    <div>
                        <img src={tickmark} alt="no images" className='tick_img' />
                    </div>
                    <div>
                        Ranking simulations in colleges.
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                    <div>
                        <img src={tickmark} alt="no images" className='tick_img' />
                    </div>
                    <div>
                        Help and mail supports.
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center ms-4'>
                    <div>
                        <img src={tickmark} alt="no images" className='tick_img' />
                    </div>
                    <div>
                        Download anwer key.
                    </div>
                </div> */}
            </div>
            <div className='button-cards mt-3 mb-4'>
                <button className='common_button'
                >{name}</button>
            </div>
        </div>
    )
}

export default CardTest