import { getReportData } from "../../../services/neetservices/report_services";
import { ReportFail, ReportRequest, ReportSuccess } from "../../reducers/Report_reducer";
export const GetReportActionUser = (data) => async (dispatch) => {
    dispatch(ReportRequest());
    try {
        const response = await getReportData(data);
        if (response) {
            dispatch(ReportSuccess(response?.data));
        }
    } catch (err) {
        dispatch(ReportFail(err));
    }
};