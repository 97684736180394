

import React, { Fragment, useEffect, useState } from 'react';
import logo from '../../../../assests/images/logo.svg';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Form, Col } from "react-bootstrap";
import eyeclose from '../../../../assests/images/eyeclose.png';
import eyeopen from '../../../../assests/images/eyeopen.png';
import editpen from '../../../../assests/images/edit-pen.png';
import { SpinersLoad } from '../../../spinners/Spinners';
import { CarouselSection } from '../../../../carouselsection/CarouselSection';
import '../../signin/styles/Signin.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import { GeneratePassword, UserChangePassword, UserVerifyToken } from '../../../../services/user/userSevice';
import { ToastError, ToastSuccess } from '../../../../toastmessages/Toastmessages';
function ChangeForgetPassword() {
    const navigate = useNavigate();




    const token = new URLSearchParams(window.location.search);
    const getToken = token.get("token");


    const [show1, setShow1] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [tokenError, setTokenError] = useState(false);

    const [matchcheck1, setMatchCheck1] = useState(false);
    const [matchcheck2, setMatchCheck2] = useState(false);
    const [matchcheck3, setMatchCheck3] = useState(false);
    const [matchcheck4, setMatchCheck4] = useState(false);

    const [forgetPasswotdid, setForgetPasswordId] = useState("");

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(8, 'Password must be atleast 8 characters long'),
        ConfirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords do not match'),
    })
    const formOptions = { resolver: yupResolver(formSchema) }
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm(formOptions);
    const onSubmit = async (data) => {
        const check = {
            _id: forgetPasswotdid,
            password: data?.password
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
                "verify-token": getToken,
            }
        };

        setLoading(true)
        try {

            const response = await GeneratePassword(check, config);
            ToastSuccess("Password Changed")
            navigate("/");
            setLoading(false)
        } catch (error) {
            setLoading(false);
        }


        setMatchCheck1(true);
        setMatchCheck2(false);

      
    }
    const handlePushForgetpassword = () => {
        navigate("/forgetpassword")
    }
    const SignupPath = () => {
        navigate("/signup")
    }

    useEffect(() => {
        TokenVerify();
    }, [forgetPasswotdid])

    const TokenVerify = async () => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "verify-token": getToken,
                }
            };
            const response = await UserVerifyToken(config);
            if (response) {
                setForgetPasswordId(response?.data?._id)
            }
        } catch (error) {
            setTokenError(true);
        }
    }

    const BackToLogin = () => {
        navigate("/");
    }
    return (
        <Fragment>

            <div className='main-signin-section'>
                <div className='inside-signin-section'>
                    <div className='left-signin-section'>
                        <div className='logo-section-sigin'>
                            <div>
                                <img src={logo} alt="no image" />
                            </div>

                        </div>
                        {tokenError ? <>
                            <div className='login-input-section'>
                                <div className='text-center mb-4'>
                                    Forget Password Token Expire Please Check
                                </div>
                                <div>
                                    <Button className='loginButton p-2' onClick={BackToLogin}>
                                        Back To Login
                                    </Button>
                                </div>
                            </div>
                        </> : <>


                            <div className='login-input-section'>
                                <div className='medium-text'>
                                    Password
                                </div>
                                <div className='mt-3'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className={"passwordformsection"}>
                                            <Form.Group className="mb-2" controlId="formBasicPassword">
                                                <Form.Label className='label-text'>Password <span className='text-danger'>*</span></Form.Label>
                                                <div className={"formsectioncommonlogin"}>
                                                    <Form.Control type={show ? "text" : "password"} placeholder="Password"
                                                        className='login-form-input'
                                                        {...register("password", {
                                                            required: "Please enter your password",
                                                            minLength: {
                                                                value: 6,
                                                                message: "Password is more than 4 charactor"
                                                            },
                                                        })}
                                                    />
                                                    <div className={"passwordicons"}>
                                                        {show ? <div className='mt-2' onClick={() => setShow(!show)}><img src={eyeopen} alt="no image" className='eyeicon' /></div> : <div className="mt-2" onClick={() => setShow(!show)}><img src={eyeclose} alt="no image" className='eyeicon' /></div>}
                                                    </div>
                                                </div>
                                                {errors.password && <span className="text-danger">{errors.password.message}</span>}
                                            </Form.Group>
                                        </div>

                                        <div className={"passwordformsection"}>
                                            <Form.Group className="mb-2" controlId="formBasicPassword">
                                                <Form.Label className='label-text'>Confirm Password <span className='text-danger'>*</span></Form.Label>
                                                <div className={"formsectioncommonlogin"}>
                                                    <Form.Control type={show1 ? "text" : "password"} placeholder="Password"
                                                        className='login-form-input'
                                                        {...register("ConfirmPassword", {
                                                            required: "Please enter ConfirmPassword",
                                                            minLength: {
                                                                value: 6,
                                                                message: "ConfirmPassword is more than 5 charactor"
                                                            },
                                                        })}
                                                    />
                                                    <div className={"passwordicons"}>
                                                        {show1 ? <div className='mt-2' onClick={() => setShow1(!show1)}><img src={eyeopen} alt="no image" className='eyeicon' /></div> : <div className="mt-2" onClick={() => setShow1(!show1)}><img src={eyeclose} alt="no image" className='eyeicon' /></div>}
                                                    </div>
                                                </div>
                                                {errors.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword.message}</span>}
                                            </Form.Group>
                                        </div>
                                        {/* <div>
                                            {matchcheck1 ? <div className='text-success'>
                                                Password Matched!
                                            </div> : <>
                                            </>}
                                            {matchcheck2 && <div className='text-danger shorttext'>
                                                At least one letter,
                                                one capital letter,
                                                one number,
                                                one special character,
                                            </div>}
                                        </div> */}

                                        <Button className="loginButton mt-3" type="submit">

                                            {error ? <div className='login-text'>
                                                Next
                                            </div> : <>

                                                {loading ? <>
                                                    <SpinersLoad
                                                        title={"Password Loading"}
                                                    />
                                                </> :
                                                    <div className='login-text'>
                                                        Next
                                                    </div>
                                                }
                                            </>}

                                        </Button>

                                    </Form>
                                </div>

                            </div>
                        </>}

                    </div>
                    <div className='right-signin-section'>
                        <div className='iniside-right-signin-section'>
                            <CarouselSection />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}


export default ChangeForgetPassword