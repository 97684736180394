import Spinner from 'react-bootstrap/Spinner';
export const SpinersLoad = ({ title }) => {


    return (
        <>

            <div className='d-flex gap-2 align-items-center justify-content-center'>
                <Spinner animation="border" role="status" size="sm">
                </Spinner>
                <span className='ms-1' >...Loading</span>
            </div>


        </>
    )
}