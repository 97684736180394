import React from 'react'
import './styles/Cardtest.scss';
import tickmark from '../../assests/images/tickmarks.png';
import gold from '../../assests/images/gold_images.png';
import silver from '../../assests/images/silver_images.png';
import bronze from '../../assests/images/bronze_images.png';

function BuynowCards({ price, name, questionDocs, type, ClickAction, id, index }) {
    return (
        <div
            onClick={
                () => {
                    if (type === "purchased") {
                        ClickAction(
                            id,
                            questionDocs[0] &&
                            questionDocs[0]?.mockQuestionId,
                            "bundle",
                            index)
                    }
                    else {
                        ClickAction(
                            id,
                            questionDocs[0] &&
                            questionDocs[0]?.mockQuestionId,
                            "bundle",
                            index)
                    }
                }}
            className={type == "purchased" ? "common_bg" : "common_bg_disabled"}
            style={{
                backgroundColor: name === "GOLD"
                    ? "#00bf9e"
                    : name === "SILVER"
                        ? "#009bdd"
                        : "#0b475f"
            }}
        >
            <div className='box-round-list'>
                {name === "GOLD"
                    ? <>
                        <img src={gold} alt="no images" className='stars-img' />

                    </>
                    : name === "SILVER"
                        ? <>
                            <img src={silver} alt="no images" className='stars-img' />

                        </>
                        : <>
                            <img src={bronze} alt="no images" className='stars-img' />
                        </>}
            </div>

            {type == "purchased" ? <>
                <div className='card-designs'>
                    <div className='price-text mt-5 mb-2 fs-4'>
                        ₹ {price}
                    </div>
                    <div style={{ color: "#2dae76", fontSize: "1.3rem", fontWeight: "600" }}>
                        Purchased This Course
                    </div>
                    {price == 999 ? <>
                        <div className='d-flex gap-2 align-items-center ms-4 mb-3 mt-2'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Live Classes
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Doubt clearing sessions
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +7 Paid

                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                NEET Analysis
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Rank Simulator
                            </div>
                        </div>
                    </> : null}
                    {price == 799 ? <div className='mt-2'>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>



                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Doubt clearing sessions
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +5 Paid

                            </div>
                        </div>
                    </div> : null}
                    {/* {price == 999 ? <>
                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Live Classes
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Doubt clearing sessions
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +3 Paid

                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                NEET Analysis
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Rank Simulator
                            </div>
                        </div>
                    </> : null} */}
                </div>
            </> : <>
                <div
                    className={type == "purchased" ? "card-designs" : "card-designs_disabled"}
                >

                    {price == 599 ? <div div className='mt-2'>
                        <div className='price-text mt-4 mb-2'>
                            ₹ {price}
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>



                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +3 Paid

                            </div>
                        </div>

                    </div> : null}

                    {price == 799 ? <div className='mt-2'>
                        <div className='price-text mt-4 mb-2'>
                            ₹ {price}
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Doubt clearing sessions
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +5 Paid

                            </div>
                        </div>
                    </div> : null}



                    {price == 999 ? <>
                        <div className='price-text mt-4 mb-2'>
                            ₹ {price}
                        </div>
                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Study Material Access
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Live Classes
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Doubt clearing sessions
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                1 Free Mock test +7 Paid

                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                NEET Analysis
                            </div>
                        </div>

                        <div className='d-flex gap-2 align-items-center ms-4 mb-3'>
                            <div>
                                <img src={tickmark} alt="no images" className='tick_img' />
                            </div>
                            <div>
                                Rank Simulator
                            </div>
                        </div>
                    </> : null}
                </div>
            </>}

            {type == "purchased" ? <>
            </> : <>
                <div className='button-cards mt-3 mb-4'
                >
                    <button className='common_button'
                        style={{ cursor: type === "purchased" ? "pointer" : "no-drop" }}
                    >{name}</button>
                </div>
            </>}

        </div>
    )
}

export default BuynowCards