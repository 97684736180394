import { rankLocationService } from "../../../services/rank/rank_services";
import { RankLocationFail, RankLocationRequest, RankLocationSuccess } from "../../reducers/RankLocation_reducer";

export const GetRankLocationList = () => async (dispatch) => {
    dispatch(RankLocationRequest());
    try {
        const response = await rankLocationService();


        if (response) {
            dispatch(RankLocationSuccess(response?.result));
        }
    } catch (err) {
        dispatch(RankLocationFail(err));

    }
};