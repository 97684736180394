import React, { useEffect } from 'react'
import './Pdfstyle.scss';
function SubjectWiseQuestions({ data }) {

    const datas = [
        {
            id: 1,
            name: "Biology",
            arraydata: data?.Biology
        },
        {
            id: 2,
            name: "Physics",
            arraydata: data?.Physics
        },
        {
            id: 3,
            name: "Chemistry",
            arraydata: data?.Chemistry
        }
    ]


    useEffect(() => {
    }, [data])
    return (
        <div>

            {datas?.map((item, index) => {
                return (
                    <div>
                        <div className='ms-3'>
                            <div className='common-title-section mt-5 mb-2'>
                                Subject wise question analysis <span className='fw-bold mb-3 mt-2'>{item?.name}</span>
                            </div>
                            <div>
                                This table examines your performance depending upon the topic and concept (s) on
                                which the question is based. It thus highlights what you need to improvise for enhancing your concepts.
                                It helps in time management across Physics and maps it to Optimum Time Utilization                </div>
                        </div>
                        <div className='mt-4'>
                            <div className='table-section'>
                                <table class="table table-striped table-bordered">
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th className='th-bg'>Q.no </th>

                                            <th className='th-bg'>Difficulty
                                                Level</th>
                                            <th className='th-bg'> Topic</th>
                                            <th className='th-bg'>Sub Topic</th>
                                            <th className='th-bg'> Your
                                                Response
                                            </th>
                                            <th className='th-bg'>Correct
                                                Answer</th>
                                            <th className='th-bg'>Score </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {item?.arraydata?.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{items?.quesNo}</td>
                                                    <td>{items?.level}</td>
                                                    <td>{items?.topic}</td>
                                                    <td>{items?.subTopic ? items?.subTopic : "-"}</td>
                                                    <td>{items?.yourResp}</td>
                                                    <td>{items?.crtOption}</td>
                                                    <td>{items?.score}</td>

                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default SubjectWiseQuestions