import { submitAnswer } from "../../services/neetservices/submit_services";

export const SubmitAnswerAction = (data, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
        const response = await submitAnswer(data);
        if (response) {
            setLoading(false);
        }
    } catch (error) {
        console.log(error);
    }
}