import { createSlice } from "@reduxjs/toolkit";
const Content_Reducer = createSlice({
    name: "Content",
    initialState: {
        loading: false,
        ContentCourse: []
    },
    reducers: {
        ContentRequest(state, action) {
            return {
                loading: true
            }
        },
        ContentSuccess(state, action) {
            return {
                loading: false,
                ContentCourse: action.payload
            }
        },
        ContentFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Content_Reducer;
export const { ContentRequest, ContentSuccess, ContentFail } = actions;
export default reducer;