import React from 'react'
import './styles/Header.scss';
import logo from '../../assests/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AllClearLogoutAction } from '../../redux/actions/ClearActions';
import { NeetExamClear } from '../../redux/reducers/Neet_reducer';
function Header() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state?.login?.loginuser);
    const path = window.location.pathname;
    const navigate = useNavigate();
    const LoginPage = () => {
        navigate("/");
        dispatch(AllClearLogoutAction());
        dispatch(NeetExamClear());
    }
    const getYear = new Date();
    let CurrentYear = getYear.getFullYear();
    return (
        <div className='main-header'>

            <div className='inide-header'>
                <div>
                    {path == "/result" ? <div onClick={LoginPage} className='cursor-pointer'>
                        <img src={logo} alt="no image" className='header-logo' />
                    </div> : <>
                        <img src={logo} alt="no image" className='header-logo' />

                    </>}
                </div>
                <div className='email-header-section'>
                    <div>
                        Exam Name : <small className='fw-bold fs-6'>NEET {CurrentYear}</small>
                    </div>
                    <div>
                        Candidate Mail id : <small className='fw-bold fs-6'>{state?.user_details?.email}</small>
                    </div>
                </div>
            </div>


            <div className='mobile-header'>
                <div>
                    {path == "/result" ? <div onClick={LoginPage} className='cursor-pointer'>
                        <img src={logo} alt="no image" className='header-logo' />
                    </div> : <>
                        <img src={logo} alt="no image" className='header-logo' />

                    </>}
                </div>
                <div>
                    <div>
                        Exam Name : <small className='fw-bold fs-6'>NEET {CurrentYear}</small>
                    </div>
                    <div>
                        Candidate Mail id : <small className='fw-bold fs-6'>{state?.user_details?.email}</small>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Header