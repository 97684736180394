import { createSlice } from "@reduxjs/toolkit";


const Token_Reducer = createSlice({
    name: "Token",
    initialState: {
        loading: false,
        Userid: "",
    },
    reducers: {
        TokenRequest(state, action) {
            return {
                loading: true
            }
        },
        TokenSuccess(state, action) {
            return {
                loading: false,
                Userid: action.payload
            }
        },
        TokenFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Token_Reducer;
export const { TokenRequest, TokenSuccess, TokenFail } = actions;
export default reducer;