import React from 'react'
import './Pdfstyle.scss';
function IncorrectResponse({ data }) {
    return (
        <div>
            <div className='ms-3'>
                <div className='common-title-section mt-5 mb-2'>
                    Impact of Incorrect Responses
                </div>
                <div>
                    This table depicts how your overall negative marks has affected your score.
                    If not for the below incorrect responses, your score would have been 168.
                </div>
            </div>
            <div className='mt-4'>
                <div className='table-section'>
                    <table class="table table-striped table-bordered">
                        <thead style={{ background: "red" }}>
                            <tr >
                                <th className='th-bg'>Subjects</th>
                                <th className='th-bg'>No.of.
                                    Questions</th>
                                <th className='th-bg'>No.of.
                                    Questions
                                    Attempted
                                </th>
                                <th className='th-bg'>No.of.
                                    Correct
                                    Responses</th>
                                <th className='th-bg'>No.of.
                                    Incorrect
                                    Responses</th>
                                <th className='th-bg'>
                                    Total
                                    Marks
                                    Scored (A)
                                </th>
                                <th className='th-bg'>
                                    Negative
                                    Marks(B)
                                </th>
                                <th className='th-bg'>
                                    Actual
                                    Scored
                                    (A-B)
                                </th>
                            </tr >
                        </thead >
                        <tbody>

                            {data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td >{item?.labelName}</td>
                                        <td>{item?.no_of_ques}</td>
                                        <td>{item?.attempted}</td>
                                        <td>{item?.crtResp}</td>
                                        <td>{item?.inCrtResp}</td>
                                        <td>{item?.totalMark}</td>
                                        <td>{item?.negativeMark}</td>
                                        <td style={{ backgroundColor: "#15F5BA", color: "white" }}>{item?.actualMark}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table >
                </div >

            </div >
        </div >
    )
}

export default IncorrectResponse