import { createSlice } from "@reduxjs/toolkit";
const Purchase_Course_Reducer = createSlice({
    name: "PurchaseCourse",
    initialState: {
        loading: false,
        PurchaseCourseData: [],
    },
    reducers: {
        PurchaseCourseRequest(state, action) {
            return {
                loading: true
            }
        },
        PurchaseCourseSuccess(state, action) {
            return {
                loading: false,
                PurchaseCourseData: action.payload
            }
        },
        PurchaseCourseFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = Purchase_Course_Reducer;
export const { PurchaseCourseRequest, PurchaseCourseSuccess, PurchaseCourseFail } = actions;
export default reducer;