import { createSlice } from "@reduxjs/toolkit";
const QuestionOptionCurrent_Reducer = createSlice({
    name: "questionsoptionscurrent",
    initialState: {
        loading: false,
        CurrentOptionvalue: "",
    },
    reducers: {
        QuestionOptionCurrentRequest(state, action) {
            return {
                loading: true
            }
        },
        QuestionOptionCurrentSuccess(state, action) {
            return {
                loading: false,
                CurrentOptionvalue: action.payload
            }
        },
        QuestionOptionCurrentFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

        ClearQuestionOptions(state, action) {
            return {
                ...state,
                CurrentOptionvalue: ""
            }
        }

    }
})

const { actions, reducer } = QuestionOptionCurrent_Reducer;
export const { QuestionOptionCurrentRequest, QuestionOptionCurrentSuccess, QuestionOptionCurrentFail, ClearQuestionOptions } = actions;
export default reducer;