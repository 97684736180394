import React from 'react'
import Slider from "react-slick";
import SlideNextArrow from './slidenextarrow/SlideNextArrow';
import SlidePreArrow from './slideprearrow/SlidePreArrow';
import './styles/Carousel.scss';
import first from '../assests/videos/progresssecond.gif';
import second from '../assests/videos/multitask.gif';
import third from '../assests/videos/progressone.gif';

export const CarouselSection = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: true,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePreArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,

                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    const data = [
        {
            id: 1,
            images: first,
            imageurl: 'https://cdn3d.iconscout.com/3d/premium/thumb/student-online-learning-with-laptop-5559088-4644784.png',
            name: "Student Online Learning",
            content: "Collaborative Market-Adaptive Education Programs for Schools, Teachers, and Students through Our Smart Solutions."
        },
      
        {
            id: 3,
            images: third,
            imageurl: "https://assets.website-files.com/6364b6fd26e298b11fb9391f/6364b6fd26e298b303b93d91_3d-tb-education.png",
            name: "Child School",
            content: `Creating a collaborative journey for all stakeholders. NEET Assessment with Test Analysis Report and School Readiness Program for Class 12. NEET Test Preparation for Classes 11 and 12. Foundation programs with MCQ and assessment for both math and science for Classes 6 to 10.`
        }
    ]
    return (
        <div className='main-carousel-section'>

            <div className='inside-carousel-position'>
                <Slider {...settings}>
                    {data?.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className='inside-box mt-2'>
                                

                                    <img src={item?.images} alt="no image" className='carousel-image-box' />
                                  
                                </div>

                            </div>
                        )
                    })}

                </Slider>
            </div>




        </div>
    )
}

