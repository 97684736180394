import { createSlice } from "@reduxjs/toolkit";


const Coupon_Reducer = createSlice({
    name: "Coupon",
    initialState: {
        loading: false,
        CouponData: [],

    },
    reducers: {
        CouponRequest(state, action) {
            return {
                loading: true
            }
        },
        CouponSuccess(state, action) {
            return {
                loading: false,
                CouponData: action.payload,
            }
        },
        CouponFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

    }
})

const { actions, reducer } = Coupon_Reducer;
export const { CouponRequest, CouponSuccess, CouponFail, CouponDummy } = actions;
export default reducer;