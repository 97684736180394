import { createSlice } from "@reduxjs/toolkit";


const Syllabus_Reducer = createSlice({
    name: "Syllabus",
    initialState: {
        loading: false,
        SyllabusData: [],

    },
    reducers: {
        SyllabusRequest(state, action) {
            return {
                loading: true
            }
        },
        SyllabusSuccess(state, action) {
            return {
                loading: false,
                SyllabusData: action.payload,
            }
        },
        SyllabusFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },

    }
})

const { actions, reducer } = Syllabus_Reducer;
export const { SyllabusRequest, SyllabusSuccess, SyllabusFail } = actions;
export default reducer;