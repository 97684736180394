import { createSlice } from "@reduxjs/toolkit";
const ClassCourse_Reducer = createSlice({
    name: "Class Course",
    initialState: {
        loading: false,
        ClassCourses: []
    },
    reducers: {
        ClassCoursesRequest(state, action) {
            return {
                loading: true
            }
        },
        ClassCoursesSuccess(state, action) {
            return {
                loading: false,
                ClassCourses: action.payload
            }
        },
        ClassCoursesFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = ClassCourse_Reducer;
export const { ClassCoursesRequest, ClassCoursesSuccess, ClassCoursesFail } = actions;
export default reducer;