import { createSlice } from "@reduxjs/toolkit";
const QuestionOption_Reducer = createSlice({
    name: "questionsoptions",
    initialState: {
        loading: false,
        QuestionOptiondata: [],
    },
    reducers: {
        QuestionOptionRequest(state, action) {
            return {
                loading: true
            }
        },
        QuestionOptionSuccess(state, action) {
            return {
                loading: false,
                QuestionOptiondata: action.payload
            }
        },
        QuestionOptionFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        },
        ClearQuestionOptionData(state, action) {
            return {
                ...state,
                QuestionOptiondata: []
            }
        }

    }
})

const { actions, reducer } = QuestionOption_Reducer;
export const { QuestionOptionRequest, QuestionOptionSuccess, QuestionOptionFail, ClearQuestionOptionData } = actions;
export default reducer;