import React from 'react'
import wifiimage from '../../assests/images/wifi.png';
import './Offline.scss';
import { useNavigate } from 'react-router-dom';
function Offline() {

    const navigate = useNavigate();
    const token = sessionStorage.getItem("access-user-token");

    const refresh = () => {
        // window.addEventListener("online", function () {
        //     window.location.assign("/home")
        // })
        // window.addEventListener("offline", function () {
        //     window.location.reload();
        // })
        if (navigator.onLine) {
            navigate("/home")
        }
        else {
            navigate("/offline")
        }
    }
    return (
        <div className='main-offline'>
            <div className='inside-offline'>
                <div className='mt-5'>
                    <img src={wifiimage} alt="no image" className='wifi-img' />
                </div>
                <div className='fw-bold fs-3'>
                    No Connection
                </div>
                <div className='mb-4'>
                    No internet connection found. Check your connection or try again.
                </div>
                <div>
                    <button className='try-btn' onClick={refresh}>TRY AGAIN</button>
                </div>
            </div>
        </div>
    )
}

export default Offline