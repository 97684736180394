import instanceBaseurl from "../../config/BaseUrl";

export async function CreateUser(data) {
  try {
    const response = await instanceBaseurl.post("/users/register", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function VerifyMobileOtp(data) {
  try {
    const response = await instanceBaseurl.post("/users/mobile/verify", data);
    return response.data;
  } catch (err) {

    throw err;
  }
}

export async function EmailVerify(data) {
  try {
    const response = await instanceBaseurl.post("/users/email", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function VerifyEmailOtp(data) {
  try {
    const response = await instanceBaseurl.post("/users/email/verify", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function GeneratePassword(data, urls) {
  try {
    const response = await instanceBaseurl.post(
      "/users/genarate/password",
      data,
      urls
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function GetStandard(data) {
  try {
    const response = await instanceBaseurl.post("/users/standard", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

// login user

export async function GetAuthUser(data) {
  try {
    const response = await instanceBaseurl.post("/auth", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function LoginAuthUser(data) {
  try {
    const response = await instanceBaseurl.post("/auth/login", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function UserSessionClear(data) {
  try {
    const response = await instanceBaseurl.post("/auth/session/clear", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

// forget password

export async function UserForgetPassword(data) {
  try {
    const response = await instanceBaseurl.post("/auth/forgot/password", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function UserChangePassword(data) {
  try {
    const response = await instanceBaseurl.post("/auth/change/password", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function UserVerifyToken(data) {
  try {
    const response = await instanceBaseurl.get("/auth/verify-token", data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

// logout

export async function UserLogout() {
  try {
    const response = await instanceBaseurl.put("/auth/logout");
    return response.data;
  } catch (err) {
    throw err;
  }
}


// user Profile
export async function GetUserProfile() {
  try {
    const response = await instanceBaseurl.get("/users");
    return response.data;
  } catch (err) {
    throw err;
  }
}
