import { createSlice } from "@reduxjs/toolkit";
const ExamCourse_Reducer = createSlice({
    name: "Extra Course Suggestion",
    initialState: {
        loading: false,
        ExamCourses: []
    },
    reducers: {
        ExamCoursesRequest(state, action) {
            return {
                loading: true
            }
        },
        ExamCoursesSuccess(state, action) {
            return {
                loading: false,
                ExamCoursesCourses: action.payload
            }
        },
        ExamCoursesFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = ExamCourse_Reducer;
export const { ExamCoursesRequest, ExamCoursesSuccess, ExamCoursesFail } = actions;
export default reducer;