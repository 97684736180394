import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import './Timer.scss';
function Timer({ duration, setSubmitresulttime, setTimeCountAlerts, setSpendTimeFinalCal, setSpendTimeFinalCal1 }) {
    // new 
    const [time, setTime] = useState(duration);
    useEffect(() => {
        setTimeout(() => {
            setTime(time - 1000);
        }, 1000);

        if (time) {
            setSpendTimeFinalCal(time);
        }
    }, [time])

    const getFormatetedTime = (millisecond) => {
        const total_seconds = parseInt(Math.floor(millisecond / 1000));
        const total_minutes = parseInt(Math.floor(total_seconds / 60));
        const total_hours = parseInt(Math.floor(total_minutes / 60));
        const days = parseInt(Math.floor(total_hours / 24));
        let seconds = parseInt(total_seconds % 60);
        let minutes = parseInt(total_minutes % 60);
        let hours = parseInt(total_hours % 24);
        // return `${hourscal} : ${Minutescal} : ${secondscal} `


    }

    const total_seconds = parseInt(Math.floor(time / 1000));
    const total_minutes = parseInt(Math.floor(total_seconds / 60));
    const total_hours = parseInt(Math.floor(total_minutes / 60));
    const days = parseInt(Math.floor(total_hours / 24));
    let seconds = parseInt(total_seconds % 60);
    let minutes = parseInt(total_minutes % 60);
    let hours = parseInt(total_hours % 24);

    useEffect(() => {
        if (seconds === 0 && minutes === 0 && hours === 0) {
            setSubmitresulttime(1)
        }
        if (seconds === -1) {
            setSubmitresulttime(1)
        }
        if (minutes === 10) {
            setTimeCountAlerts(minutes)
        }
    }, [seconds, minutes, hours])
    return (
        <div className=''>
            <div className="d-none">
                {minutes < 10 ? <>
                    <ReactPlayer url='https://www.youtube.com/watch?v=3l7kZZIqQ6I'
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            },
                            facebook: {
                                appId: '12345'
                            }
                        }}
                        playing={true}
                    />
                </> : <></>}
            </div>
            <div className='remaning-time-text'>
                Remaining Timing   : 0{hours} : {minutes <= 9 ? <>0{minutes}</> : <>{minutes}</>} : {seconds <= 9 ? <>0{seconds}</> : <>{seconds}</>}
            </div>
        </div>
    )
}

export default Timer